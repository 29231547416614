<div class="background-white">
  <div class="custom-container">
    <div nz-row class="specialities">
      <div nz-col [nzXl]="{ span: 20, offset: 2 }" [nzSm]="{ span: 22, offset: 1 }">

        <div class="pipeline-list">
          <div nz-row nzGutter="16">


            <div *ngFor="let item of results" nz-col class="gutter-row" [nzSm]="{ span: 12 }" [nzLg]="{ span : 8 }">
              <div class="list-item" [class.no-content]="!item.hasContent" *ngIf="item.visible"
                (click)="item.hasContent?navigateToRecruitingStudies(item.id):false">
                <div nz-row nzAlign="middle">

                  <div nz-col nzSpan="6">
                    <span class="list-image"><img class="roche-image-round roche-image-fullwidth"
                        src="/assets/img/specialities/{{item.type}}.png" /></span>
                  </div>

                  <div nz-col nzSpan="16"><span class="list-title roche-font-bold">{{item.name}}</span>
                    <span *ngIf="item.message" class="list-title roche-font-bold">
                      Last Update: {{modificationDates(item.message)}}
                    </span>
                    <span *ngIf="!item.message" class="list-title roche-font-bold">
                      Coming soon...
                    </span>
                  </div>

                  <div nz-col nzSpan="2" class="roche-text-center"><span class="list-icon"><i
                        class="icon icon-chevron-right"></i></span></div>

                </div>
              </div>
            </div>

          </div>
        </div>

        <ng-template #noResults>
          <div class="no-results">
            <p>{{ 'Shared.NoResults' | translate }}</p>
          </div>
        </ng-template>

      </div>
    </div>
  </div>
</div>