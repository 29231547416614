import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/modules/api-client/services/auth.service';
import { LocalDatabaseService } from '../../services/local-database.service';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit {

  @Input() attachmentId: string;
  contentType: string;
  videoSrc: string;

  constructor(
    private authUser: AuthService,
    private localDb: LocalDatabaseService
  ) {
  }

  ngOnInit() {
    if (this.attachmentId) {
      const attachment = this.localDb.getAttachmentWithId(this.attachmentId);
      this.contentType = attachment.contentType;

      const jwtToken = this.authUser.getJwtToken();
      const apiKey = environment.api_key;
      const url = `${environment.api_url}/attachments/${this.attachmentId}/stream?jwt=${jwtToken}&apiKey=${apiKey}`;
      this.videoSrc = url;
    }
  }

}
