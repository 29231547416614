import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-pdf',
  templateUrl: './pdf.component.html',
  styleUrls: ['./pdf.component.scss']
})
export class PdfComponent implements OnInit {

  @ViewChild('pdfViewerOnDemand') pdfViewerOnDemand;
  @ViewChild('pdfViewerAutoLoad') pdfViewerAutoLoad;

  @Input() attachmentId: string;

  constructor(
    public http: HttpClient
  ) {
  }

  ngOnInit() {

    if (this.attachmentId) {
      const url = `${environment.api_url}/attachments/${this.attachmentId}/stream`;
      this.http
      .get(url, { responseType: 'blob' })
      .subscribe((val) => {
        this.pdfViewerAutoLoad.pdfSrc = val;
        this.pdfViewerAutoLoad.refresh();
      });
    }

  }

}
