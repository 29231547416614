import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import jwt_decode from 'jwt-decode';
import { JWTToken, User } from '../models';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private readonly JWT_TOKEN = 'JWT_TOKEN';
  private readonly FULL_DATA = 'full_data';

  private isLoggedInSubject: BehaviorSubject<boolean>;
  private currentUser: User;

  constructor() { }

  getJwtToken() {
    return localStorage.getItem(this.JWT_TOKEN);
  }

  setJwtToken(jwt: string) {
    localStorage.setItem(this.JWT_TOKEN, jwt);
    this._updateIsLoggedInSubject();
  }

  getCurrentUser(): User {
    return this.currentUser;
  }

  setCurrentUser(user: User) {
    this.currentUser = user;
  }

  getDecodedJwtToken(): JWTToken {
    const jwt = this.getJwtToken();
    if (jwt) {
      return JWTToken.deserialize(jwt_decode(jwt));
    }
    return null;
  }

  login() {
    window.location.href = `${environment.api_url}/login`;
  }

  logout() {
    const jwt = this.getJwtToken();
    this._removeJwtToken();
    this._updateIsLoggedInSubject();
    this._removeFullData();
    window.location.href = `${environment.api_url}/logout?jwt=${jwt}`;
  }

  isLoggedIn(): Observable<boolean> {
    if (!this.isLoggedInSubject) {
      this.isLoggedInSubject = new BehaviorSubject<boolean>(this._isLoggedIn());
    }
    this._updateIsLoggedInSubject();
    return this.isLoggedInSubject;
  }

  private _removeJwtToken() {
    localStorage.removeItem(this.JWT_TOKEN);
  }

  private _removeFullData() {
    localStorage.removeItem(this.FULL_DATA);
  }

  private _updateIsLoggedInSubject() {
    if (!this.isLoggedInSubject) { return; }
    this.isLoggedInSubject.next(this._isLoggedIn());
  }

  private _isLoggedIn(): boolean {
    const token = this.getDecodedJwtToken();
    return token && !token.isExpired();
  }
}
