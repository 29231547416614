<div class="background-white">
  <div class="custom-container">
    <div nz-row class="events">
      <div nz-col nzSpan="20" nzOffset="2">

          <p class="instructions-text">{{'EventsScreen.PackageSelectionInstructions' | translate}}:</p>

          <ng-container *ngIf="results | async as results;">
            <ul class="roche-list package-list" *ngIf="results.length > 0 else noResults">

              <div *ngFor="let event of results">
              <li [class.selected]="event.id == currentEventId" class="list-item" (click)="pinPrompt(tplContent, tplFooter, event)" *ngIf="event.configuration.locale=='en'">
                <span class="list-title roche-font-bold">{{event.name}}</span>
                <span class="list-version">{{'EventsScreen.PackageVersion' | translate}}: {{event.contentVersion}}</span>
                <span class="list-icon"><i class="icon icon-chevron-right"></i></span>
              </li>
              </div>

            </ul>

            <ng-template #noResults>
              <div class="no-results">
                <p>{{ 'Shared.NoResults' | translate }}</p>
              </div>
            </ng-template>
          </ng-container>

          <!-- popup dialog -->

          <ng-template #tplContent>

            <div nz-row>

              <div nz-col nzSpan="2">
                <i class="icon icon-warning"></i>
              </div>

              <div nz-col nzSpan="20">
                <p [innerHtml]=" 'EventsScreen.PinModalTitle' | translate: { eventName: eventName}"></p>
                <div [class.error]="hasErrors()">
                  <label class="roche-color-blue">{{'EventsScreen.PinInputLabel' | translate}}</label>
                  <input nz-input type="password" [(ngModel)]="pin" (keydown.enter)="authenticatePin()"/>
                  <p *ngIf="errorPin">{{'EventsScreen.WrongPin' | translate}}</p>
                  <p *ngIf="errorFetchingDatapackage">{{'EventsScreen.ErrorFetchingDatapackage' | translate}}</p>
                </div>
              </div>

            </div>



          </ng-template>
          <ng-template #tplFooter>
            <button nz-button nzDanger (click)="closeModal()">
              {{'EventsScreen.Cancel' | translate}}
            </button>
            <button nz-button nzType="primary" (click)="authenticatePin()" [nzLoading]="tplModalButtonLoading">
              Ok
            </button>
          </ng-template>
          <!-- /popup dialog -->


      </div>
    </div>
  </div>
</div>
