import { Codable } from '../interfaces/serialization';

export class Target implements Codable<Target> {

    id: string;
    name: string;
    description: string;
    substances: string[];
    imageId: string;
    pdfId: string;
    visible: Boolean;
    hasContent: Boolean;
    position: number;
    modeOfActionId: string;
    testingSubstances: string[];

    deserialize(object: any, id?: any): Target {
        const target = new Target();
        target.id = object.id || id;
        target.name = object.name || "";
        target.description = object.description || "";
        target.substances = object.substances || [];
        target.imageId = object.image_id || null;
        target.pdfId = object.pdf_id || null;
        target.visible = object.visible || false;
        target.hasContent = object.hasContent || false;
        target.position = object.position || 0;
        target.modeOfActionId = object.mode_of_action_id || null;
        target.testingSubstances = Array.isArray(object.testing_substances) ? object.testing_substances : [];
        return target;
    }

    serialize(): any {
        let newSubstancesList = []
        if (this.substances.length > 0) {
            for (let i = 0; this.substances.length > i; i++) {
                if (typeof (this.substances[i]) == "string") {
                    newSubstancesList.push(this.substances[i])
                }
            }
        }

        return {
            id: this.id,
            name: this.name,
            description: typeof (this.description) == "string" ? this.description : "",
            substances: Array.isArray(this.substances) ? newSubstancesList : [],
            image_id: this.imageId ? this.imageId.length ? this.imageId : null : null,
            pdf_id: this.pdfId ? this.pdfId.length ? this.pdfId : null : null,
            visible: typeof (this.visible) == "boolean" ? this.visible || false : false,
            hasContent: typeof (this.hasContent) == "boolean" ? this.hasContent || false : false,
            position: typeof (this.position) == "number" ? this.position >= 0 ? this.position : 0 : 0,
            mode_of_action_id: this.modeOfActionId ? this.modeOfActionId.length ? this.modeOfActionId : null : null,
            testing_substances: Array.isArray(this.testingSubstances) ? this.testingSubstances : [],
        };
    }

}
