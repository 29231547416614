<div class="tabs-wrapper">

  <div class="tabs-bg-white-container">
    <div class="custom-container">
      <div nz-row>
        <div nz-col [nzLg]="{span: 20, offset : 2}" [nzLg]="{span: 20, offset : 2}">

          <div class="custom-container">
            <nz-tabset nzType="card" [class.no-tab-selected]="selectedTabIndex===false" [nzSelectedIndex]="selectedTabIndex">
              <nz-tab *ngFor="let tab of tabs" [nzTitle]="tab.topic.name" (nzClick)="didClickTab(tab)">
              </nz-tab>
            </nz-tabset>
          </div>

        </div>
    </div>
    </div>
  </div>

  <div class="custom-container">
    <app-breadcrumbs></app-breadcrumbs>
    <router-outlet></router-outlet>
  </div>
</div>
