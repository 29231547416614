import { Codable } from '../interfaces/serialization';

export class StudyPhase implements Codable<StudyPhase> {

    id: string;
    name: string;
    position: number;

    deserialize(object: any, id?: any): StudyPhase {
        const studyPhase = new StudyPhase();
        studyPhase.id = object.id || id;
        studyPhase.name = object.name;
        studyPhase.position = object.position;
        return studyPhase;
    }

    serialize(): any {
        return {
            id: this.id,
            name: this.name,
            position: this.position
        };
    }

}

