import { Component, OnInit, Input } from '@angular/core';
import { Study } from 'src/app/modules/api-client/models';

@Component({
  selector: 'app-grid-item-study',
  templateUrl: './grid-item-study.component.html',
  styleUrls: ['./grid-item-study.component.scss']
})
export class GridItemStudyComponent implements OnInit {

  pillMarginTop = 5;

  // to avoid using absolute positions we calculate the height of the pill
  // by multiplying the original height with the 'absorbed' studies
  // we also take into account the extra vertial padding / space between pills
  get rolloverPillHeight(): string {
    return this.isRolloverStudy ? `calc(${(1 + this.rolloverStudies?.length) * 100}% + ${this.rolloverStudies?.length * this.pillMarginTop}px)` : `100%`;
  }

  get isRolloverStudy(): boolean {
    return this.rolloverStudies?.length > 0;
  }

  @Input()
  title: string;

  @Input()
  isRecruiting: boolean;

  @Input()
  rolloverStudies: Study[];

  @Input()
  targetRolloverStudy: Study;

  @Input()
  hasContent: boolean;

  constructor() { }

  ngOnInit() {
  }

}
