import { Injectable } from '@angular/core';
import { HttpRequest, HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RequestsFailedLoggerService {

  private failedRequests: { request: HttpRequest<any>, response: HttpErrorResponse }[];

  private maxLoggedRequests = 10;

  constructor() {
    this.failedRequests = [];
  }

  logFailedRequest(request: HttpRequest<any>, response: HttpErrorResponse) {
    this.failedRequests.splice(0, 0, { request, response });
    if (this.failedRequests.length > this.maxLoggedRequests) {
      this.failedRequests.length = this.maxLoggedRequests;
    }
  }

  getFailedRequestForResponse(response: HttpErrorResponse): HttpRequest<any> {
    const failed = this.failedRequests.find(fr => fr.response === response);
    return failed ? failed.request : null;
  }
}
