import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from '../modules/api-client/services/auth.service';
import { ApiClientService } from '../modules/api-client/services/api-client.service';
import { tap, map, catchError } from 'rxjs/operators';
import { TempEventService } from '../modules/shared/services/temp-event.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService,
    private apiClient: ApiClientService,
    private tempEventService: TempEventService
  ) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const token = this.authService.getDecodedJwtToken();

    if (this.tempEventService.isTempEvent()) {
      return of(true);
    } else {
      // not a temp event, must have user
      if (token) {
        return this.apiClient.getUser(token.sub)
        .pipe(
          tap(user => this.authService.setCurrentUser(user)),
          map(user => {
            return !!user ? true : false;
          }),
          catchError(() => this.router.navigate(['/login']))
        );
      } else {
          this.router.navigate(['/login']);
      }
    }
  }

}
