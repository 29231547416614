import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalDatabaseService } from 'src/app/modules/shared/services/local-database.service';
import { ModeOfAction, Topic, Speciality } from 'src/app/modules/api-client/models';

@Component({
  selector: 'app-mode-of-actions',
  templateUrl: './mode-of-actions.component.html',
  styleUrls: ['./mode-of-actions.component.scss']
})
export class ModeOfActionsComponent implements OnInit {

  modesOfAction: ModeOfAction[];
  topic: Topic;
  speciality: Speciality;
  pageReady: boolean;

  constructor(
    public activatedRoute: ActivatedRoute,
    private localDb: LocalDatabaseService,
    public router: Router
  ) {
  }

  ngOnInit() {

    this.pageReady = false;

    this.activatedRoute.parent.params.subscribe(params => {
      if (params.topicId) {
        this.topic = this.localDb.getTopic(params.topicId);
        this.speciality = this.localDb.getSpeciality(params.specialityId);
        this.modesOfAction = this.localDb.getModeOfActionForTopicId(params.topicId, params.specialityId);
      }
    });

    // redirect to targets if only one mode of action
    if (this.modesOfAction.length === 1) {
      this.toModeOfActionTargets(this.modesOfAction[0]);
    } else {
      this.pageReady = true;
    }

  }

  toModeOfActionTargets(modeOfAction: ModeOfAction) {
    this.router.navigate([`specialities/${this.speciality.id}/topics/${this.topic.id}/mode_of_actions/${modeOfAction.id}/targets`]);
  }

}
