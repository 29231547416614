import { Codable } from '../interfaces/serialization';

export enum TopicType {
    MODE_OF_ACTIONS = 'MODE_OF_ACTIONS',
    GLASS_BODY = 'GLASS_BODY',
    GLASS_BODY_STUDIES = 'GLASS_BODY_STUDIES',
    GLASS_BODY_CURRENT_DATA = 'GLASS_BODY_CURRENT_DATA',
    PIPELINE_OVERVIEW = 'PIPELINE_OVERVIEW',
    ANALYSIS = 'ANALYSIS'
}

export class Topic implements Codable<Topic> {

    id: string;
    name: string;
    description: string;
    position: number;
    imageId: string;
    pdfId: string;
    type: TopicType;
    imageGlassBodyId: string;

    deserialize(object: any, id?: any): Topic {
        const topic = new Topic();
        topic.id = object.id || id;
        topic.name = object.name;
        topic.description = object.description;
        topic.position = object.position;
        topic.imageId = object.image_id;
        topic.pdfId = object.pdf_id;
        topic.type = object.type;
        topic.imageGlassBodyId = object.image_glass_body_id;
        return topic;
    }

    serialize(): any {
        return {
            id: this.id,
            name: this.name,
            description: this.description,
            position: this.position,
            image_id: this.imageId,
            pdf_id: this.pdfId,
            type: this.type,
            image_glass_body_id: this.imageGlassBodyId ? this.imageGlassBodyId : ""
        };
    }

}
