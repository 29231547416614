import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { async, combineLatest, Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { getUnixTime } from 'date-fns';
import { EventsQueryParams, BasicQueryParams } from '../interfaces/query-params';
import { Deserializable, Codable, Serializable } from '../interfaces/serialization';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { v4 as uuid } from 'uuid';
import { pluck } from 'rxjs/operators';
import {
  EventConfiguration,
  EventFullData,
  Attachment,
  List,
  Image,
  User,
  Substance,
  Category,
  Event,
  ModeOfAction,
  Phase,
  Speciality,
  Study,
  StudyCategory,
  StudyPhase,
  Target,
  Topic,
} from '../models';

@Injectable({
  providedIn: 'root'
})
export class ApiClientService {

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { }

  isApiAvailable(): Observable<boolean> {
    return this.http.get(`${environment.api_url}`)
      .pipe(
        map(() => true),
        catchError((response: HttpErrorResponse) => {
          return of(response.status === 401);
        })
      );
  }

  getUser(id: string): Observable<User> {
    return this.get('users', id, User);
  }

  getEventFullData(eventId: string, packagePin: string): Observable<EventFullData> {
    let headers = new HttpHeaders();
    headers = headers.set('X-Package-Pin', btoa(packagePin));
    return this.get(`events`, `${eventId}/full_data`, EventFullData, null, headers)
      .pipe(
        map(fullData => {
          fullData.event.id = eventId;
          return fullData;
        })
      );
  }

  getEventFullDataFromToken(eventId: string): Observable<EventFullData> {
    return this.get(`events`, `${eventId}/full_data`, EventFullData, null)
      .pipe(
        map(fullData => {
          fullData.event.id = eventId;
          return fullData;
        })
      );
  }

  getEvents(query?: EventsQueryParams, edition?: boolean): Observable<List<Event>> {
    if (edition) {
      this.list('events', Event, query).pipe(pluck('items')).subscribe((res) => {
        return res
      })
    }
    else {
      let llamada = this.list('events', Event, query)
      llamada.pipe(pluck('items')).subscribe((res) => {
      })
      return llamada
    }
  }

  getAttachment(attachmentId: string): Observable<Blob> {
    return this.http.get(`${environment.api_url}/attachments/${attachmentId}/stream`, { responseType: 'blob' });
  }

  getAttachments(attachmentIds: string[]): Observable<{ blob: Blob, attachmentId: string }[]> {
    const requests = attachmentIds.map(attachmentId => this.getAttachment(attachmentId));
    return combineLatest(requests).pipe(map(blobs => {
      return blobs.map((blob, index) => {
        return {
          blob,
          attachmentId: attachmentIds[index]
        };
      });
    }));
  }

  refreshToken(): Observable<string> {
    const token = this.authService.getDecodedJwtToken();
    const payload = { token: token.refreshToken };
    return this.http.post(`${environment.api_url}/refresh_token`, payload).pipe(
      map((body: any) => {
        this.authService.setJwtToken(body.jwt_token);
        return body.jwt_token;
      }),
      catchError(error => { throw error; })
    );
  }

  //---------------------------------------------- New Functions -------------------------------------------------------------->

  checkEditorAuthorization() {
    return new Promise((resolve, reject) => {
      this.http.get(`${environment.api_url}/${"editorTool"}`).subscribe((res: any) => { if (res.data === true) { resolve(res) } else { reject(res) } })
    })
  }
  checkStatsAuthorization() {
    return new Promise((resolve, reject) => {
      this.http.get(`${environment.api_url}/${"statsTool"}`).subscribe((res: any) => { if (res.data === true) { resolve(res.data) } else { reject(res) } })
    })
  }
  // checkShowStatAllInformation() {
  //   return new Promise((resolve, reject) => {
  //     this.http.get(`${environment.api_url}/${"statsToolInformation"}`).subscribe((res: any) => { resolve(res.data) })
  //   })
  // }
  getStatsFileInformation() {
    return new Promise((resolve, reject) => {
      this.http.get(`${environment.api_url}/${"statsFileInformation"}`).subscribe((res: any) => { resolve(res.data) })
    })
  }
  // getStructureFileInformation() {
  //   return new Promise((resolve, reject) => {
  //     this.http.get(`${environment.api_url}/${"structureFileInformation"}`).subscribe((res: any) => { resolve(res.data) })
  //   })
  // }

  getCollection(path, observableType, locale?): Observable<List<any>> { //
    return this.newList(path, observableType, locale ? { locale: locale, page_size: 1000 } : { page_size: 1000 })
  }

  updateInformation(id: string, object: any, locale: string, mode: string, path: string, paramId: string, option: string) {
    return new Promise((resolve, reject) => {
      try {
        let headers
        //headers = new HttpHeaders().set('cabecera1', 'cabecera');

        const errorAnswer = function (error) {
          console.error("Error", error)
          reject(error)
        }

        let params = new HttpParams();
        if (path == "events") { params = params.set("newEvent", "newEvent"); }
        if (mode == "edit") {
          params = params.set('locale', locale);
          params = params.set(paramId, id);
          if (path == "events") {

            let keys = Object.keys(object.configuration)
            let objectConfiguration = new EventConfiguration()

            for (let i = 0; i < keys.length; i++) {
              objectConfiguration[keys[i]] = object.configuration[keys[i]]
            }

            this.newPut("configuration", id, objectConfiguration, locale, mode, option, params, headers).subscribe(() => {
              this.newPut(path, id, object, locale, mode, option, params, headers).subscribe(() => { resolve(null); }, (error) => { errorAnswer(error); });
              resolve(null)
            }, (error) => { errorAnswer(error); });

          } else {
            this.newPut(path, id, object, locale, mode, option, params, headers).subscribe(() => { resolve(null); }, (error) => { errorAnswer(error); });
          }
        }
        else if (mode == "new") {
          if (path == "events") {
            let keys = Object.keys(object.configuration)
            let objectConfiguration = { ...object.configuration }
            object.configuration = new EventConfiguration()

            for (let i = 0; i < keys.length; i++) {
              object.configuration[keys[i]] = objectConfiguration[keys[i]]
            }
            this.newPost(path, object, mode, option, params).subscribe(() => { resolve(null); }, (error) => { errorAnswer(error); });
          }
          else {
            if (path == "Images" || path == "Pdfs" || path == "Videos") { params = params.set("fileName", object.fileInformation.name); }
            (async () => {
              if (option == "Images" && object.fileInformation || option == "Pdfs" && object.fileInformation) {
                object.attachmentId = await this.createAttachments(option, object, locale)
              }
              else if (option == "Videos" && object.isPdfSubstance) {
                object.slideshowId = await this.createAttachments(option, object, locale)
              }
              this.newPost(path, object, mode, option, params).subscribe(() => { resolve(null); }, (error) => { errorAnswer(error); });
            })()
          }
        }
        else {
          resolve(null)
        }
      } catch (error) { reject(error) }
    })
  }

  //----------------------------------------------- Original Calls ------------------------------------------------------------->

  private list<T extends Deserializable<T>>(path: string, type: (new () => T), query?: BasicQueryParams): Observable<List<T>> {
    return this.http.get(`${environment.api_url}/${path}${this.toQueryString(query)}`).pipe(
      map((response: any) => new List<T>().deserialize(response, path, type)),
      catchError(error => { throw error; })
    );
  }

  private get<T extends Deserializable<T>>(path: string, id: string, type: (new () => T), query?: any,
    headers?: HttpHeaders): Observable<T> {
    return this.http.get(`${environment.api_url}/${path}/${id}${this.toQueryString(query)}`, { headers }).pipe(
      map(response => new type().deserialize(response, id)),
      catchError(error => { throw error; })
    );
  }

  private post<T extends Codable<T>>(path: string, object: Codable<T>): Observable<T> {
    return this.http.post(`${environment.api_url}/${path}`, object.serialize(), { observe: 'response' }).pipe(
      map(response => {
        const components = response.headers.get('Location').split('/');
        const id = components[components.length - 1];
        return object.deserialize(response.body, id);
      }),
      catchError(error => { throw error; })
    );
  }

  private put<T extends Serializable<T>>(path: string, id: string, object: Serializable<T>): Observable<void> {
    return this.http.put(`${environment.api_url}/${path}/${id}`, object.serialize()).pipe(
      map(() => { return; }),
      catchError((response: HttpErrorResponse) => {
        if (response.status === 304) { return of(undefined); } // Treat 304. Not Modified as success
        throw response;
      })
    );
  }

  private delete(path: string, id: string): Observable<void> {
    return this.http.delete(`${environment.api_url}/${path}/${id}`).pipe(
      map(() => { return; }),
      catchError(error => { throw error; })
    );
  }

  private toQueryString(query?: BasicQueryParams): string {
    if (!query) { return ''; }
    const keys = Object.keys(query);
    const components = keys.map(key => {
      let value = query[key];
      if (value instanceof Date) {
        value = getUnixTime(value);
      }
      if (value instanceof Array) {
        return value.map(v => `${key}=${encodeURIComponent(v)}`).join('&');
      }
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    });
    return components.length > 0 ? '?' + components.join('&') : '';
  }

  //-------------------------------------------------- New Calls ---------------------------------------------------------->

  private newList<T extends Deserializable<T>>(path: string, type: (new () => T), query?: any): Observable<List<T>> {
    return this.http.get(`${environment.api_url}/${path}${this.toQueryString(query)}`).pipe(
      map((response: any) => {
        return new List<T>().deserialize(response, path, type)
      }),
      catchError(error => { throw error; })
    );
  }

  private newPut<T extends Serializable<T>>(path: string, id: string, object: Serializable<T>, locale: string, mode: string, option: string, params?: HttpParams, headers?: HttpHeaders): Observable<void> {
    return this.http.put(path != "configuration" ? `${environment.api_url}/${path}/${id}` : `${environment.api_url}/events/${id}/configuration`, path != "configuration" ? this.optionsToSerialize(object, mode, option, locale) : this.optionsToSerialize(object, mode, "configuration", locale), { headers, params }).pipe(
      map(() => { return }),
      catchError((response: HttpErrorResponse) => {
        if (response.status === 304) { console.log("error 304"); return of(undefined); } // Treat 304. Not Modified as success
        throw response;
      })
    );
  }

  private newPost<T extends Codable<T>>(path: string, object: Codable<T>, mode: string, option: string, params?: HttpParams, headers?: HttpHeaders): Observable<T> {
    return this.http.post(`${environment.api_url}/${path}`, this.optionsToSerialize(object, mode, option), { observe: 'response', params: params }).pipe(
      map(response => {
        const components = response.headers.get('Location').split('/');
        const id = components[components.length - 1];
        return object.deserialize(response.body, id);
      }),
      catchError(error => { throw error; })
    );
  }

  //----------------------------------------------------------------------------------------------------------------------------------------------------------->

  optionsToSerialize(object, mode, option, locale?) {

    let serializeValues = object.serialize()
    delete serializeValues["id"]

    if (mode == "edit") {
      if (option != "configuration") {
        serializeValues["locale"] = locale
      }
      delete serializeValues["id"]
    }

    switch (option) {
      case "Specialities": break;
      case "Categories": break;
      case "Countries": break;
      case "Events": break;
      case "Locales": break;
      case "ModeOfActions": break;
      case "Phases": break;
      case "Studies": break;
      case "StudiesCategories": break;
      case "StudiesPhases": break;
      case "Substances": break;
      case "Targets": break;
      case "Topics": break;
      case "Images": break;
      case "Attachments":
        if (mode == "edit") {
          delete serializeValues["locale"]
          // delete serializeValues["grid_fs_id"]
          // delete serializeValues["file_size"]
        }
        break;
      case "Pdfs":
        delete serializeValues["pages"]
        delete serializeValues["thumbnail_attachment_id"]
        break;
      case "Videos": break;
    }
    return serializeValues
  }

  //------------------------------------------------------------------------------------------------------------>

  createAttachments(option, object, locale) {

    return new Promise((resolve, reject) => {
      const vm = this;

      let fileType = {
        video_type: "",
        is_image: false,
        is_video_slideshow: false,
        is_video: false,
        pdf_generate_thumbnail: false
      }

      if (option == "Images") { fileType.is_image = true; }
      if (option == "Videos" && object.isPdfSubstance) { fileType.is_video_slideshow = true; }

      function createAttachment(attachmentId, fileId, fileSize,
        contentType, pages, thumbnailId) {

        let attachment = new Attachment()
        attachment.contentType = contentType
        attachment.fileInformation = object.fileInformation

        return new Promise((resolve, reject) => {
          try {
            let params = new HttpParams();
            params = params.set("fileName", object.fileInformation.name);
            if (object.isPdfSubstance) { params = params.set("isPdfSubstance", "isPdfSubstance"); }
            vm.newPost(`attachments`, attachment, "new", "Attachments", params)
              .subscribe((res) => {
                params = params.set("size", object.fileInformation.size);
                vm.newPost(`attachments/${res.id}/payload`, attachment, "new", "Attachments", params)
                  .subscribe((res) => {
                    resolve(null)
                  })
                resolve(res.id);
              });
            //
          } catch (error) { reject(error) }
        })
      }

      try {
        if (fileType.is_image) {
          createAttachment(object.attachment_id, null,
            null, 'image/png', null, null).then((res) => { resolve(res) });
        } else if (fileType.is_video_slideshow) {
          createAttachment(object.attachment_id, null,
            null, 'application/pdf', null, null).then((res) => { resolve(res) });
        } else if (fileType.is_video) {
          return createAttachment(object.attachment_id, null,
            null, fileType.video_type, null, null);
        } else {
          createAttachment(object.attachment_id, null,
            null, 'application/pdf', null, null).then((res) => { resolve(res) });
          // return createPdfThumbnailAndPages(null, null,
          //   object.name, fileType.pdf_generate_thumbnail);
        }
      } catch (error) { reject(error) }
    }).then((res) => {
      return res
    }).catch((error) => { console.log("error", error) })
  }
}