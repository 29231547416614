import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Study, Substance } from '../../api-client/models';
import { LocalDatabaseService } from '../services/local-database.service';

export class SearchResults {
  substances: Substance[];
  studies: Study[];
}

@Injectable({
  providedIn: 'root'
})
export class SearchUtil {

  searchTerm: string;

  private searchResultsSubject = new BehaviorSubject<SearchResults>(null);

  constructor(
    private localDb: LocalDatabaseService,
    private location: Location
  ) { }

  results(): Observable<SearchResults> {
    return this.searchResultsSubject.asObservable();
  }

  search() {
    const results: SearchResults = {
      substances: [],
      studies: []
    };
    if (!!this.searchTerm) {
      results.substances = this.localDb.searchSubstancesWithTerm(this.searchTerm, this.getCurrentSpecialityId());
      results.studies = this.localDb.searchStudiesWithTerm(this.searchTerm, this.getCurrentSpecialityId());
      this.searchResultsSubject.next(results);
    } else {
      this.searchResultsSubject.next(null);
    }
  }

  clear() {
    this.searchTerm = null;
    this.searchResultsSubject.next(null);
  }

  getCurrentSpecialityId(): string {
    const pathComponents = this.location.path().split('/');
    const index = pathComponents.indexOf('specialities');
    return index + 1 < pathComponents.length ? pathComponents[index + 1] : null;
  }
}
