import { Codable } from '../interfaces/serialization';

export class Locale implements Codable<Locale> {

    name: string;
    locale: string;

    deserialize(object: any, id?: any): Locale {
        const locale = new Locale();

        locale.name = object.name;
        locale.locale = object.locale;
        return locale;
    }

    serialize(): any {
        return {
            name: this.name,
            locale: this.locale,
        };
    }

}
