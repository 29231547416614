<div nz-row>
  <div nz-col nzSpan="12" nzOffset="6">
    <div class="roche-title-section-wrapper roche-mt-50 roche-mb-50">
      <h1 class="roche-text-center roche-title-section">{{substance.name}}<ng-container *ngIf="substance.activeSubstance"> ({{substance.activeSubstance}})</ng-container></h1>
    </div>
  </div>
</div>

<div nz-row class="roche-mb-50">

  <div nz-col [nzXl]="{ span: 12, offset : 6 }" [nzSm]="{ span: 20, offset : 2 }">

    <!-- focus area -->
    <div nz-row class="moa-substance-details">

      <div nz-col class="moa-substance-content" [nzMd]="{ span : 24 }">
        <ng-container *ngIf="substance.images[0]">
          <div class="moa-substance-image" [style.backgroundImage]="substance.images[0] | toAttachmentId | authImg:'':'style' | async"></div>
        </ng-container>

        <div class="moa-substance-description">
          <div class="moa-text-overlay-fx"></div>

          <div [innerHTML]="(substance.description)"></div>

        </div>

        <!-- substance buttons -->
        <div class="moa-substance-info">

            <ng-container *ngIf="(topic.type === 'ANALYSIS' && mediaItems && mediaItems.length > 0); else standardLayout" >
              <a *ngFor="let option of mediaItems" (click)="didSelectMedia(option.id)" class="roche-dropup-component-button roche-mr-10">{{ option.label }}</a>
            </ng-container>

            <ng-template #standardLayout>
              <!-- When have more than one item, just show title "Options" in the button -->
              <app-drop-up [isMediaButton]="true" *ngIf="(mediaItems && mediaItems.length > 0)" (didSelectOption)="didSelectMedia($event)" [options]="mediaItems" [title]="'Options'"></app-drop-up>
              <!-- <app-drop-up [isMediaButton]="true" *ngIf="(mediaItems && mediaItems.length > 0)" (didSelectOption)="didSelectMedia($event)" [options]="mediaItems" [title]="mediaItems[0]?.label"></app-drop-up> -->
            </ng-template>

            <app-drop-up *ngIf="checkContentStudies(clinicalTrials,'RECRUITING_STUDY')" (didSelectOption)="didSelectClinicalTrials($event)" [options]="clinicalTrials" [title]="'SubstanceScreen.ActiveStudies' | translate"></app-drop-up>
            <!-- <app-drop-up *ngIf="(clinicalTrials && clinicalTrials.length > 0)" (didSelectOption)="didSelectClinicalTrials($event)" [options]="clinicalTrials" [title]="'SubstanceScreen.ActiveStudies' | translate"></app-drop-up> -->
            <app-drop-up *ngIf="checkContentStudies(clinicalData,'CURRENT_DATA')" (didSelectOption)="didSelectClinicalData($event)" [options]="clinicalData" [title]="'SubstanceScreen.ClinicalData' | translate"></app-drop-up>
            <!-- <app-drop-up *ngIf="(clinicalData && clinicalData.length > 0)" (didSelectOption)="didSelectClinicalData($event)" [options]="clinicalData" [title]="'SubstanceScreen.ClinicalData' | translate"></app-drop-up> -->
        </div>
        <!-- /substance buttons -->

      </div>

    </div>
    <!-- /focus area -->

  </div>
</div>
