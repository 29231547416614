import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './guards/auth.guard';
import { LoginComponent } from './pages/login/login.component';
import { EventsComponent } from './pages/events/events.component';
import { SpecialitiesComponent } from './pages/specialities/specialities.component';
import { TopicComponent } from './pages/topic/topic.component';
import { SsoSuccessComponent } from './pages/sso-success/sso-success.component';
import { SsoErrorComponent } from './pages/sso-error/sso-error.component';
import { GlassbodyComponent } from './pages/tabs/glassbody/glassbody.component';
import { ModeOfActionsComponent } from './pages/tabs/mode-of-actions/mode-of-actions.component';
import { PipelineOverviewComponent } from './pages/tabs/pipeline-overview/pipeline-overview.component';
import { AnalysisComponent } from './pages/tabs/analysis/analysis.component';
import { TargetsComponent } from './pages/tabs/targets/targets.component';
import { TargetComponent } from './pages/tabs/target/target.component';
import { StudiesComponent } from './pages/tabs/studies/studies.component';
import { SubstanceComponent } from './pages/substance/substance.component';
import { MediaComponent } from './pages/media/media.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { SearchComponent } from './pages/tabs/search/search.component';
import { TempEventComponent } from './modules/shared/components/temp-event/temp-event.component';

// import { EditionComponent } from './pages/edition/edition.component';
import { StatsComponent } from './pages/stats/stats.component';
// import { EditionNewComponent } from './pages/editionNew/editionNew.component';
import { GroupAssetsComponent } from './pages/tabs/groupAssets/groupAssets.component';
import { AssetComponent } from './pages/tabs/asset/asset.component';

const routeMOAToAsset = 'mode_of_actions/:modeOfActionId/groupAssets/:targetId/asset/:substanceId'

const routeMOAToSubstance = 'mode_of_actions/:modeOfActionId/targets/:targetId/substance/:substanceId'

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'share/:token',
    component: TempEventComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'sso_success',
    component: SsoSuccessComponent
  },
  {
    path: 'sso_error',
    component: SsoErrorComponent
  },
  // {
  //   path: 'edition',
  //   component: EditionComponent,
  //   canActivate: [AuthGuard]
  // },
  // {
  //   path: 'editionNew',
  //   component: EditionNewComponent,
  //   canActivate: [AuthGuard]
  // },
  {
    path: 'stats',
    component: StatsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'events',
    component: EventsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'specialities',
    component: SpecialitiesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'media/:mediaId',
    component: MediaComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'substance/:substanceId',
    component: SubstanceComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'substance/:substanceId/media/:mediaId',
    component: MediaComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'specialities/:specialityId/media/:mediaId',
    component: MediaComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'specialities/:specialityId/topics/:topicId',
    component: TopicComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'search',
        component: SearchComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'glassbody_studies',
        component: GlassbodyComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'glassbody_current_data',
        component: GlassbodyComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'pipeline_overview',
        component: PipelineOverviewComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'analysis',
        component: AnalysisComponent,
        canActivate: [AuthGuard]
      },

      {
        path: 'media/:mediaId',
        component: MediaComponent,
        canActivate: [AuthGuard]
      },

      //studies
      {
        path: 'study/:studyId/media/:mediaId',
        component: MediaComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'recruiting_studies/:categoryId',
        component: StudiesComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'recruiting_studies/:categoryId/media/:mediaId',
        component: MediaComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'current_data/:categoryId',
        component: StudiesComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'current_data/:categoryId/media/:mediaId',
        component: MediaComponent,
        canActivate: [AuthGuard]
      },

      //substances
      {
        path: 'substance/:substanceId',
        component: SubstanceComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'substance/:substanceId/media/:mediaId',
        component: MediaComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'substance/:substanceId/recruiting_studies/:categoryId',
        component: StudiesComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'substance/:substanceId/recruiting_studies/:categoryId/media/:mediaId',
        component: MediaComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'substance/:substanceId/current_data/:categoryId',
        component: StudiesComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'substance/:substanceId/current_data/:categoryId/media/:mediaId',
        component: MediaComponent,
        canActivate: [AuthGuard]
      },
      //mode_of_actions
      {
        path: 'mode_of_actions',
        component: ModeOfActionsComponent,
        canActivate: [AuthGuard]
      },
      //targets
      {
        path: 'mode_of_actions/:modeOfActionId/targets',
        component: TargetsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'mode_of_actions/:modeOfActionId/targets/:targetId',
        component: TargetComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'mode_of_actions/:modeOfActionId/targets/:targetId/media/:mediaId',
        component: MediaComponent,
        canActivate: [AuthGuard]
      },
      {
        path: routeMOAToSubstance,
        component: SubstanceComponent,
        canActivate: [AuthGuard]
      },
      {
        path: `${routeMOAToSubstance}/media/:mediaId`,
        component: MediaComponent,
        canActivate: [AuthGuard]
      },
      {
        path: `${routeMOAToSubstance}/recruiting_studies/:categoryId`,
        component: StudiesComponent,
        canActivate: [AuthGuard]
      },
      {
        path: `${routeMOAToSubstance}/recruiting_studies/:categoryId/media/:mediaId`,
        component: MediaComponent,
        canActivate: [AuthGuard]
      },
      {
        path: `${routeMOAToSubstance}/current_data/:categoryId`,
        component: StudiesComponent,
        canActivate: [AuthGuard]
      },
      {
        path: `${routeMOAToSubstance}/current_data/:categoryId/media/:mediaId`,
        component: MediaComponent,
        canActivate: [AuthGuard]
      },
      //assets
      {
        path: 'mode_of_actions/:modeOfActionId/groupAssets',
        component: GroupAssetsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: routeMOAToAsset,
        component: AssetComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${routeMOAToAsset}/recruiting_studies/:categoryId`,
        component: StudiesComponent,
        canActivate: [AuthGuard]
      },
      {
        path: `${routeMOAToAsset}/recruiting_studies/:categoryId/media/:mediaId`,
        component: MediaComponent,
        canActivate: [AuthGuard]
      },
      {
        path: `${routeMOAToAsset}/current_data/:categoryId`,
        component: StudiesComponent,
        canActivate: [AuthGuard]
      },
      {
        path: `${routeMOAToAsset}/current_data/:categoryId/media/:mediaId`,
        component: MediaComponent,
        canActivate: [AuthGuard]
      },
      {
        path: `${routeMOAToAsset}/media/:mediaId`,
        component: MediaComponent,
        canActivate: [AuthGuard]
      },
    ]
  },
  {
    path: '404',
    component: NotFoundComponent
  },
  {
    path: '**',
    redirectTo: '/404',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
