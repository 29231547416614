import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateModule, TranslateLoader, TranslateCompiler } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { HttpClient } from '@angular/common/http';
import { SharedModule } from './modules/shared/shared.module';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { NZ_I18N, de_DE, NZ_DATE_CONFIG } from 'ng-zorro-antd/i18n';
import { LoginComponent } from './pages/login/login.component';
import { EventsComponent } from './pages/events/events.component';
import { SpecialitiesComponent } from './pages/specialities/specialities.component';
import { TopicComponent } from './pages/topic/topic.component';
import { PipelineOverviewComponent } from './pages/tabs/pipeline-overview/pipeline-overview.component';
import { ApiClientModule } from 'src/app/modules/api-client/api-client.module';
import { SsoSuccessComponent } from './pages/sso-success/sso-success.component';
import { SsoErrorComponent } from './pages/sso-error/sso-error.component';
import { GlassbodyComponent } from './pages/tabs/glassbody/glassbody.component';
import { ModeOfActionsComponent } from './pages/tabs/mode-of-actions/mode-of-actions.component';
import { AnalysisComponent } from './pages/tabs/analysis/analysis.component';
import { TargetsComponent } from './pages/tabs/targets/targets.component';
import { TargetComponent } from './pages/tabs/target/target.component';
import { StudiesComponent } from './pages/tabs/studies/studies.component';
import { SubstanceComponent } from './pages/substance/substance.component';
import { MediaComponent } from './pages/media/media.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { SearchComponent } from './pages/tabs/search/search.component';
import { MatomoModule } from 'ngx-matomo';
// import { EditionComponent } from './pages/edition/edition.component';
import { StatsComponent } from './pages/stats/stats.component';
// import { EditionNewComponent } from './pages/editionNew/editionNew.component';
// import { EditionPipelineOverview } from './pages/edition/components_edition/pipelineOverview/pipelineOverview'
// import { EditionPopup } from './pages/edition/components_edition/popupS/popupS'
// import { EditionCustomSelect } from './pages/edition/components_edition/customSelect/customSelect'
import { GroupAssetsComponent } from './pages/tabs/groupAssets/groupAssets.component';
import { AssetComponent } from './pages/tabs/asset/asset.component';



registerLocaleData(localeDe, 'de');

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    EventsComponent,
    SpecialitiesComponent,
    TopicComponent,
    PipelineOverviewComponent,
    SsoSuccessComponent,
    SsoErrorComponent,
    GlassbodyComponent,
    ModeOfActionsComponent,
    AnalysisComponent,
    TargetsComponent,
    TargetComponent,
    StudiesComponent,
    SubstanceComponent,
    MediaComponent,
    NotFoundComponent,
    SearchComponent,
    // EditionComponent,
    StatsComponent,
    // EditionNewComponent,
    // EditionPipelineOverview,
    // EditionPopup,
    // EditionCustomSelect,
    GroupAssetsComponent,
    AssetComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    ApiClientModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: (createTranslateLoader),
          deps: [HttpClient]
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler
      }
    }),
    MatomoModule
  ],
  providers: [
    {
      provide: NZ_I18N,
      useValue: de_DE
    },
    {
      provide: NZ_DATE_CONFIG,
      useValue: {
        firstDayOfWeek: 1
      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
