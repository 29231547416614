import { Component, OnInit } from '@angular/core';
import { Substance, Speciality, Topic, Target, ModeOfAction, Phase } from 'src/app/modules/api-client/models';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalDatabaseService } from 'src/app/modules/shared/services/local-database.service';
// import { MatomoTracker } from 'ngx-matomo';

@Component({
  selector: 'app-asset',
  templateUrl: './asset.component.html',
  styleUrls: ['./asset.component.scss']
})
export class AssetComponent implements OnInit {

  phase: Phase;
  substance: Substance;
  clinicalDataCategories: any;
  speciality: Speciality;
  target: Target;
  modeOfAction: ModeOfAction;
  topic: Topic;
  clinicalTrials: {
    id: string,
    label: string
  }[];
  clinicalData: {
    id: string,
    label: string
  }[];
  mediaItems: {
    id: string,
    label: string
  }[];

  constructor(
    private localDb: LocalDatabaseService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    // private matomoTracker: MatomoTracker
  ) {
    try {

      this.clinicalTrials = [];
      this.clinicalData = [];
      this.mediaItems = [];

      // used for navigation structure
      this.activatedRoute.parent.params.subscribe(params => {
        this.topic = this.localDb.getTopic(params.topicId);
        this.speciality = this.localDb.getSpeciality(params.specialityId);
      });

      this.activatedRoute.params.subscribe(params => {
        try {
          this.target = this.localDb.getTarget(params.targetId);
          this.modeOfAction = this.localDb.getModeOfAction(params.modeOfActionId);
          this.substance = this.localDb.getSubstance(params.substanceId);
          this.activatedRoute.queryParams.subscribe(queryParams => {
            this.phase = this.localDb.getPhase(queryParams.phaseId);
          })

          // prepare options array of objects for app-drop-up component

          // clinicalTrials
          const clinicalTrials =
            this.localDb.getStudyCategoriesForSubstance(this.substance.id, ['RECRUITING_STUDY', 'NONE_RECRUITING_STUDY'], this.speciality.id);

          clinicalTrials.map(item => {
            this.clinicalTrials.push({ id: item.id, label: item.name }); // turn into expected generic format
          });

          this.clinicalTrials.sort((c1, c2) => c1.label < c2.label ? -1 : 1);

          // clinicalData
          const clinicalData = this.localDb.getStudyCategoriesForSubstance(this.substance.id, ['CURRENT_DATA'], this.speciality.id, true);
          clinicalData.map(item => {
            this.clinicalData.push({ id: item.id, label: item.name }); // turn into expected generic format
          });

          this.clinicalData.sort((c1, c2) => c1.label < c2.label ? -1 : 1);

          // media
          const mediaItems = this.localDb.getVideosDetails(this.substance.videos);
          mediaItems.map(item => {
            this.mediaItems.push({ id: item.id, label: item.name });
          });
        } catch (error) { this.navigateToThisUrl() }
      });
    } catch (error) { this.navigateToThisUrl() }
  }

  ngOnInit() {
    // this.matomoTracker.trackEvent('ViewSubstance', 'substance.name', this.substance.name);
  }

  navigateToThisUrl() { //In case of issue, redirect to original direction.
    try {
      if (this.speciality?.id && this.topic?.id && this.modeOfAction?.id && this.target?.id && this.target?.substances[0]) {
        console.error("Error: It could not be possible charge the page correctly");
        this.router.navigate(["specialities", this.speciality.id, "topics", this.topic.id, "mode_of_actions", this.modeOfAction.id, "groupAssets", this.target.id, "asset", this.target.substances[0]], { replaceUrl: true });
      }
      else if (this.speciality.id && this.topic.id) {
        console.error("Error: It could not be possible redirect to new url");
        this.router.navigate(["specialities", this.speciality.id, "topics", this.topic.id, "analysis"], { replaceUrl: true });
      }
      else { throw "redirect, because not enough values available" }
    } catch (error) { this.router.navigate(["404"]); }
  }

  didSelectClinicalTrials(categoryId: string) {
    try {
      if (this.modeOfAction && this.target && this.substance) {
        // tslint:disable-next-line: max-line-length
        this.router.navigate(["specialities", this.speciality.id, "topics", this.topic.id, "mode_of_actions", this.modeOfAction.id, "groupAssets", this.target.id, "asset", this.substance.id, "recruiting_studies", categoryId], { queryParams: { substanceId: this.substance.id } });
      }
      else { throw "Error" }
    } catch (error) { console.error("Error: It could not be possible redirect to new url"); }
  }

  didSelectClinicalData(categoryId: string) {
    try {
      if (this.modeOfAction && this.target && this.substance) {
        // tslint:disable-next-line: max-line-length
        this.router.navigate(["specialities", this.speciality.id, "topics", this.topic.id, "mode_of_actions", this.modeOfAction.id, "groupAssets", this.target.id, "asset", this.substance.id, "current_data", categoryId], { queryParams: { substanceId: this.substance.id } });
      }
      else { throw "Error" }
    } catch (error) { console.error("Error: It could not be possible redirect to new url"); }
  }

  didSelectMedia(videoId: string) {
    try {
      const fullUrl = [...this.activatedRoute.parent.snapshot.url, ...this.activatedRoute.snapshot.url];
      const basePath = fullUrl.map(u => u.path);
      const path = basePath.concat(['media', videoId]);
      this.router.navigate(path);
    } catch (error) { console.error("Error: It could not be possible to open the pdf"); }
  }
}


























// import { Component, OnInit } from '@angular/core';
// import { LocalDatabaseService } from 'src/app/modules/shared/services/local-database.service';
// import { Router, ActivatedRoute } from '@angular/router';
// import { Target, Substance, Speciality, Topic, ModeOfAction } from 'src/app/modules/api-client/models';
// import { MatomoTracker } from 'ngx-matomo';

// @Component({
//   selector: 'app-asset',
//   templateUrl: './asset.component.html',
//   styleUrls: ['./asset.component.scss']
// })
// export class AssetComponent implements OnInit {

//   target: Target;
//   substances: Substance[];
//   speciality: Speciality;
//   topic: Topic;
//   modeOfAction: ModeOfAction;
//   pdfOfTargetName: string;

//   constructor(
//     private localDb: LocalDatabaseService,
//     public activatedRoute: ActivatedRoute,
//     public router: Router,
//     private matomoTracker: MatomoTracker
//   ) { }

//   ngOnInit() {

//     this.activatedRoute.parent.params.subscribe(params => {
//       this.topic = this.localDb.getTopic(params.topicId);
//       this.speciality = this.localDb.getSpeciality(params.specialityId);
//     });

//     this.activatedRoute.params.subscribe(params => {
//       this.target = this.localDb.getTarget(params.targetId);
//       this.substances = this.localDb.getSubstancesDetails(this.target.substances);
//       this.modeOfAction = this.localDb.getModeOfAction(params.modeOfActionId);
//     });

//     const url = `specialities/${this.speciality.id}/topics/${this.topic.id}/mode_of_actions/${this.modeOfAction.id}/groupAssets/${this.target.id}/asset/${this.substances[0].id}`;

//     this.router.navigate([url]);

//     //this.pdfOfTargetName = (this.localDb.getPdf(this.target.pdfId)).name;

//     //this.matomoTracker.trackEvent('ViewTarget', 'target.name', this.target.name);

//   }

//   toSubstanceDetails(substance: Substance) {

//     // tslint:disable-next-line: max-line-length

//   }

//   didSelect() {
//     try {
//       if (this.target.pdfId) {
//         const parentBasePath = this.activatedRoute.parent.snapshot.url.map(u => u.path);
//         const currentBasePath = this.activatedRoute.snapshot.url.map(u => u.path);
//         const absoluteUrl = [...parentBasePath, ...currentBasePath];
//         const path = absoluteUrl.concat(['media', this.target.pdfId]);

//         // preserve queryParams to be able to go back to state if possible
//         this.activatedRoute.queryParams.subscribe(queryParams => {
//           this.router.navigate(path, { queryParams });
//         });
//       }
//       else {
//         console.log("No content available")
//       }
//     } catch (error) { console.error(error) }
//   }
// }
