<div nz-row>

  <div nz-col [nzXl]="{ span: 20, offset : 2 }" [nzSm]="{ span: 20, offset: 1 }">

    <div class="breadcrumbs-wrapper">
      <nz-breadcrumb [nzSeparator]="iconTemplate">
        <ng-container *ngIf="breadcrumbs && breadcrumbs.length == 1 && false">
          <nz-breadcrumb-item>
            <i class="icon icon-house-outline-white"></i>
          </nz-breadcrumb-item>
        </ng-container>

        <nz-breadcrumb-item *ngFor="let breadcrumb of breadcrumbs; let i = index;">

          <ng-container [ngSwitch]="i === breadcrumbs.length - 1">
            <span *ngSwitchCase="true"><span [innerHtml]="breadcrumb.studyPrefixLabel"></span><span [innerHtml]="breadcrumb.label"></span></span>
            <a *ngSwitchDefault (click)="didClickBreadcrumb(breadcrumb)"><span [innerHtml]="breadcrumb.studyPrefixLabel"></span><span [innerHtml]="breadcrumb.label"></span></a>
          </ng-container>

        </nz-breadcrumb-item>
      </nz-breadcrumb>
      <ng-template #iconTemplate><i class="icon icon-chevron-right-white"></i></ng-template>
    </div>
  </div>

</div>
