import { Component, OnInit } from '@angular/core';
import { Substance, Speciality, Topic, Target, ModeOfAction, Phase } from 'src/app/modules/api-client/models';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalDatabaseService } from 'src/app/modules/shared/services/local-database.service';
import { MatomoTracker } from 'ngx-matomo';

@Component({
  selector: 'app-substance',
  templateUrl: './substance.component.html',
  styleUrls: ['./substance.component.scss']
})
export class SubstanceComponent implements OnInit {

  phase: Phase;
  substance: Substance;
  clinicalDataCategories: any;
  speciality: Speciality;
  target: Target;
  modeOfAction: ModeOfAction;
  topic: Topic;
  clinicalTrials: {
    id: string,
    label: string
  }[];
  clinicalData: {
    id: string,
    label: string
  }[];
  mediaItems: {
    id: string,
    label: string
  }[];

  constructor(
    private localDb: LocalDatabaseService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private matomoTracker: MatomoTracker
  ) {

    this.clinicalTrials = [];
    this.clinicalData = [];
    this.mediaItems = [];

    // used for navigation structure
    this.activatedRoute.parent.params.subscribe(params => {
      this.topic = this.localDb.getTopic(params.topicId);
      this.speciality = this.localDb.getSpeciality(params.specialityId);
    });

    this.activatedRoute.params.subscribe(params => {

      this.target = this.localDb.getTarget(params.targetId);
      this.modeOfAction = this.localDb.getModeOfAction(params.modeOfActionId);
      this.substance = this.localDb.getSubstance(params.substanceId);
      this.activatedRoute.queryParams.subscribe(queryParams => {
        this.phase = this.localDb.getPhase(queryParams.phaseId);
      })

      // prepare options array of objects for app-drop-up component

      // clinicalTrials
      const clinicalTrials =
        this.localDb.getStudyCategoriesForSubstance(this.substance.id, ['RECRUITING_STUDY', 'NONE_RECRUITING_STUDY'], this.speciality.id, null, this.phase?.id);


      clinicalTrials.map(item => {
        this.clinicalTrials.push({ id: item.id, label: item.name }); // turn into expected generic format
      });

      this.clinicalTrials.sort((c1, c2) => c1.label < c2.label ? -1 : 1);

      // clinicalData
      const clinicalData = this.localDb.getStudyCategoriesForSubstance(this.substance.id, ['CURRENT_DATA'], this.speciality.id, true, this.phase?.id);
      clinicalData.map(item => {
        this.clinicalData.push({ id: item.id, label: item.name }); // turn into expected generic format
      });

      this.clinicalData.sort((c1, c2) => c1.label < c2.label ? -1 : 1);

      // media
      const mediaItems = this.localDb.getVideosDetails(this.substance.videos);
      mediaItems.map(item => {
        this.mediaItems.push({ id: item.id, label: item.name });
      });

    });

  }

  ngOnInit() {
    this.matomoTracker.trackEvent('ViewSubstance', 'substance.name', this.substance.name);
  }

  didSelectClinicalTrials(categoryId: string) {
    if (this.modeOfAction && this.target && this.substance) {
      // tslint:disable-next-line: max-line-length
      this.router.navigate([`specialities/${this.speciality.id}/topics/${this.topic.id}/mode_of_actions/${this.modeOfAction.id}/targets/${this.target.id}/substance/${this.substance.id}/recruiting_studies/${categoryId}`], { queryParams: { substanceId: this.substance.id } });
    } else if (this.phase) {
      // tslint:disable-next-line: max-line-length
      this.router.navigate([`specialities/${this.speciality.id}/topics/${this.topic.id}/substance/${this.substance.id}/recruiting_studies/${categoryId}`], { queryParams: { substanceId: this.substance.id, phaseId: this.phase.id } });
    } else { //If the access is from search that have not phase.
      // tslint:disable-next-line: max-line-length
      this.router.navigate([`specialities/${this.speciality.id}/topics/${this.topic.id}/substance/${this.substance.id}/recruiting_studies/${categoryId}`], { queryParams: { substanceId: this.substance.id } });
    }
  }

  didSelectClinicalData(categoryId: string) {
    if (this.modeOfAction && this.target && this.substance) {
      // tslint:disable-next-line: max-line-length
      this.router.navigate([`specialities/${this.speciality.id}/topics/${this.topic.id}/mode_of_actions/${this.modeOfAction.id}/targets/${this.target.id}/substance/${this.substance.id}/current_data/${categoryId}`], { queryParams: { substanceId: this.substance.id } });
    } else if (this.phase) {
      {
        // tslint:disable-next-line: max-line-length
        this.router.navigate([`specialities/${this.speciality.id}/topics/${this.topic.id}/substance/${this.substance.id}/current_data/${categoryId}`],
          { queryParams: { substanceId: this.substance.id, phaseId: this.phase.id } });
      }
    } else { //If the access is from search that have not phase.
      // tslint:disable-next-line: max-line-length
      this.router.navigate([`specialities/${this.speciality.id}/topics/${this.topic.id}/substance/${this.substance.id}/current_data/${categoryId}`],
        { queryParams: { substanceId: this.substance.id } });
    }

  }

  didSelectMedia(videoId: string) {
    const fullUrl = [...this.activatedRoute.parent.snapshot.url, ...this.activatedRoute.snapshot.url];
    const basePath = fullUrl.map(u => u.path);
    const path = basePath.concat(['media', videoId]);
    if (this.modeOfAction && this.target && this.substance) { this.router.navigate(path, { queryParams: { substanceId: this.substance.id } }); }
    else { this.router.navigate(path, { queryParams: { substanceId: this.substance.id, phaseId: this.phase?.id || null } }); }

  }

  checkContentStudies(elementsToCheck, typeOfStudies) {
    try {
      let returnBoolean = false
      if (elementsToCheck?.length > 0) {
        this.substance.studyCategoryDependencies.forEach(itemCategory => {
          try {
            itemCategory.studies.forEach(itemStudy => {
              try {
                let studyToCheck = this.localDb.getStudyForId(itemStudy)
                if (studyToCheck.type == typeOfStudies) {
                  if (studyToCheck.phase && this.phase) {
                    if (studyToCheck.phase == this.phase.id) { returnBoolean = true }
                  } else { returnBoolean = true } //This is here until all studies have phase.
                }
              } catch (error) { console.error("Error: Incorrect information of some studies", error) }
            })
          } catch (error) { console.error("Error: Incorrect information of some categories") }
        })
      }
      return returnBoolean
    } catch (error) { console.error("Error: It was not possible charge information of studies"); return false }
  }

}
