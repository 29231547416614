import { Component, OnInit } from '@angular/core';
import { LocalDatabaseService } from 'src/app/modules/shared/services/local-database.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Target, Substance, Speciality, Topic, ModeOfAction } from 'src/app/modules/api-client/models';
import { MatomoTracker } from 'ngx-matomo';

@Component({
  selector: 'app-target',
  templateUrl: './target.component.html',
  styleUrls: ['./target.component.scss']
})
export class TargetComponent implements OnInit {

  target: Target;
  substances: Substance[];
  speciality: Speciality;
  topic: Topic;
  modeOfAction: ModeOfAction;
  pdfOfTargetName: string;
  listOfSubstance: {
    id: string,
    label: string
  }[];

  constructor(
    private localDb: LocalDatabaseService,
    public activatedRoute: ActivatedRoute,
    public router: Router,
    private matomoTracker: MatomoTracker
  ) { }

  ngOnInit() {

    this.activatedRoute.parent.params.subscribe(params => {
      this.topic = this.localDb.getTopic(params.topicId);
      this.speciality = this.localDb.getSpeciality(params.specialityId);
    });

    this.activatedRoute.params.subscribe(params => {
      this.target = this.localDb.getTarget(params.targetId);
      this.substances = this.localDb.getSubstancesDetails(this.target.substances);
      this.modeOfAction = this.localDb.getModeOfAction(params.modeOfActionId);
    });

    try { this.pdfOfTargetName = (this.localDb.getPdf(this.target.pdfId)).name; } catch (error) { } //Try-Catch to avoid error when have not pdf.

    this.matomoTracker.trackEvent('ViewTarget', 'target.name', this.target.name);

    this.listOfSubstance = []
    this.substances.forEach(item => { this.listOfSubstance.push({ id: item.id, label: item.name }) })
  }

  toSubstanceDetails(substance: Substance) {

    // tslint:disable-next-line: max-line-length
    const url = `specialities/${this.speciality.id}/topics/${this.topic.id}/mode_of_actions/${this.modeOfAction.id}/targets/${this.target.id}/substance/${substance.id}`;

    this.router.navigate([url]);
  }

  didSelect() {
    try {
      if (this.target.pdfId) {
        const parentBasePath = this.activatedRoute.parent.snapshot.url.map(u => u.path);
        const currentBasePath = this.activatedRoute.snapshot.url.map(u => u.path);
        const absoluteUrl = [...parentBasePath, ...currentBasePath];
        const path = absoluteUrl.concat(['media', this.target.pdfId]);

        // preserve queryParams to be able to go back to state if possible. In this case is not necessary but for possible future changes.
        this.activatedRoute.queryParams.subscribe(queryParams => {
          this.router.navigate(path, { queryParams });
        });
      }
      else {
        console.log("No content available")
      }
    } catch (error) { console.error("Error: No content available") }
  }

  didSelectSubstance(substanceIdToWork) {
    let substanceToWork = this.localDb.getSubstance(substanceIdToWork)
    this.toSubstanceDetails(substanceToWork)
  }
}
