<ng-container *ngIf="pageReady">
  <div nz-row>

    <div nz-col [nzSm]="{ span: 12, offset : 6 }">
      <div class="roche-title-section-wrapper roche-mt-50 roche-mb-50">
        <h1 class="roche-text-center roche-title-section">{{title}}</h1>
      </div>
    </div>

  </div>

  <div nz-row>

    <div nz-col [nzXl]="{ span: 6, offset : 6 }" [nzSm]="{ span : 8, offset : 4 }">

      <div [style.backgroundImage]="defaultGlassbodyImage | toAttachmentId | authImg:null:'style' | async" class="glass-body glass-body-placeholder"></div>
      <div [style.backgroundImage]="glassBody.categoryImage | authCachedImg:false:'style' | async" class="glass-body"></div>

    </div>

    <div nz-col [nzLg]="{ span: 7, offset : 0 }" [nzSm]="{ span : 6, offset : 3 }">

        <ul class="recruiting-studies-list">
          <li *ngFor="let category of categories" (mouseout)="didMouseOut($event)" (mouseover)="hover($event, category)" (click)="onSelectCategory(category)" [innerHtml]="category.name"></li>
        </ul>

    </div>

  </div>
</ng-container>
