import { Codable } from '../interfaces/serialization';
import { fromUnixTime, getUnixTime } from 'date-fns';

export class EventToken {
    eventId: string;
    exp: Date;
    name: string;
    startDate: Date;

    deserialize(object: any): EventToken {
        const event = new EventToken();
        event.eventId = object.event_id;
        event.exp = fromUnixTime(object.exp);
        event.name = object.name;
        event.startDate = fromUnixTime(object.start_date);
        return event;
    }
}

export class Event implements Codable<Event> {

    id: string;
    expireDays: number;
    name: string;
    start: Date;
    end: Date;
    defaultSpecialityId: string;
    lastForceSave: Date;
    referencesPdf: string;
    packagePin: string;
    contentVersion: string;
    showContactFormTrigger: boolean;
    screenSaver: { show: boolean, imageId: string };
    configuration?: EventConfiguration;
    showCovidBanner: boolean;

    deserialize(object: any, id?: any): Event {
        const event = new Event();
        event.id = object.id || id;
        event.expireDays = object.expire_days;
        event.name = object.name;
        event.start = fromUnixTime(object.start);
        event.end = fromUnixTime(object.end);
        event.defaultSpecialityId = object.default_speciality_id;
        event.lastForceSave = object.last_force_save;
        event.referencesPdf = object.references_pdf;
        event.packagePin = "";
        event.contentVersion = object.content_version;
        event.showContactFormTrigger = object.show_contact_form_trigger;
        event.screenSaver = {
            show: object.screen_saver.show,
            imageId: object.screen_saver.image_id
        };
        if (object.configuration) {
            event.configuration = (new EventConfiguration()).deserialize(object.configuration);
        }
        event.showCovidBanner = object.show_covid_banner;
        return event;
    }

    serialize(): any {
        return {
            id: this.id,
            expire_days: this.expireDays ? this.expireDays : 120,
            name: this.name,
            start: this.start ? getUnixTime(this.start) : getUnixTime(new Date()),
            end: this.end ? getUnixTime(this.end) : getUnixTime(new Date(new Date().getFullYear() + 2, new Date().getMonth(), new Date().getDate())),
            default_speciality_id: this.defaultSpecialityId ? this.defaultSpecialityId : null,
            last_force_save: this.lastForceSave ? this.lastForceSave : null,
            references_pdf: this.referencesPdf ? this.referencesPdf : null,
            package_pin: this.packagePin ? this.packagePin : "Password.",
            content_version: this.contentVersion ? this.contentVersion : "0",
            show_contact_form_trigger: this.showContactFormTrigger ? this.showContactFormTrigger : false,
            screen_saver: this.screenSaver ? { show: this.screenSaver.show, image_id: this.screenSaver.imageId ? this.screenSaver.imageId != "undefined" ? this.screenSaver.imageId : null  : null } : { show: false, image_id: null },
            configuration: this.configuration ? this.configuration.serialize() : null,
            show_covid_banner: this.showCovidBanner
        };
    }

}

export class EventConfiguration implements Codable<EventConfiguration> {

    locale: string;
    finePrints: { color: string, title: string, text: string, isPopover: boolean }[];
    contactRequestEmails: { studiesManagement: string[], general: string[] };
    specialities: {
        id: string,
        categories: string[],
        defaultTopicId: string,
        defaultCategoryId: string,
        topics: {
            id: string,
            modeOfActions?: {
                id: string,
                targets: string[]
            }[]
        }[],
        configurations: {
            substanceId: string,
            categories: {
                id: string,
                properties: {
                    visible: boolean,
                    showVideos: boolean,
                    showStudies: boolean,
                    showCurrentData: boolean,
                    showDescription: boolean
                }
            }[]
        }[]
    }[];

    deserialize(object: any, id?: any): EventConfiguration {
        const config = new EventConfiguration();
        config.locale = object.locale;
        config.finePrints = object.fine_prints.map(fp => {
            return {
                color: fp.color,
                title: fp.title,
                text: fp.text,
                isPopover: fp.is_popover
            };
        });
        config.contactRequestEmails = {
            studiesManagement: object.contact_request_emails.studies_management,
            general: object.contact_request_emails.general,
        };
        config.specialities = object.specialities.map(speciality => ({
            id: speciality.id,
            categories: speciality.categories,
            defaultTopicId: speciality.default_topic_id,
            defaultCategoryId: speciality.default_category_id,
            topics: speciality.topics.map(topic => ({
                id: topic.id,
                modeOfActions: topic.mode_of_actions ? topic.mode_of_actions.map(moa => ({
                    id: moa.id,
                    targets: moa.targets
                })) : undefined
            })),
            configurations: speciality.configurations.map(spConfig => ({
                substanceId: spConfig.substance_id,
                categories: spConfig.categories.map(category => ({
                    id: category.id,
                    properties: {
                        visible: category.properties.visible,
                        showVideos: category.properties.show_videos,
                        showStudies: category.properties.show_studies,
                        showCurrentData: category.properties.show_current_data,
                        showDescription: category.properties.show_description
                    }
                }))
            }))
        }));
        return config;
    }

    serialize(): any {
        return {
            locale: this.locale,
            fine_prints: this.finePrints ? this.finePrints.map(fp => {
                return {
                    color: fp.color,
                    title: fp.title,
                    text: fp.text,
                    is_popover: fp.isPopover
                };
            }) : null,
            contact_request_emails: this.contactRequestEmails ? {
                studies_management: this.contactRequestEmails.studiesManagement,
                general: this.contactRequestEmails.general
            } : null,
            specialities: this.specialities.map(speciality => ({
                id: speciality.id,
                categories: speciality.categories,
                default_topic_id: speciality.defaultTopicId,
                default_category_id: speciality.defaultCategoryId,
                topics: speciality.topics.map(topic => ({
                    id: topic.id,
                    mode_of_actions: topic.modeOfActions ? topic.modeOfActions.map(moa => ({
                        id: moa.id,
                        targets: moa.targets
                    })) : undefined
                })),
                configurations: speciality.configurations.map(spConfig => ({
                    substance_id: spConfig.substanceId,
                    categories: spConfig.categories.map(category => ({
                        id: category.id,
                        properties: {
                            visible: category.properties.visible,
                            show_videos: category.properties.showVideos,
                            show_studies: category.properties.showStudies,
                            show_current_data: category.properties.showCurrentData,
                            show_description: category.properties.showDescription
                        }
                    }))
                }))
            }))
        };
    }

}

