<div class="hidden-xs">

  <app-covid-banner *ngIf="hasCovidBanner && canShowCovidBanner"></app-covid-banner>

  <nz-layout>
    <nz-header>
          <app-header [title]="title"
          [isDataPackageVisible]="isDataPackageVisible"
          [isLogoutVisible]="isLogoutVisible"
          [isHomeVisible]="isHomeVisible"
          [isReferencesVisible]="isReferencesVisible"
          [isSearchVisible]="isSearchVisible"
          [isStatsVisible]="isStatsVisible"
          (didPressLogo)="didPressLogo()"
          (didPressPackageButton)="didPressPackageButton()"
          (didPressLogoutButton)="didPressLogoutButton()"
          (didPressHomeButton)="didPressHomeButton()"
          (didPressReferencesButton)="didPressReferencesButton()"
          (didPressSearchButton)="didPressSearchButton()"
          (didPressStatsButton)="didPressStatsButton()"
          ></app-header>
          <!-- <app-header [title]="title"
          [isDataPackageVisible]="isDataPackageVisible"
          [isLogoutVisible]="isLogoutVisible"
          [isHomeVisible]="isHomeVisible"
          [isReferencesVisible]="isReferencesVisible"
          [isSearchVisible]="isSearchVisible"
          [isEditionVisible]="isEditionVisible"
          [isStatsVisible]="isStatsVisible"
          (didPressLogo)="didPressLogo()"
          (didPressPackageButton)="didPressPackageButton()"
          (didPressLogoutButton)="didPressLogoutButton()"
          (didPressHomeButton)="didPressHomeButton()"
          (didPressReferencesButton)="didPressReferencesButton()"
          (didPressSearchButton)="didPressSearchButton()"
          (didPressEditionButton)="didPressEditionButton()"
          (didPressEditionNewButton)="didPressEditionNewButton()"
          (didPressStatsButton)="didPressStatsButton()"
          ></app-header> -->
    </nz-header>
    <div class="router-wrapper">
      <div class="content-wrapper">
        <nz-content>
          <router-outlet></router-outlet>
        </nz-content>
      </div>
        
      <div class="footer-wrapper">
        <nz-footer>
          <app-footer [style]="footerStyle" [version]="footerVersion"></app-footer>
        </nz-footer>
      </div>
    </div>
  </nz-layout>
</div>


<div class="hidden-lg">
  <div class="mobile-warning-window">
    <span><i class="icon icon-warning"></i></span>
    <span class="title">Application optimized for larger screens</span>
    <span class="message">Please make sure to use a larger screen <small>(> 1024px)</small> for the best viewing experience.</span>
  </div>
</div>
