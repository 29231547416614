<div nz-row *ngIf="errorReason | async as errorReason;" class="content">
    <div nz-col nzSpan="20" nzOffset="2">
        <p>{{ 'SsoError.PreTitle' | translate }}</p>

        <h1 [ngSwitch]="true">
            <span *ngSwitchCase="errorReason === 'login_failed' || errorReason === 'assert_failed'">{{ 'SsoError.TitleLoginFailed' | translate }}</span>
            <span *ngSwitchCase="errorReason === 'logout_failed'">{{ 'SsoError.TitleLogoutFailed' | translate }}</span>
            <span *ngSwitchDefault>{{ 'Shared.UnknownError' | translate }}</span>
        </h1>
        <h2 [ngSwitch]="true">
            <span *ngSwitchCase="errorReason === 'login_failed' || errorReason === 'assert_failed'">{{ 'SsoError.SubtitleLoginFailed' | translate }}</span>
            <span *ngSwitchCase="errorReason === 'logout_failed'">{{ 'SsoError.SubtitleLogoutFailed' | translate }}</span>
            <span *ngSwitchDefault>{{ 'Shared.UnknownErrorOccured' | translate }}</span>
        </h2>
        <a routerLink="/">{{ 'PageNotFound.BackStartPage' | translate }}</a>
    </div>
</div>