import { Codable } from '../interfaces/serialization';

export class Image implements Codable<Image> {

    id: string;
    name: string;
    description: string;
    position: number;
    attachmentId: string;
    fileInformation: object;

    deserialize(object: any, id?: any): Image {
        const image = new Image();
        image.id = object.id || id;
        image.name = object.name;
        image.description = object.description;
        image.position = object.position;
        image.attachmentId = object.attachment_id;
        return image;
    }

    serialize(): any {
        return {
            id: this.id,
            name: this.name,
            description: this.description,
            position: this.position,
            attachment_id: this.attachmentId
        };
    }

}

