import { Deserializable } from '../interfaces/serialization';

export enum UserRole {
    ADMINISTRATOR = 'Administrator',
    EDITOR = 'Editor',
    MSL = 'MSL',
    APP = 'App'
}

export class User implements Deserializable<User> {
    id: string;
    role: UserRole;

    deserialize(object: any, id?: string): User {
        const user = new User();
        user.id = object.id || id;
        user.role = object.role;
        return user;
    }

}
