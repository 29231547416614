import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiClientService } from '../../modules/api-client/services/api-client.service';

@Component({
    selector: 'app-stats',
    templateUrl: './stats.component.html',
    styleUrls: ['./stats.component.scss']
})
export class StatsComponent implements OnInit {

    constructor(
        private apiClient: ApiClientService,
        private router: Router,
    ) { }

    checkEditorProfile: boolean;

    ngOnInit() {
        try {
            this.checkEditorProfile = false;
            this.apiClient.checkStatsAuthorization()
                .then(() => {
                    // this.apiClient.checkShowStatAllInformation()
                    //     .then((response) => {
                            try {
                                // if (response === true) { this.checkEditorProfile = true }
                                // else {
                                    this.checkEditorProfile = false
                                    this.downloadStats()
                                // }
                            } catch (error) { throw error }
                        // }).catch((error) => { if (error) { throw error } });
                }).catch((error) => { throw error });
        } catch (error) { console.error(error) }
    }

    downloadStats() {
        try {
            this.apiClient.getStatsFileInformation().then((info) => {
                try {
                    let fileToDownload = document.getElementById("fileToDownload");
                    fileToDownload.setAttribute("href", `data: text / csv; base64, ${info}`)
                    fileToDownload.setAttribute("download", "statsList.csv")
                    document.getElementById('fileToDownload').click();
                    fileToDownload.removeAttribute("href")
                    fileToDownload.removeAttribute("download")
                    // if (!this.checkEditorProfile) { this.returnMainMenu() }
                } catch (error) { throw error }
            }).catch((error) => { throw error })
        } catch (error) { console.error(error) }
    }

    // generateStructure() {
    //     try {
    //         this.apiClient.getStructureFileInformation().then((response) => { }).catch((error) => { throw error })
    //     } catch (error) { console.error(error) }
    // }

    // returnMainMenu() {
    //     try {
    //         this.router.navigate(['/events']);
    //     } catch (error) { console.error(error) }
    // }
}