import { Codable } from '../interfaces/serialization';

export enum StudyType {
    RECRUITING_STUDY = 'RECRUITING_STUDY',
    NONE_RECRUITING_STUDY = 'NONE_RECRUITING_STUDY',
    CURRENT_DATA = 'CURRENT_DATA'
}

export class Study implements Codable<Study> {

    id: string;
    name: string;
    shortName: string;
    description: string;
    type: StudyType;
    pdfId: string;
    testingSubstances: string[];
    recruitingCountries: string[];
    studiesCategories: { id: string, priority: number, studiesPhases: string[] }[];
    rolloverStudy?: string;
    hasContent: boolean;
    visible; boolean;
    phase: string;

    deserialize(object: any, id?: any): Study {
        let checkType = false;
        if (typeof (object.type) == "string" &&
            Object.values(StudyType).includes(object.type) &&
            this.type != "NONE_RECRUITING_STUDY") {
            checkType = true;
        }
        const study = new Study();
        study.id = object.id || id;
        study.name = object.name;
        study.shortName = object.short_name ? object.short_name : "";
        study.description = object.description || "";
        study.type = checkType ? object.type : null;
        study.pdfId = object.pdf_id;
        study.testingSubstances = object.testing_substances;
        study.recruitingCountries = object.recruiting_countries;
        study.studiesCategories = (object.studies_categories || [])
            .map(sc => ({
                id: sc.id,
                priority: sc.priority,
                studiesPhases: sc.studies_phases
            }));
        study.rolloverStudy = object.rollover_study;
        study.visible = object.visible || false;
        study.hasContent = (object.hasContent || object.pdf_id) && checkType ? true : false; //This should be change when all studies will be reviewed.
        study.phase = object.phase || null;
        return study;
    }

    serialize(): any {
        return {
            id: this.id,
            name: this.name,
            short_name: typeof (this.shortName) == "string" ? this.shortName : "",
            description: typeof (this.description) == "string" ? this.description : "",
            type: typeof (this.type) == "string" ? Object.values(StudyType).includes(this.type) ? this.type : "NONE_RECRUITING_STUDY" : "NONE_RECRUITING_STUDY",
            pdf_id: this.pdfId ? this.pdfId.length ? this.pdfId : null : null,
            testing_substances: Array.isArray(this.testingSubstances) ? this.testingSubstances : [],
            recruiting_countries: Array.isArray(this.recruitingCountries) ? this.recruitingCountries : [],
            studies_categories: (this.studiesCategories || [])
                .map(sc => ({
                    id: sc.id,
                    priority: sc.priority,
                    studies_phases: sc.studiesPhases
                })),
            rollover_study: typeof (this.rolloverStudy) == "string" ? this.rolloverStudy : null,
            visible: typeof (this.visible) == "boolean" ? this.visible || false : false,
            hasContent: typeof (this.hasContent) == "boolean" ? this.hasContent || false : false,
            phase: typeof (this.phase) == "string" ? this.phase : null
        };
    }

}

