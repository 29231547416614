<div nz-row>

    <div nz-col nzSpan="12" nzOffset="6">
        <div class="roche-title-section-wrapper roche-mt-50 roche-mb-50">
        <h1 class="roche-text-center roche-title-section">{{topic?.name}}</h1>
        </div>
    </div>

</div>

<app-grid [customTopLeftTemplate]="contentAvailability" [structure]="gridStructure" [itemTemplate]="gridItem" (didSelectGridItem)="didSelectSubstance($event)"></app-grid>
<ng-template #gridItem let-title="title" let-subtitle="subtitle" let-hasContent="hasContent">
    <app-grid-item-pipeline-overview [title]="title" [subtitle]="subtitle" [filterContentActive]="filterContentActive" [hasContent]="hasContent"></app-grid-item-pipeline-overview>
</ng-template>

<ng-template #contentAvailability>
  <div class="content-availability">
    <ul>
      <li><span class="label"><i class="icon icon-content-available"></i>{{ 'PipelineScreen.ContentAvailable' | translate }}</span></li>
      <li class="roche-position-relative">
        <span class="label"><i class="icon icon-content-unavailable"></i>{{ 'PipelineScreen.ContentUnavailable' | translate }}</span>
        <a (click)="didChangeContentAvailabilitySwitch()" class="content-availability-switch" [routerLink]=""><i [ngClass]="{'icon-content-is-visible' : filterContentActive === true, 'icon-content-is-hidden' : filterContentActive === false}" class="icon "></i></a>
      </li>
    </ul>
  </div>
</ng-template>
