import { Injectable } from '@angular/core';
import { EventToken } from '../../api-client/models';

@Injectable({
  providedIn: 'root'
})
export class TempEventService {

  private storageKey = 'TEMP_EVENT';
  tempEventToken: EventToken;

  constructor() { }

  public isTempEvent(): boolean {
    return !!window.sessionStorage.getItem(this.storageKey);
    // return !!this.tempEventToken;
  }

  public setTempEventToken(token: EventToken): void {
    this.tempEventToken = token;
    window.sessionStorage.setItem(this.storageKey, JSON.stringify(token));
  }

  public clearTempEventToken(): void {
    window.sessionStorage.removeItem(this.storageKey);
  }
}
