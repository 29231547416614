<div nz-row class="roche-mb-50 roche-mt-50">
    <div nz-col [nzLg]="{ span: 5, offset : 2 }" [nzMd]="{ span: 5, offset : 2 }">
        <ng-container *ngIf="substances.length > 1 && allowSubstanceFilter">
            <div><span class="roche-font-base roche-font-bold roche-font-uppercase">{{'Shared.Filter' |
                    translate}}</span></div>
            <nz-select nzAllowClear nzPlaceHolder="{{'Shared.All' | translate}}" [(ngModel)]="filterSubstanceId"
                (ngModelChange)="didChangeSubstanceFilter($event)">
                <nz-option *ngFor="let substance of substances" [nzLabel]="substance.name" [nzValue]="substance.id">
                </nz-option>
            </nz-select>
        </ng-container>
    </div>

    <div nz-col [nzLg]="{ span: 10, offset : 0 }" [nzMd]="{ span: 10, offset : 0 }" class="roche-text-center">

        <a [nzDisabled]="(categoryOptions.length > 0 ? false : true)" nz-dropdown nzTrigger="click"
            [nzDropdownMenu]="menu" [nzPlacement]="'bottomCenter'" class="
        roche-dropdown-trigger
        roche-color-white
        roche-font-base
        roche-font-bold
        roche-font-uppercase
        roche-big-dropdown-trigger" [class.no-border]="(categoryOptions.length > 0 ? false : true)"
            [innerHtml]="currentCategory?.name">
            <i *ngIf="(categoryOptions.length > 0)" class="icon icon-chevron-down"></i>
            <!-- i *ngIf="(categoryOptions.length > 0)" nz-icon nzType="down"></i-->
        </a>
        <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu class="roche-dropdown-big-button-list">
                <li nz-menu-item *ngFor="let category of categoryOptions" (click)="didSelectCategory(category)"
                    [innerHtml]="category.name"></li>
            </ul>
        </nz-dropdown-menu>

    </div>

    <div nz-col [nzLg]="{ span: 5, offset : 0 }" [nzMd]="{ span: 6, offset : 0 }" class="roche-text-right">
        <ng-container *ngIf="showSwitchButton">
            <a class="roche-crosslink" (click)="didPressSwitchToRelatedScreen()" *ngIf="allowScreenSwitch">
                <i class="icon icon-crosslink"></i>
                <ng-container [ngSwitch]="showingCurrentData">
                    <span *ngSwitchCase="true">{{ 'StudiesScreen.SwitchToStudies' | translate }}</span>
                    <span *ngSwitchCase="false">{{ 'StudiesScreen.SwitchToCurrentData' | translate }}</span>
                </ng-container>
            </a>
        </ng-container>
    </div>

</div>


<ng-container *ngIf="gridStructure">
    <app-grid [structure]="gridStructure" [itemTemplate]="gridItem" [noContentMessage]="noContentMessage"
        (didSelectGridItem)="didSelectGridItem($event)" [customTopLeftTemplate]="contentAvailability"></app-grid>
    <!-- <app-grid [structure]="gridStructure" [itemTemplate]="gridItem" [noContentMessage]="noContentMessage" [customTopLeftTemplate]="filterSwitch" (didSelectGridItem)="didSelectGridItem($event)"></app-grid> -->
    <ng-template #gridItem let-name="name" let-hasContent="hasContent" let-isRecruiting="isRecruiting"
        let-rolloverStudy="rolloverStudy" let-rolloverStudies=rolloverStudies>
        <app-grid-item-study [title]="name" [hasContent]="hasContent" [isRecruiting]="isRecruiting"
            [rolloverStudies]="rolloverStudies" [targetRolloverStudy]="rolloverStudy"></app-grid-item-study>
    </ng-template>

    <ng-template #contentAvailability>
        <div class="content-availability">
            <ul>
                <li><span class="label"><i class="icon icon-content-available"></i>{{ 'PipelineScreen.ContentAvailable'
                        |
                        translate }}</span></li>
                <li class="roche-position-relative">
                    <span class="label"><i class="icon icon-content-unavailable"></i>{{
                        'PipelineScreen.ContentUnavailable'
                        | translate }}</span>
                </li>
            </ul>
        </div>
    </ng-template>
    
    <!-- <ng-template #filterSwitch>
        <div class="filter-switch" *ngIf="!showingCurrentData">
          <nz-switch [(ngModel)]="switchValue" (ngModelChange)="didChangeStudiesSwitch($event)"></nz-switch>
          <span class="roche-font-base roche-font-bold roche-font-uppercase">{{ recrutingStudySwitchLabel }}</span>
          <i class="icon icon-recruiting"></i>
        </div>
      </ng-template> -->
</ng-container>