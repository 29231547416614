import { Component, OnInit, Input, TemplateRef, Output, EventEmitter, AfterViewInit, ElementRef } from '@angular/core';
import { StudiesGridUtil } from '../../utils/studies-grid.util';

export class GridStructure {
  x: GridStructureX[];
  y: GridStructureY[];
}

export class GridStructureX {
  id: string;
  name: string;
}

export class GridStructureY {
  id: string;
  name: string;
  items: {
    id: string,
    metadata: any,
    span: number,
    offset?: number,
    endSpan?: number
  }[];
  groups?: {
    items: {
      id: string,
      metadata: any,
      span: number,
      offset?: number,
      endSpan?: number
    }[];
  }[];
}

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss']
})
export class GridComponent implements OnInit, AfterViewInit {

  @Input()
  structure: GridStructure;

  @Input()
  itemTemplate: TemplateRef<any>;

  @Input()
  customTopLeftTemplate: TemplateRef<any>;

  @Input()
  noContentMessage: string;

  @Output()
  didSelectGridItem = new EventEmitter<{ yId: string, itemId: string, itemPhaseId: string }>();

  pillWidth: number;
  isHeaderSticky: boolean;

  constructor(
    private gridUtils: StudiesGridUtil,
    private elementRef: ElementRef
  ) { }

  ngOnInit() {

    // after init the component may suffer changes in data, so we need to watch for changes on gridStructure
    this.gridUtils.gridStructure().subscribe((gridStructure) => {
      if (gridStructure && gridStructure.x) {
        this.pillWidth = (100 / gridStructure.x.length); // calculate the single unit pill everytime gridStructure updates
      }
    });
    this.pillWidth = (100 / this.structure.x.length); // calculate the single unit pill width for spacing
  }

  ngAfterViewInit(): void {
    window.addEventListener('scroll', this.windowDidScroll.bind(this));
    window.addEventListener('resize', this.windowDidResize.bind(this));
  }

  private windowDidScroll(): void {
    this.updateAffix();
  }

  private windowDidResize(): void {
    this.updateAffix();
  }

  private updateAffix(): void {
    if (window.scrollY >= 350) {
      this.isHeaderSticky = true;
    } else {
      this.isHeaderSticky = false;
    }
  }

  didPressItem($event, yId: string, itemId: string, itemPhaseId: string) {
    $event.preventDefault();
    this.didSelectGridItem.emit({ yId, itemId, itemPhaseId });
  }

  getStructureY() { //Check if the groups are created. Avoid empty groups. New structure is created avoiding groups from pipeline overview.
    let checkStructureY = []
    this.structure.y.forEach(itemStructureY => {
      if (itemStructureY.groups ? itemStructureY.groups.length > 0 : false) { checkStructureY.push(itemStructureY) }
      else if (!itemStructureY.groups ? itemStructureY : false) { checkStructureY.push(itemStructureY) }
    })
    return checkStructureY
  }
}
