import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import jwt_decode from 'jwt-decode';
import { combineLatest } from 'rxjs';
import { EventToken } from 'src/app/modules/api-client/models';
import { ApiClientService } from 'src/app/modules/api-client/services/api-client.service';
import { AuthService } from 'src/app/modules/api-client/services/auth.service';
import { LocalDatabaseService } from '../../services/local-database.service';
import { TempEventService } from '../../services/temp-event.service';

@Component({
  selector: 'app-temp-event',
  templateUrl: './temp-event.component.html',
  styleUrls: ['./temp-event.component.scss']
})
export class TempEventComponent implements OnInit {

  isEventExpired: boolean;
  isInvalidToken: boolean;
  isEventTokenMissingInfo: boolean;
  isLoading = true;
  tempEventToken: EventToken;
  jwtToken: string;

  get eventHasErrors(): boolean {
    return !!this.isInvalidToken || !!this.isEventExpired || !!this.isEventTokenMissingInfo;
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private apiClient: ApiClientService,
    private localDb: LocalDatabaseService,
    private router: Router,
    private tempEventService: TempEventService
  ) { }

  ngOnInit(): void {

    combineLatest(
      this.activatedRoute.params,
      this.activatedRoute.queryParams
    ). subscribe(data => {
      const params = data[0];
      const queryParams = data[1];

      // check if its a valid token
      if (params?.token) {

        this.jwtToken = params.token;

        try {
          const event = jwt_decode(this.jwtToken);
          if (event) {
            this.tempEventToken = new EventToken().deserialize(event);
          }
          // deconstruct the token and inspect dates
          if (this.tempEventToken.eventId && this.tempEventToken.exp) {

            if (this.tempEventToken.exp >= new Date()) {

              // if all goes well, first set the JWT token onto our service
              this.authService.setJwtToken(this.jwtToken);
              this.tempEventService.setTempEventToken(this.tempEventToken);

              // then fetch the event from the api and set full data to result
              this.apiClient.getEventFullDataFromToken(this.tempEventToken.eventId).subscribe(fullData => {
                this.localDb.setFullData(fullData);

                if (queryParams?.redirect) {
                  this.router.navigate([queryParams.redirect]);
                } else {
                  this.router.navigate([`specialities`]);
                }
                this.isLoading = false;
              });

            } else {
              this.isEventExpired = true;
              this.tempEventService.clearTempEventToken();
              this.isLoading = false;
            }
          } else {
            this.isEventTokenMissingInfo = true;
            this.isLoading = false;
          }
        } catch (err) {
          this.isInvalidToken = true;
          this.isLoading = false;
        }
      }

    });

  }
}
