<ng-container *ngIf="canShowBanner()">
    <div class="roche-covid-banner-wrapper">
        <div [ngClass]="{'roche-covid-banner' : true, 'is-collapsed' : isCollapsed}">
            <div class="covid-banner-info">
                <div class="text">{{ 'CovidBanner.LatestInfo' | translate}}</div>
                <div class="buttons">
                    <button *ngIf="isCovidCurrentDataAvailable()" nz-button nzType="primary" (click)="didPressData()">{{ 'CovidBanner.DataSituation' | translate}}</button>
                    <button *ngIf="isCovidStudiesAvailable()" nz-button nzType="primary" (click)="didPressStudies()">{{ 'CovidBanner.Studies' | translate}}</button>
                </div>
            </div>
            <div class="covid-banner-handle-container">
                <div class="covid-banner-handle" (click)="didPressToggle()">
                    <span class="icon"><i class="icon icon-corona"></i></span>
                    <span class="text">COVID-19</span>
                    <span class="caret"><i class="icon icon-chevron-right"></i></span>
                </div>
                
            </div>
        </div>
    </div>
</ng-container>