import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TopicType } from 'src/app/modules/api-client/models';
import { LocalDatabaseService } from '../../services/local-database.service';

@Component({
  selector: 'app-covid-banner',
  templateUrl: './covid-banner.component.html',
  styleUrls: ['./covid-banner.component.scss']
})
export class CovidBannerComponent implements OnInit {

  isCollapsed = true;
  infectologySpecialityId = '18d3c51d-b688-482e-b90d-7e5383c02053';
  covidCategoryId = 'f1e00ead-6cea-4875-bbfb-069d399aa5a6';

  constructor(
    public router: Router,
    private localDb: LocalDatabaseService
  ) { }

  ngOnInit(): void {
  }

  didPressToggle(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  didPressStudies(): void {
    if (this.isCovidStudiesAvailable()) {
      const activeStudiesTopicId = this.localDb.getTopicForTopicTypeInSpeciality(TopicType.GLASS_BODY_STUDIES,
        this.infectologySpecialityId)?.id;
      this.router.navigate(
        [`specialities`, this.infectologySpecialityId, `topics`, activeStudiesTopicId, `recruiting_studies`, this.covidCategoryId]
      );
    }
  }

  didPressData(): void {
    if (this.isCovidCurrentDataAvailable()){
      const currentDataTopicId = this.localDb.getTopicForTopicTypeInSpeciality(TopicType.GLASS_BODY_CURRENT_DATA,
        this.infectologySpecialityId)?.id;
      this.router.navigate(
        [`specialities`, this.infectologySpecialityId, `topics`, currentDataTopicId, `current_data`, this.covidCategoryId]
      );
    }
  }

  isCovidCurrentDataAvailable(): boolean {
    return this.localDb.getTopicForTopicTypeInSpeciality(TopicType.GLASS_BODY_CURRENT_DATA,
      this.infectologySpecialityId)?.id ? true : false;
  }

  isCovidStudiesAvailable(): boolean {
    return this.localDb.getTopicForTopicTypeInSpeciality(TopicType.GLASS_BODY_STUDIES, this.infectologySpecialityId)?.id ? true : false;
  }

  // the banner can only be displayed if both the package holds the INFECTOLOGY speciality as well as a study for category Covid-19
  canShowBanner(): boolean {
    if (
      this.packageHasSpeciality(this.infectologySpecialityId) &&
      this.packageHasCategory(this.covidCategoryId) &&
      (this.isCovidCurrentDataAvailable() || this.isCovidStudiesAvailable())
    ) {
      return true;
    }
    return false;
  }

  packageHasSpeciality(specialityId: string): boolean {
    return this.localDb.getSpecialitiesForEvent()?.find(s => s.id === specialityId) ? true : false;
  }

  packageHasCategory(categoryId: string): boolean {
    return this.localDb.getSpecialitiesForEvent()?.
      find(s => s.id === this.infectologySpecialityId)?.categories.includes(categoryId) ? true : false;
  }

}
