<ng-container *ngIf="pageReady">

<div nz-row>
    <div nz-col nzSpan="12" nzOffset="6">
      <div class="roche-title-section-wrapper roche-mt-50 roche-mb-50">
        <h1 class="roche-text-center roche-title-section">{{topic.name}}</h1>
      </div>
    </div>
</div>

<div class="">
  <div nz-row class="roche-pb-50">
    <div nz-col [nzXl]="{ span: 16, offset : 4 }" [nzSm]="{ span: 20, offset : 2 }" >
      <div nz-row nzGutter="24">
        <div class="moa-items">


            <div *ngFor="let modeOfAction of modesOfAction">
                <a (click)="toModeOfActionTargets(modeOfAction)">
                  <div class="moa-item">
                    <span class="moa-image"><img class="roche-round-corners" [src]="modeOfAction.imageId | toAttachmentId | authImg | async" /></span>
                    <span class="moa-title roche-pill">{{modeOfAction.name}}</span>
                  </div>
                </a>
            </div>


        </div>
      </div>
    </div>
  </div>
</div>

</ng-container>
