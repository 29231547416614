<div nz-row class="roche-mt-50 roche-mb-50">

    <div nz-col [nzXl]="{ span: 20, offset : 2 }" [nzSm]="{ span: 22, offset : 1 }">
        <div class="section-table">

            <div [ngClass]="{'fixed-header-row-sm' : isHeaderSticky}">
                <div class="custom-container">
                    <div nz-row>
                        <div nz-col [nzXl]="{ span: isHeaderSticky ? 20 : 24, offset : isHeaderSticky ? 2 : 0 }"
                            [nzSm]="{ span: isHeaderSticky ? 22 : 24, offset : isHeaderSticky ? 1 : 0 }">
                            <div nz-row class="header-row">

                                <div nz-col nzSpan="6" nzOffset="" class="grid-custom-top-left-template">
                                    <ng-container *ngIf="customTopLeftTemplate">
                                        <ng-container *ngTemplateOutlet="customTopLeftTemplate"></ng-container>
                                    </ng-container>
                                </div>

                                <div nz-col nzSpan="18" nzOffset="">
                                    <div class="section-header">
                                        <div *ngFor="let xAxis of structure.x" [ngStyle]="{'width.%': pillWidth}"
                                            class="section-pill">
                                            <span [innerHtml]="xAxis.name"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <ng-container *ngIf="structure.y.length > 0 else noContent">
                <!-- iteration here -->
                <div *ngFor="let yAxis of getStructureY()" nz-row class="section-row" nzJustify="space-between"
                    nzAlign="center">
                    <div nz-col nzSpan="6">
                        <div class="section-header y-axis">
                            <span [innerHtml]="yAxis.name"></span>
                        </div>
                    </div>

                    <div nz-col nzSpan="18">
                        <div class="section-content">
                            <div class="section-column">

                                <ng-container *ngIf="yAxis.groups; else itemListing">
                                    <ng-container *ngFor="let group of yAxis.groups;">
                                        <div
                                            [ngClass]="{'section-group': true, 'is-rollover-group' : group.isRolloverGroup}">
                                            <!-- start listing pills for group from yAxis.groups -->
                                            <div *ngFor="let pill of group.items" [ngStyle]="{
                                                'width.%': (pill.span > 0 ? (pillWidth * pill.span) : pillWidth),
                                                'margin-left.%' : (pill.offset > 0 ? (pillWidth * pill.offset) : 0),
                                                'margin-right.%' : (pill.endSpan > 0 ? (pillWidth * pill.endSpan) : 0)
                                            }" [ngClass]="{'section-pill': true, 'is-rollover-study-pill' : pill.metadata.hasRolloverStudies}"
                                                (click)="didPressItem($event, yAxis.id, pill.id,pill.metadata.phase)">

                                                <ng-container *ngTemplateOutlet="itemTemplate; context:pill.metadata">
                                                </ng-container>

                                            </div>
                                            <!-- /start listing pills for group from yAxis.groups -->
                                        </div>
                                    </ng-container>
                                </ng-container>

                                <ng-template #itemListing>

                                    <!-- start listing pills for non-grouped items from yAxis.items -->
                                    <div *ngFor="let pill of yAxis.items" [ngStyle]="{
                                        'width.%': (pill.span > 0 ? (pillWidth * pill.span) : pillWidth),
                                        'margin-left.%' : (pill.offset > 0 ? (pillWidth * pill.offset) : 0),
                                        'margin-right.%' : (pill.endSpan > 0 ? (pillWidth * pill.endSpan) : 0)
                                    }" class="section-pill"
                                        (click)="didPressItem($event, yAxis.id, pill.id, pill.metadata.phase)">

                                        <ng-container *ngTemplateOutlet="itemTemplate; context:pill.metadata">
                                        </ng-container>

                                    </div>
                                    <!-- /start listing pills for non-grouped items yAxis.items -->

                                </ng-template>

                            </div>
                        </div>
                    </div>
                </div>
                <!-- /iteration here -->
            </ng-container>
            <ng-template #noContent>
                <div nz-row>
                    <div nz-col nzSpan="18" nzOffset="6" class="no-content">
                        <span [innerHtml]="noContentMessage"></span>
                    </div>
                </div>
            </ng-template>


        </div>
    </div>

</div>