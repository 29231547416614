import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { ApiClientService } from 'src/app/modules/api-client/services/api-client.service';
import { Observable } from 'rxjs';
import { Event } from 'src/app/modules/api-client/models';
import { pluck } from 'rxjs/operators';
import { LocalDatabaseService } from 'src/app/modules/shared/services/local-database.service';
import { NzModalService, NzModalRef } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { MatomoTracker } from 'ngx-matomo';
import { DisclaimerService } from 'src/app/modules/shared/services/disclaimer.service';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {

  results: Observable<Event[]>;
  currentEventId: string;
  tplModal: NzModalRef;
  tplModalButtonLoading = false;
  pin: string;
  eventName: string;
  event: Event;
  errorPin: boolean;
  errorFetchingDatapackage: boolean;

  constructor(
    public router: Router,
    private apiClient: ApiClientService,
    private localDb: LocalDatabaseService,
    public modalService: NzModalService,
    public messageService: NzMessageService,
    private matomoTracker: MatomoTracker,
    public disclaimerService: DisclaimerService
  ) { }

  ngOnInit() {
    this.currentEventId = this.localDb.getCurrentEventId();
    this.results = this.apiClient.getEvents({locale : 'en'}).pipe(pluck('items'));
  }

  pinPrompt(tplContent: TemplateRef<{}>, tplFooter: TemplateRef<{}>, event: Event): void {

    this.eventName = event.name;
    this.event = event;

    this.tplModal = this.modalService.create({
      nzContent: tplContent,
      nzFooter: tplFooter,
      nzMaskClosable: false,
      nzClosable: false,
      nzIconType: 'exclamation-circle',
      nzOkType: 'primary',
      nzOnOk: () => {

        this.tplModalButtonLoading = true;
        setTimeout(function() {
          this.tplModalButtonLoading = false;
        }, 3000);

        return false;
      }
    });
  }

  authenticatePin() {

    this.tplModalButtonLoading = true;
    this.apiClient.getEventFullData(this.event.id, this.pin)
    .subscribe(data => {

      this.localDb.setFullData(data);
      // save successfull event download into matomo
      this.matomoTracker.trackEvent('SelectedEvent', 'event.selected', this.localDb.getSelectedEventName(), null);
      this.tplModalButtonLoading = false;
      this.tplModal.destroy();
      // always display the disclaimer if available when accessing a datapackage
      const popOverDisclaimer = this.localDb.getDisclaimer();
      if (popOverDisclaimer) {
        this.disclaimerService.setDisclaimer(popOverDisclaimer);
        this.disclaimerService.display(true);
      }
      this.router.navigate([`/specialities`]);

    }, (error) => {

      // since we handle multiple non-cumulative errors, clean them before setting them
      this.errorPin = false;
      this.errorFetchingDatapackage = false;

      if (error.status === 403) {
        this.errorPin = true;
      }

      if (error.status === 500) {
        this.errorFetchingDatapackage = true;
      }

      this.tplModalButtonLoading = false;
    });

  }

  hasErrors(): boolean {
    return !!this.errorPin  || !!this.errorFetchingDatapackage;
  }

  closeModal(): void {
    this.tplModal.destroy();
  }

}
