<div [class.background-white]="style !== 'transparent'">



  <div class="custom-container">
    <div nz-row class="footer" [class.transparent]="style === 'transparent'">
        <div nz-col  [nzSm]="{ span: 16, offset: 2 }">

          <p class="footer-note" *ngIf="isEventLang('fr')">Ce site est à usage interne uniquement. Les documents contenus sur ce site, relatifs à des produits en développement et à des informations hors AMM, sont strictement confidentiels.</p>

          <ul>
            <li><span>&copy; Roche</span></li>
            <ng-container *ngIf="footerLinks">

              <ng-container *ngFor="let footerLink of footerLinks">
                <li><a (click)="openModal(footerLink.title, tplContent)" href="javascript:void(0);">{{footerLink.title}}</a></li>
                <ng-template #tplContent>
                  <div class="ps" [perfectScrollbar]="{minScrollbarLength:60}">
                    <perfect-scrollbar></perfect-scrollbar>
                    <div [innerHTML]="footerLink.text"></div>
                  </div>
                </ng-template>
              </ng-container>

            </ng-container>
            </ul>
        </div>
        <div nz-col [nzMd]="{ offset: 0, span: 4 }">
            <p class="version" [innerHtml]="version"></p>
        </div>
      </div>
    </div> <!-- /custom-container -->

</div>
