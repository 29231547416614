import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiClientService } from './services/api-client.service';
import { AuthService } from './services/auth.service';
import { JwtInterceptor } from './services/jwt.interceptor';
import { AuthImgPipe } from './pipes/auth-img.pipe';
import { ImgToAttachmentPipe, ImgToAttachmentPipeNew } from './pipes/img-attachment.pipe';
import { AuthCachedImg } from './pipes/auth-cached-img.pipe';



@NgModule({
  declarations: [
    AuthImgPipe,
    AuthCachedImg,
    ImgToAttachmentPipe,
    ImgToAttachmentPipeNew
  ],
  imports: [
    CommonModule,
    HttpClientModule
  ],
  exports : [
    AuthImgPipe,
    AuthCachedImg,
    ImgToAttachmentPipe,
    ImgToAttachmentPipeNew
  ],
  providers: [
    ApiClientService,
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    }
  ]
})
export class ApiClientModule { }
