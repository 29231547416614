import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/modules/api-client/services/auth.service';
import { ApiClientService } from 'src/app/modules/api-client/services/api-client.service';
// import { AuthService } from 'src/app/modules/api-client/services/auth.service';
// import { ApiClientService } from 'src/app/modules/api-client/services/api-client.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  isUserInactive: Observable<boolean>;

  constructor(
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private apiClient: ApiClientService
  ) {
  }

  ngOnInit() {
    this.isUserInactive = this.activatedRoute.queryParams.pipe(
      map(params => !!params.user_inactive)
    );
  }

  click() {
    this.apiClient.isApiAvailable()
    .subscribe(isApiAvailable => {
      if (isApiAvailable) {
        this.authService.login();
      }
    });
  }

}
