import { Pipe, PipeTransform } from '@angular/core';
import { LocalDatabaseService } from '../../shared/services/local-database.service';

@Pipe({
  name: 'toAttachmentId',
})
export class ImgToAttachmentPipe implements PipeTransform {

  images: any;
  constructor(
    private localDb: LocalDatabaseService
  ) { }

  transform(imageId: string): string {
    const attachment = this.localDb.getAttachmentForImageId(imageId);
    return attachment ? attachment.id : null;
  }

}

@Pipe({
  name: 'toAttachmentIdNew',
})
export class ImgToAttachmentPipeNew implements PipeTransform {

  images: any;
  constructor(
  ) { }

  transform(attachmentId: string): string {
    return typeof attachmentId == "string" ? attachmentId : null;
  }

}
