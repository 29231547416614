import { Codable } from '../interfaces/serialization';

export class Substance implements Codable<Substance> {

    id: string;
    name: string;
    shortName: string;
    description: string;
    activeSubstance: string;
    gdcNumber: string;
    rgNumber: string;
    roNumber: string;
    position: number;
    images: string[];
    videos: { categoryId?: string, videoId: string }[];
    studyCategoryDependencies: { categoryId: string, studies: string[] }[];
    categoriesDependencies: { categoryId: string, phases: string[] }[];
    linkSubstancesIds: string[];
    testingSubstances: string[];
    speciality: string;


    deserialize(object: any, id?: any): Substance {
        const substance = new Substance();
        substance.id = object.id || id;
        substance.name = object.name;
        substance.shortName = object.short_name ? object.short_name : null;
        substance.description = object.description;
        substance.activeSubstance = object.active_substance;
        substance.gdcNumber = object.gdc_number;
        substance.rgNumber = object.rg_number;
        substance.roNumber = object.ro_number;
        substance.position = object.position;
        substance.images = object.images;
        substance.videos = (object.videos || []).map(video => ({ categoryId: video.category_id, videoId: video.video_id }));
        substance.studyCategoryDependencies = (object.study_category_dependencies || [])
            .map(studyCatDep => ({
                categoryId: studyCatDep.category_id,
                studies: studyCatDep.studies
            }));
        substance.categoriesDependencies = (object.categories_dependencies || [])
            .map(catDep => ({
                categoryId: catDep.category_id,
                phases: catDep.phases
            }));
        substance.linkSubstancesIds = object.link_substance_ids;
        substance.testingSubstances = object.testing_substances;
        substance.speciality = object.speciality;

        return substance;
    }

    serialize(): any {
        let studiesCategories = [];
        if (this.studyCategoryDependencies) {
            this.studyCategoryDependencies.forEach(studyCatDep => {
                try {
                    if (studyCatDep.studies && studyCatDep.studies[0]) {
                        studiesCategories.push({
                            category_id: studyCatDep.categoryId,
                            studies: studyCatDep.studies,
                            main_studies: studyCatDep.studies
                        })
                    }
                } catch (error) { }
            })
        }
        let categoryDepencies = [];
        if (this.categoriesDependencies) {
            this.categoriesDependencies.forEach(item => {
                try {
                    if (item.phases && item.phases[0]) {
                        categoryDepencies.push({
                            category_id: item.categoryId,
                            phases: item.phases,
                        })
                    }
                } catch (error) { }
            })
        }
        let videosToSave = []
        if (this.videos) {
            this.videos.forEach(item => {
                try {
                    if (typeof (item.videoId) == "string") {
                        if (typeof (item.categoryId) == "string") {
                            videosToSave.push(item)
                        } else { videosToSave.push({ video_id: item.videoId }) }
                    }
                } catch (error) { }
            })
        }

        let imagesToSave = []
        this.images.forEach(item => { if (item) { imagesToSave.push(item) } })
        return {
            id: this.id,
            name: this.name,
            short_name: typeof (this.shortName) == "string" ? this.shortName : "",
            description: typeof (this.description) == "string" ? this.description : "",
            active_substance: typeof (this.activeSubstance) == "string" ? this.activeSubstance : "",
            gdc_number: typeof (this.gdcNumber) == "string" ? this.gdcNumber : "",
            rg_number: typeof (this.rgNumber) == "string" ? this.rgNumber : "",
            ro_number: typeof (this.roNumber) == "string" ? this.roNumber : "",
            //position: typeof (this.position) == "number" ? this.position : 0, //Position is not working, because schema has not "position" value.
            images: imagesToSave,
            videos: videosToSave,
            study_category_dependencies: studiesCategories,
            categories_dependencies: categoryDepencies,
            link_substance_ids: Array.isArray(this.linkSubstancesIds) ? this.linkSubstancesIds : [],
            testing_substances: Array.isArray(this.testingSubstances) ? this.testingSubstances : [],
            speciality: typeof (this.speciality) == "string" ? this.speciality : null
        };
    }
}

