import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/modules/api-client/services/auth.service';

@Component({
  selector: 'app-sso-success',
  templateUrl: './sso-success.component.html',
  styleUrls: ['./sso-success.component.scss']
})
export class SsoSuccessComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.activatedRoute.queryParams.pipe(map(params => params.jwt))
    .subscribe(jwt => {
      this.authService.setJwtToken(jwt);
      this.router.navigate(['/events']);
    });
  }

  ngOnInit() {}

}
