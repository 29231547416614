import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalDatabaseService } from 'src/app/modules/shared/services/local-database.service';
import { Pdf, Video, Substance } from 'src/app/modules/api-client/models';
import { MatomoTracker } from 'ngx-matomo';

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss']
})
export class MediaComponent implements OnInit {

  type: string;
  attachmentId: string;
  substance: Substance;

  constructor(
    public activatedRoute: ActivatedRoute,
    public router: Router,
    private localDb: LocalDatabaseService,
    private matomoTracker: MatomoTracker
  ) { }

  ngOnInit() {

    this.activatedRoute.params.subscribe(params => {

      if (params.substanceId) {
        this.substance = this.localDb.getSubstance(params.substanceId);
      }

      // generic media object
      let media: Video | Pdf = this.localDb.getVideo(params.mediaId);
      if (!media) {
        media = this.localDb.getPdf(params.mediaId);
      }

      // media name for mamoto tracking
      const mediaName = (this.substance && this.substance.name) ? `${media.name} (${this.substance.name})` : `${media.name}`;

      // discover which type of media
      if (media instanceof Video) {
        this.type = media.slideshowId ? 'pdf' : 'video';
        this.attachmentId = media.slideshowId || media.videoSoundId || media.videoSubtitleId;

        // matomo tracking depending on media type
        if (this.type === 'pdf') {
          this.matomoTracker.trackEvent('OpenPdf', 'pdf.name', mediaName); // track for pdf
        } else if (this.type === 'video') {
          this.matomoTracker.trackEvent('WatchVideo', 'video.name', mediaName); // track for video
        }

      } else {
        this.type = 'pdf';
        this.attachmentId = media.attachmentId;
        this.matomoTracker.trackEvent('OpenPdf', 'pdf.name', mediaName); // track for pdf
      }

    });

  }

}
