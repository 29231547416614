import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, Subject, of, EMPTY } from 'rxjs';
import { AuthService } from './auth.service';
import { catchError, switchMap, flatMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ApiClientService } from './api-client.service';
import { environment } from 'src/environments/environment';
import { NzModalService, NzModalRef } from 'ng-zorro-antd/modal';
import { TranslateService } from '@ngx-translate/core';
import { RequestsFailedLoggerService } from './requests-failed-logger.service';

@Injectable({
  providedIn: 'root'
})
export class JwtInterceptor implements HttpInterceptor {

  private refreshSubject: Subject<any> = new Subject<any>();
  private serverNotAvailableModal: NzModalRef;

  constructor(
    private authService: AuthService,
    private apiClient: ApiClientService,
    private router: Router,
    private modalService: NzModalService,
    private translate: TranslateService,
    private requestsLogger: RequestsFailedLoggerService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (request.url !== environment.api_url && this.authService.getJwtToken()) {
      request = this.updateAuthorizationHeader(request);
    }
    request = this.updateApiKeyQueryParameter(request);

    return next.handle(request).pipe(
      catchError((response: HttpErrorResponse) => {

        // If the server is not available, show an error modal and return
        if (response.status >= 502 && response.status <= 504) {
          this.handleServerNotAvailable();
          return throwError(response);
        }

        // We use the base api url to check if the api is available, so we ignore it here
        if (request.url === environment.api_url) {
          return throwError(response);
        }

        return this.errorFromResponse(response)
        .pipe(
          flatMap((error) => {
            const isUnauthorized = response.status === 401;
            const isInactive = (response.status === 401 && error.error.message === 'The user is inactive');
            const isTokenExpired = (response.status === 400 && error.error.message === 'The provided token has expired');

            if (isInactive) {
              this.router.navigate(['/login'], { queryParams: { user_inactive: true } });
              return EMPTY;
            } else {

              if (isTokenExpired) {

                return this.ifTokenExpired().pipe(
                  switchMap(() => {
                    return next.handle(this.updateAuthorizationHeader(request));
                  })
                );

              } else {

                if (isUnauthorized || isTokenExpired) {
                  this.redirectToLoginPage();
                  return EMPTY;
                } else {
                  this.requestsLogger.logFailedRequest(request, response);
                  return throwError(response);
                }

              }

            }
          })
        );

      }));
  }

  private updateApiKeyQueryParameter(request: HttpRequest<any>) {
    return request.clone({
      setParams: {
        apiKey: environment.api_key
      }
    });
  }

  private updateAuthorizationHeader(request: HttpRequest<any>) {
    return request.clone({
      setHeaders: {
        Authorization: `JWT ${this.authService.getJwtToken()}`
      }
    });
  }

  private redirectToLoginPage() {
    // Use this to force refresh the page.
    // This guarantees that no dangling modals are still visible
    window.location.href = '/login';
  }

  private ifTokenExpired() {
    this.refreshSubject.subscribe({
      complete: () => {
        this.refreshSubject = new Subject<any>();
      }
    });
    if (this.refreshSubject.observers.length === 1) {
      this.apiClient.refreshToken().subscribe(this.refreshSubject);
    }
    return this.refreshSubject;
  }

  private errorFromResponse(response: HttpErrorResponse): Observable<any> {
    if (response.error instanceof Blob) {
      const subject = new Subject<any>();
      const reader = new FileReader();
      reader.addEventListener('loadend', (e: ProgressEvent) => {
        const text = (e.target as FileReader).result as string;
        try {
          subject.next(JSON.parse(text));
        } catch {
          subject.next({ error: '', message: '' });
        }
        subject.complete();
      });
      reader.readAsText(response.error);
      return subject.asObservable();
    } else {
      return of(response.error);
    }
  }

  private handleServerNotAvailable() {
    const closeModal = () => {
      this.serverNotAvailableModal.destroy();
      this.serverNotAvailableModal = null;
    };

    if (!this.serverNotAvailableModal) {
      this.serverNotAvailableModal = this.modalService.error({
        nzTitle: this.translate.instant('Shared.ServerNotAvailable'),
        nzOnOk: () => closeModal(),
        nzOnCancel: () => closeModal()
      });
    }
  }

}
