import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class BreadCrumbsService {

  constructor(
    public location: Location,
    public router: Router,
    public activatedRoute: ActivatedRoute
  ) { }

  updateBreadCrumbKey(key: string, value: string) {

    // must trigger update on URL so that breadcrumbs react
    const path = this.location.path().split('/');
    const index = path.indexOf(key);

    // to have this work throughout the system, must only update the category_id
    if (index !== -1) {
      path[index + 1] = value;
      this.router.navigate(path, { queryParamsHandling: 'merge' }); // updating the URL to trigger breadcrumbs onUrlChange
    }

  }

}
