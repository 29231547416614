<div class="temp-event-landing">

    <ng-container *ngIf="isLoading">
        <div class="temp-event-loading">
            <app-spinner></app-spinner>
        </div>
    </ng-container>

    <ng-container *ngIf="eventHasErrors">

        <div class="custom-container">
            <div nz-row class="roche-mt-50">
                <div nz-col [nzXl]="{ span: 20, offset : 2 }" [nzSm]="{ span: 22, offset : 1 }">
                    <div class="temp-event-has-errors">

                        <ng-container *ngIf="isEventExpired">
                            <p>{{ 'TempEvents.EventExpired' | translate }}</p>
                            <h1>{{ 'TempEvents.EventExpired' | translate }}</h1>
                            <h2>{{ 'TempEvents.EventExpiredFullMessage' | translate }}</h2>
                        </ng-container>

                        <ng-container *ngIf="isInvalidToken || isEventTokenMissingInfo">
                            <p>{{ 'TempEvents.TokenInvalid' | translate }}</p>
                            <h1>{{ 'TempEvents.TokenInvalid' | translate }}</h1>
                            <h2>{{ 'TempEvents.TokenInvalidFullMessage' | translate }}</h2>
                        </ng-container>
                        
                    </div>
                </div>
            </div>
        </div>
        
    </ng-container>

</div>