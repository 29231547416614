import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TempEventService } from '../../services/temp-event.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() title = '';
  @Input() isDataPackageVisible = false;
  @Input() isLogoutVisible = false;
  @Input() isHomeVisible = false;
  @Input() isReferencesVisible = false;
  @Input() isSearchVisible = false;
  @Input() isEditionVisible = false;
  @Input() isStatsVisible = false;

  @Output() didPressLogo = new EventEmitter<void>();
  @Output() didPressPackageButton = new EventEmitter<void>();
  @Output() didPressLogoutButton = new EventEmitter<void>();
  @Output() didPressHomeButton = new EventEmitter<void>();
  @Output() didPressReferencesButton = new EventEmitter<void>();
  @Output() didPressSearchButton = new EventEmitter<void>();
  @Output() didPressEditionButton = new EventEmitter<void>();
  @Output() didPressEditionNewButton = new EventEmitter<void>();
  @Output() didPressStatsButton = new EventEmitter<void>();

  constructor(
    public router: Router,
    private tempEventService: TempEventService
  ) { }

  ngOnInit() {
  }

  clickLogo() {
    this.didPressLogo.emit();
  }

  packageButtonClicked($event) {
    $event.preventDefault();
    this.didPressPackageButton.emit();
  }

  logoutButtonClicked($event) {
    $event.preventDefault();
    this.didPressLogoutButton.emit();
  }

  homeButtonClicked($event) {
    $event.preventDefault();
    this.didPressHomeButton.emit();
  }

  referencesButtonClicked($event) {
    $event.preventDefault();
    this.didPressReferencesButton.emit();
  }

  searchButtonClicked($event) {
    $event.preventDefault();
    this.didPressSearchButton.emit();
  }

  editionButtonClicked($event) {
    $event.preventDefault();
    this.didPressEditionButton.emit();
  }
  editionNewButtonClicked($event) {
    $event.preventDefault();
    this.didPressEditionNewButton.emit();
  }

  statsButtonClicked($event) {
    $event.preventDefault();
    this.didPressStatsButton.emit();
  }

  isNotTempEvent(): boolean {
    return this.tempEventService.isTempEvent() === false;
  }

}
