import { Component, OnInit } from '@angular/core';
import { ModeOfAction, Target, Speciality, Topic } from 'src/app/modules/api-client/models';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalDatabaseService } from 'src/app/modules/shared/services/local-database.service';

@Component({
  selector: 'app-targets',
  templateUrl: './targets.component.html',
  styleUrls: ['./targets.component.scss']
})
export class TargetsComponent implements OnInit {

  modeOfAction: ModeOfAction;
  targets: Target[];
  speciality: Speciality;
  topic: Topic;
  pageReady: boolean;


  constructor(
    public activatedRoute: ActivatedRoute,
    private localDb: LocalDatabaseService,
    public router: Router
  ) { }

  ngOnInit() {

    this.pageReady = false;

    // must get / set from parent routing
    this.activatedRoute.parent.params.subscribe(params => {
      this.topic = this.localDb.getTopic(params.topicId);
      this.speciality = this.localDb.getSpeciality(params.specialityId);
    });

    // can get directly from :self routing
    this.activatedRoute.params.subscribe(params => {
      this.modeOfAction = this.localDb.getModeOfAction(params.modeOfActionId);
      this.targets = this.localDb.getTargetsForModeOfAction(this.speciality.id, this.topic.id, this.modeOfAction.id);

      if (this.targets.length === 1) {
        this.toTargetDetails(this.targets[0]);
      } else {
        this.pageReady = true;
      }
    });
  }

  toTargetDetails(target: Target) {
    const url = `specialities/${this.speciality.id}/topics/${this.topic.id}/mode_of_actions/${this.modeOfAction.id}/targets/${target.id}`;
    this.router.navigate([url]);
  }

}
