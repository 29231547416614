import { Codable } from '../interfaces/serialization';

export class ModeOfAction implements Codable<ModeOfAction> {

    id: string;
    name: string;
    position: number;
    imageId: string;
    topicId: string;

    deserialize(object: any, id?: any): ModeOfAction {
        const moa = new ModeOfAction();
        moa.id = object.id || id;
        moa.name = object.name;
        moa.position = object.position || 0;
        moa.imageId = object.image_id || null;
        moa.topicId = object.topic_id || null;
        return moa;
    }

    serialize(): any {
        return {
            id: this.id,
            name: this.name,
            position:typeof (this.position) == "number" ? this.position || 0 : 0,
            image_id: typeof (this.imageId) == "string" ? this.imageId.length ? this.imageId : null : null,
            topic_id: typeof (this.topicId) == "string" ? this.topicId.length ? this.topicId : null : null
        };
    }

}