import { Component, OnInit } from '@angular/core';
import { ModeOfAction, Topic, Speciality } from 'src/app/modules/api-client/models';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalDatabaseService } from 'src/app/modules/shared/services/local-database.service';

@Component({
  selector: 'app-analysis',
  templateUrl: './analysis.component.html',
  styleUrls: ['./analysis.component.scss']
})
export class AnalysisComponent implements OnInit {

  speciality: Speciality;
  topic: Topic;
  pageReady: boolean;
  modesOfAction: ModeOfAction[];

  constructor(
    public activatedRoute: ActivatedRoute,
    public router: Router,
    private localDb: LocalDatabaseService,
  ) {
    try {
      this.pageReady = false;

      this.activatedRoute.parent.params.subscribe(params => {
        try {
          if (params.topicId) {
            this.speciality = this.localDb.getSpeciality(params.specialityId);
            this.topic = this.localDb.getTopic(params.topicId);
            this.modesOfAction = this.localDb.getModeOfActionForTopicId(params.topicId, params.specialityId);
          }
        } catch (error) { console.error("Error: It could not be possible redirect to new url"); this.navigateToThisUrl() }
      });

      // redirect to targets if only one mode of action
      if (this.modesOfAction.length === 1) {
        this.toModeOfActionTargets(this.modesOfAction[0]);
      } else {
        this.pageReady = true;
      }
    } catch (error) { console.error("Error: It could not be possible redirect to new url"); this.navigateToThisUrl() }
  }

  ngOnInit() { }

  navigateToThisUrl() {
    try {
      this.router.navigate(["specialities", this.speciality.id, "topics", this.topic.id, "analysis"], { replaceUrl: true });
    } catch (error) { this.router.navigate(["404"]); }
  }

  toModeOfActionTargets(modeOfAction: ModeOfAction, isFoundationMore?: boolean) {
    try {
      if (!isFoundationMore) {
        this.router.navigate(["specialities", this.speciality.id, "topics", this.topic.id, "mode_of_actions", modeOfAction.id, "groupAssets"])
      } else {
        let modeOfActionToWork = this.modesOfAction.find(itemModeOfAction => itemModeOfAction.name == "Foundation More")
        this.localDb.getTargetsForModeOfAction(this.speciality.id, this.topic.id, modeOfActionToWork.id)
        this.router.navigate(["specialities", this.speciality.id, "topics", this.topic.id, "mode_of_actions", modeOfActionToWork.id, "groupAssets"], { queryParams: { foundationMore: true } });
      }
    } catch (error) { console.error("Error: It could not be possible redirect to the new url") }
  }

  didSelect() {
    try {
      if (this.topic.pdfId) {
        const parentBasePath = this.activatedRoute.parent.snapshot.url.map(u => u.path);
        const absoluteUrl = [...parentBasePath];
        const path = absoluteUrl.concat(['media', this.topic.pdfId]);

        // preserve queryParams to be able to go back to state if possible
        this.activatedRoute.queryParams.subscribe(queryParams => {
          this.router.navigate(path, { queryParams });
        });
      }
      else {
        console.log("No content available")
      }
    } catch (error) { console.error("Error: It could not be possible charge the pdf") }
  }

  getImageFM() {
    try {
      let getImageForFoundationMore = this.modesOfAction.find(item => item.name.toUpperCase() == "FOUNDATION MORE")
      return getImageForFoundationMore.imageId
    } catch (error) { console.error("Error: It could not be possible charge the image of Foundation More"); return null }
  }

  getTypeOfAssets(typeOfAssets) {
    try {
      let assetsToReturn = []
      typeOfAssets.forEach(itemAssets => {
        if (itemAssets.name != "Foundation More") {
          assetsToReturn.push(itemAssets)
        }
      })
      return assetsToReturn
    } catch (error) { console.error("Error: It could not be possible charge the assets"); return [] }
  }
}
