import { Injectable } from '@angular/core';
import { Category, StudyType, StudyPhase, Study, StudyCategory, Substance } from '../../api-client/models';
import { LocalDatabaseService } from '../services/local-database.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { GridStructure, GridStructureY } from '../components/grid/grid.component';

export class GridItem {
  id: string;
  metadata: {
    name: string;
    isRecruiting: boolean;
    rolloverStudies: Study[],
    rolloverStudy: string;
    hasRolloverStudies: boolean
    hasContent: boolean;
  };
  span: number;
  offset?: number;
  endSpan?: number;
}

export class RolloverStudyGroup {
  id: string;
  name: string;
  items: GridItem[];
  isRolloverGroup: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class StudiesGridUtil {

  substanceId: string;
  categoryId: string;
  specialityId: string;
  studyTypes: StudyType[];

  private gridStructureSubject = new BehaviorSubject<GridStructure>(undefined);
  private substancesSubject = new BehaviorSubject<Substance[]>([]);

  constructor(
    private localDb: LocalDatabaseService
  ) { }

  gridStructure(): Observable<GridStructure> {
    return this.gridStructureSubject.asObservable();
  }

  substances(): Observable<Substance[]> {
    return this.substancesSubject.asObservable();
  }

  calculateGridStructure(phaseId?: string) {
    const studiesCategories = this.getStudyCategories();
    const structure: GridStructure = {
      y: studiesCategories.map(c => this.getYGridStructureForStudyCategory(c, phaseId)),
      x: this.getStudyPhasesForCategory().map(sp => ({ id: sp.id, name: sp.name }))
    };
    this.gridStructureSubject.next(structure);

    this.substancesSubject.next(this.getAllSubstancesWithStudies());
  }

  getCategoriesForStudies(): Category[] {
    let categories: Category[];
    if (this.substanceId) {
      // We are showing studies which belong to the substance
      // categories = this.localDb.getCategoriesForSubstanceWithStudyOfTypes(this.studyTypes, this.substanceId);
      categories = this.localDb.getStudyCategoriesForSubstance(this.substanceId, this.studyTypes);
    } else {
      // We are shwowing studies which belong to the category
      categories = this.localDb.getCategoriesForSpecialityId(this.specialityId);
    }
    // Only show categories which belong to the currently selected speciality
    return categories
      .filter(c => this.localDb.getIsCategoryVisibleForSpeciality(this.specialityId, c.id))
      .filter(c => this.localDb.getCategoriesForSpecialityId(this.specialityId).map(cc => cc.id).indexOf(c.id) !== -1)
      .sort((c1, c2) => c1.name < c2.name ? -1 : 1);
  }

  private getAllSubstancesWithStudies(): Substance[] {
    return this.localDb.getAllSubstancesInCategoryWithStudiesOfTypes(this.categoryId, this.studyTypes);
  }

  private getStudyPhasesForCategory(): StudyPhase[] {
    return this.localDb.getStudyPhasesForCategory(this.categoryId);
  }

  private getStudyCategories(): StudyCategory[] {
    const studies = this.getAllStudies();
    const studyCategoryIds = studies.map(s => s.studiesCategories).reduce((prev, cur) => prev.concat(cur), [])
      .sort((sc1, sc2) => sc1.priority < sc2.priority ? -1 : 1)
      .map(sc => sc.id)
      .reduce((prev, curr) => {
        if (prev.indexOf(curr) === -1) {
          prev.push(curr);
        }
        return prev;
      }, []);
    return studyCategoryIds.map(id => this.localDb.getStudyCategory(id)).sort((sc1, sc2) => sc1.position < sc2.position ? -1 : 1);
  }

  private getAllStudies(): Study[] {
    return this.localDb.getAllStudiesFor(this.categoryId, this.substanceId, this.studyTypes);
  }

  private getYGridStructureForStudyCategory(studyCategory: StudyCategory, phaseId: string): GridStructureY {
    const phases = this.getStudyPhasesForCategory();
    if (phases.length === 0) {
      return null;
    }

    const studies = this.getStudiesForStudyCategory(studyCategory).filter(itemStudy => phaseId ? itemStudy.phase ? itemStudy.phase == phaseId ? true : false : true/*This check if phase is correct. Should be "false" to work correctly but in the maintime we change be true. Now if have not phase, add the study too.*/ : true);
    //Here therapy lines (studyPhases) are been organising. Important to draw the studies correctly when they are in 2 or more therapy lines.
    studies.map(itemStudy => itemStudy.studiesCategories.map(itemIndication => {
      let checkTherapyLines = itemIndication.studiesPhases.map(itemTherapyLine => {
        return this.localDb.getStudyPhase(itemTherapyLine)
      })

      checkTherapyLines.sort((x1, x2) => x1.position - x2.position)
      itemIndication.studiesPhases = checkTherapyLines.map(item => item.id)
      return itemIndication
    }))

    const rolloverStudies = this.getRolloverStudiesFromStudies(studies);
    const groups = this.getGroupsFromAllStudies(studies);

    // with the created groups, iterate through them, to get the item structure from getItemsStructureFromStudies
    groups.forEach(group => {
      if (group.isRolloverGroup) {
        const groupStudies = this.getRolloverStudiesFromGroupId(group.id, studies);
        const groupItems = this.getItemsStructureFromStudies(groupStudies, phases, studyCategory);
        group.items = groupItems;
      } else {
        // regular group - make sure to filter out not including from rolloverStudies[] and only s.rolloverStudy === null
        const groupItems = this.getItemsStructureFromStudies(
          studies.filter(s => s.rolloverStudy === null && !rolloverStudies.find(rs => rs.id === s.id)), phases, studyCategory);
        group.items = groupItems;
      }

    });

    const items = this.getItemsStructureFromStudies(studies, phases, studyCategory);

    return { id: studyCategory.id, name: studyCategory.name, items, groups };
  }

  // to respect the display order we cycle through the received studies in order
  // when we find a rolloverStudy we push the id to the group and only stop pushing into it,
  // the next time we find a study that does not belong to the latest rolloverStudy
  getGroupsFromAllStudies(studies: Study[]) {

    const groups = [];

    studies.forEach(study => {
      if (this.isStudyRolloverGroup(study.id)) {
        const rolloverGroup = groups.find(g => g.id === study.id);
        if (!rolloverGroup) {
          // create rollover group
          groups.push({ id: study.id, name: study.name, items: [study], isRolloverGroup: true });
        } else {
          rolloverGroup.items.push(study);
        }
      } else {
        // but does it belong to a rollover study?
        if (study.rolloverStudy) {
          // if its a rollover study it must be pushed into the items[] of the rollover group
          const rolloverGroup = groups.find(g => g.id === study.rolloverStudy);
          if (!rolloverGroup) {
            groups.push({ id: study.rolloverStudy, name: study.name, items: [study], isRolloverGroup: true });
          } else {
            rolloverGroup.items.push(study);
          }
        } else {

          let lastNonRolloverGroup;
          if (groups[groups.length - 1]?.isRolloverGroup === false) {
            // create a reference for the 'last'
            lastNonRolloverGroup = groups[groups.length - 1];
          } else {
            // create it and set it
            groups.push({ id: null, items: [], isRolloverGroup: false });
            lastNonRolloverGroup = groups[groups.length - 1];
          }

          lastNonRolloverGroup.items.push(study);

        }
      }
    });

    return groups;
  }

  isStudyRolloverGroup(studyId: string): boolean {
    const allStudies = this.localDb.getAllStudiesFor(this.categoryId, this.substanceId, [
      StudyType.CURRENT_DATA, StudyType.RECRUITING_STUDY, StudyType.NONE_RECRUITING_STUDY
    ]);
    return allStudies.find(s => s.rolloverStudy === studyId) ? true : false;
  }

  private getRolloverStudiesFromGroupId(groupId: string, allStudies: Study[]): Study[] {
    return allStudies.filter(s => s.rolloverStudy === groupId).concat(allStudies.find(as => as.id === groupId));
  }

  private getItemsStructureFromStudies(studies: Study[], phases, studyCategory): GridItem[] {

    const items: GridItem[] = [];
    const columnCount = phases.length;
    let currentColumn = 0;

    while (studies.length > 0) {
      let study = studies[0];
      let colOffset = 0;
      let studyPhases = this.getStudyPhasesForStudyInStudyCategory(study, studyCategory);
      let startColum = phases.indexOf(studyPhases[0]) >= 0 ? phases.indexOf(studyPhases[0]) : 0;

      if (startColum !== currentColumn) {
        const couldFitStudy = studies.find(possibleStudy => {
          const sPhases = this.getStudyPhasesForStudyInStudyCategory(possibleStudy, studyCategory);
          const sc = phases.indexOf(sPhases[0]) >= 0 ? phases.indexOf(sPhases[0]) : 0;
          return ((startColum > currentColumn && sc >= currentColumn && sc < startColum) ||
            (startColum < currentColumn && (sc < startColum || sc >= currentColumn)));
        });
        if (couldFitStudy) {
          study = couldFitStudy;
          studyPhases = this.getStudyPhasesForStudyInStudyCategory(study, studyCategory);
          startColum = phases.indexOf(studyPhases[0]) >= 0 ? phases.indexOf(studyPhases[0]) : 0;
        }
      }

      const colspan = studyPhases.length;
      let endOffset = 0;
      if (startColum < currentColumn) {
        endOffset = (columnCount - currentColumn);
        colOffset = startColum;
        currentColumn = startColum + colspan;
      } else {
        endOffset = 0;
        colOffset = startColum - currentColumn;
        currentColumn += colOffset + colspan;
      }

      studies.splice(studies.indexOf(study), 1);

      if (endOffset > 0) {
        items[items.length - 1].endSpan = endOffset;
      }

      let checkStudy = []
      try {
        checkStudy = study.studiesCategories.map(itemIndication => {
          if (itemIndication.studiesPhases?.length > 0) { return true }
          else { return false }
        })
      } catch (error) { }

      if (checkStudy[0]) {
        items.push({
          id: study.id,
          metadata: {
            name: study.name,
            isRecruiting: study.type === StudyType.RECRUITING_STUDY,
            rolloverStudies: studies.filter(s => s.rolloverStudy === study.id),
            rolloverStudy: study.rolloverStudy,
            hasRolloverStudies: studies.filter(s => s.rolloverStudy === study.id)?.length > 0,
            hasContent: study.hasContent ? true : false
          },
          span: colspan,
          offset: colOffset,
          endSpan: 0
        });
      } else { console.log(`The study ${study.name} have not therapy line. It is not possible show it.`) }
    }
    return items;
  }


  private getStudiesForStudyCategory(studyCategory: StudyCategory): Study[] {
    return this.localDb.getStudiesForStudyCategoryId(studyCategory.id, this.studyTypes, this.substanceId)
      .sort((s1, s2) => s1.name < s2.name ? -1 : 1);
  }

  private getStudyPhasesForStudyInStudyCategory(study: Study, studyCategory: StudyCategory): StudyPhase[] {
    return study.studiesCategories.find(sc => sc.id === studyCategory.id).studiesPhases
      .map(id => this.localDb.getStudyPhase(id));
  }

  getRolloverStudiesFromStudies(studies: Study[]): Study[] {
    // we have to fetch allStudies unfiltered, so we can determine which studies 'point' to our passed in studies
    // if we were to rely on all input studies, we could have filtered studies (by StudyType)
    // meaning they would not be taken into account
    const allStudies = this.localDb.getAllStudiesFor(this.categoryId, this.substanceId, [
      StudyType.CURRENT_DATA, StudyType.RECRUITING_STUDY, StudyType.NONE_RECRUITING_STUDY
    ]);
    const studiesThatTargetRolloverStudy = allStudies.filter(s => s.rolloverStudy !== null);
    const rolloverStudies: Study[] = [];
    studiesThatTargetRolloverStudy.forEach(s => {
      if (!rolloverStudies.find(rs => rs.id === s.rolloverStudy)) {
        rolloverStudies.push(studies.find(study => study.id === s.rolloverStudy));
      }
    });
    return rolloverStudies;
  }

}
