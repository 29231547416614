import { Codable } from '../interfaces/serialization';

export class StudyCategory implements Codable<StudyCategory> {

    id: string;
    name: string;
    position: number;

    deserialize(object: any, id?: any): StudyCategory {
        const studyCategory = new StudyCategory();
        studyCategory.id = object.id || id;
        studyCategory.name = object.name;
        studyCategory.position = object.position;
        return studyCategory;
    }

    serialize(): any {
        return {
            id: this.id,
            name: this.name,
            position: this.position
        };
    }

}

