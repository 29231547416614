import { Deserializable } from '../interfaces/serialization';

export class List<T extends Deserializable<T>> {

    page: number;
    pageSize: number;
    total: number;
    items: T[];

    deserialize(object: any, path: string, type: (new () => T)): List<T> {
        const l = new List<T>();
        if (!object) { return l; }

        l.page = object.page;
        l.pageSize = object.page_size;
        l.total = object.total;
        l.items = object[path].map(obj => new type().deserialize(obj));
        return l;
    }

}
