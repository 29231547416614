<div nz-row>
    <div nz-col [nzXs]="{ offset: 1, span: 20 }" [nzSm]="{ offset: 2, span: 18 }">

        <div class="search-input-wrapper">
            <nz-input-group [nzPrefix]="prefix" [nzSuffix]="suffix" nzSize="large">
                <input type="text" nz-input [(ngModel)]="searchTerm" placeholder="{{ 'SearchScreen.InputPlaceholder' | translate }}" />
            </nz-input-group>
            <ng-template #prefix><i class="icon icon-magnifiying-glass"></i></ng-template>
            <ng-template #suffix
            ><i
                nz-icon
                nz-tooltip
                class="ant-input-clear-icon"
                nzTheme="fill"
                nzType="close-circle"
                *ngIf="searchTerm"
                (click)="searchTerm = null"
            ></i
            ></ng-template>
        </div>

        <div class="search-results-wrapper roche-mb-50" *ngIf="(results | async) as results">
            <h1 class="roche-text-center roche-title-section">{{ 'SearchScreen.SearchResults' | translate }}</h1>

            <div nz-row>
                <div nz-col [nzXs]="{ span: 22 }" [nzSm]="{ span: 12 }">
                    <p class="results-section-title">{{ 'SearchScreen.Substances' | translate }}</p>

                    <ul class="results-list" *ngIf="results.substances.length > 0 else noResultsSubstances">
                        <li *ngFor="let substance of results.substances">
                            <a (click)="didSelectSubstance(substance)">{{substance.name}}</a>
                        </li>
                    </ul>
                    <ng-template #noResultsSubstances>
                        <p class="no-results">{{ 'SearchScreen.NoResultsSubstances' | translate }}</p>
                    </ng-template>
                </div>
                <div nz-col [nzXs]="{ span: 22 }" [nzSm]="{ span: 12 }">
                    <p class="results-section-title">{{ 'SearchScreen.Studies' | translate }}</p>

                    <ul class="results-list" *ngIf="results.studies.length > 0 else noResultsStudies">
                        <li *ngFor="let study of results.studies">
                            <a (click)="didSelectStudy(study)">{{study.name}}</a>
                        </li>
                    </ul>
                    <ng-template #noResultsStudies>
                        <p class="no-results">{{ 'SearchScreen.NoResultsSubstances' | translate }}</p>
                    </ng-template>
                </div>
            </div>
        </div>

    </div>
</div>
