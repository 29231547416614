import { Codable } from '../interfaces/serialization';
import { Image } from './image';
import { Video } from './video';
import { Pdf } from './pdf';
import { Speciality } from './speciality';
import { Substance } from './substance';
import { Category } from './category';
import { Target } from './target';
import { ModeOfAction } from './mode-of-action';
import { Topic } from './topic';
import { Phase } from './phase';
import { Study } from './study';
import { StudyCategory } from './study-category';
import { StudyPhase } from './study-phase';
import { Attachment } from './attachment';
import { Event } from './event';

export class EventFullData implements Codable<EventFullData> {

    locale: string;
    images: Image[];
    videos: Video[];
    pdfs: Pdf[];
    specialities: Speciality[];
    substances: Substance[];
    categories: Category[];
    targets: Target[];
    modeOfActions: ModeOfAction[];
    topics: Topic[];
    phases: Phase[];
    studies: Study[];
    studiesCategories: StudyCategory[];
    studiesPhases: StudyPhase[];
    attachments: Attachment[];
    event: Event;

    deserialize(object: any, id?: any): EventFullData {
        const eventFullData = new EventFullData();
        eventFullData.locale = object.locale;
        eventFullData.images = object.images.map(image => (new Image()).deserialize(image));
        eventFullData.videos = object.videos.map(video => (new Video()).deserialize(video));
        eventFullData.pdfs = object.pdfs.map(pdf => (new Pdf()).deserialize(pdf));
        eventFullData.specialities = object.specialities.map(speciality => (new Speciality()).deserialize(speciality));
        eventFullData.substances = object.substances.map(substance => (new Substance()).deserialize(substance));
        eventFullData.categories = object.categories.map(category => (new Category()).deserialize(category));
        eventFullData.targets = object.targets.map(target => (new Target()).deserialize(target));
        eventFullData.modeOfActions = object.mode_of_actions.map(moa => (new ModeOfAction()).deserialize(moa));
        eventFullData.topics = object.topics.map(topic => (new Topic()).deserialize(topic));
        eventFullData.phases = object.phases.map(phase => (new Phase()).deserialize(phase));
        eventFullData.studies = object.studies.map(study => (new Study()).deserialize(study));
        eventFullData.studiesCategories = object.studies_categories.map(studyCat => (new StudyCategory()).deserialize(studyCat));
        eventFullData.studiesPhases = object.studies_phases.map(studyPhase => (new StudyPhase()).deserialize(studyPhase));
        eventFullData.attachments = object.attachments.map(attachment => (new Attachment()).deserialize(attachment));
        eventFullData.event = (new Event()).deserialize(object.event, id);
        return eventFullData;
    }

    serialize(): any {
        return {
            locale: this.locale,
            images: this.images.map( image => image.serialize() ),
            videos: this.videos.map( video => video.serialize() ),
            pdfs: this.pdfs.map( pdf => pdf.serialize() ),
            specialities: this.specialities.map( speciality => speciality.serialize() ),
            substances: this.substances.map( substance => substance.serialize() ),
            categories: this.categories.map( category => category.serialize() ),
            targets: this.targets.map( target => target.serialize() ),
            mode_of_actions: this.modeOfActions.map( modeOfAction => modeOfAction.serialize() ),
            topics: this.topics.map( topic => topic.serialize() ),
            phases: this.phases.map( phase => phase.serialize() ),
            studies: this.studies.map( study => study.serialize() ),
            studies_categories: this.studiesCategories.map( studiesCategory => studiesCategory.serialize() ),
            studies_phases: this.studiesPhases.map( studiesPhase => studiesPhase.serialize() ),
            attachments: this.attachments.map( attachment => attachment.serialize() ),
            event: this.event.serialize(),
        };
    }

}

