import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { ValidatorsFactory } from './validators/validators-factory';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzConfig, NZ_CONFIG } from 'ng-zorro-antd/core/config';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzAffixModule } from 'ng-zorro-antd/affix';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzCalendarModule } from 'ng-zorro-antd/calendar';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';

const ngZorroConfig: NzConfig = {
  message: { nzTop: 80, nzMaxStack: 1 }
};

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ApiClientModule } from '../api-client/api-client.module';
import { IndicationSelectionComponent } from './components/indication-selection/indication-selection.component';
import { LocalDatabaseService } from './services/local-database.service';
import { BreadCrumbsService } from './services/breadcrumbs.service';
import { GridComponent } from './components/grid/grid.component';
import { GridItemStudyComponent } from './components/grid-item-study/grid-item-study.component';
import { GridItemPipelineOverviewComponent } from './components/grid-item-pipeline-overview/grid-item-pipeline-overview.component';
import { DropUpComponent } from './components/drop-up/drop-up.component';
import { StudiesGridUtil } from './utils/studies-grid.util';
import { VideoComponent } from './components/video/video.component';
import { PdfComponent } from './components/pdf/pdf.component';
import { SearchUtil } from './utils/search.util';
import { ButtonIconComponent } from './components/button-icon/button-icon.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { CovidBannerComponent } from './components/covid-banner/covid-banner.component';
import { TempEventComponent } from './components/temp-event/temp-event.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { DisclaimerService } from './services/disclaimer.service';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    IndicationSelectionComponent,
    GridComponent,
    GridItemStudyComponent,
    GridItemPipelineOverviewComponent,
    DropUpComponent,
    VideoComponent,
    PdfComponent,
    ButtonIconComponent,
    BreadcrumbsComponent,
    CovidBannerComponent,
    TempEventComponent,
    SpinnerComponent
  ],
  entryComponents: [
  ],
  imports: [
    TranslateModule,
    BrowserModule,
    ApiClientModule,
    NzLayoutModule,
    NzGridModule,
    NzInputModule,
    NzSelectModule,
    NzCheckboxModule,
    NzRadioModule,
    NzFormModule,
    NzButtonModule,
    NzAlertModule,
    NzIconModule,
    NzDropDownModule,
    NzMenuModule,
    NzPaginationModule,
    NzSpinModule,
    NzDatePickerModule,
    NzMessageModule,
    NzInputNumberModule,
    NzPopconfirmModule,
    NzUploadModule,
    NzAffixModule,
    NzTimePickerModule,
    NzModalModule,
    NzAutocompleteModule,
    NzCollapseModule,
    NzToolTipModule,
    NzCalendarModule,
    NzBadgeModule,
    NzTableModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NzTagModule,
    NzStatisticModule,
    NzDividerModule,
    NzResultModule,
    NzTabsModule,
    NzSwitchModule,
    NzBreadCrumbModule,
    PdfJsViewerModule,
    PerfectScrollbarModule,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    IndicationSelectionComponent,
    GridComponent,
    GridItemStudyComponent,
    GridItemPipelineOverviewComponent,
    ButtonIconComponent,
    NzLayoutModule,
    NzGridModule,
    NzInputModule,
    NzSelectModule,
    NzCheckboxModule,
    NzRadioModule,
    NzFormModule,
    NzButtonModule,
    NzAlertModule,
    NzIconModule,
    NzDropDownModule,
    NzMenuModule,
    NzPaginationModule,
    NzSpinModule,
    NzDatePickerModule,
    NzMessageModule,
    NzInputNumberModule,
    NzPopconfirmModule,
    NzUploadModule,
    NzTimePickerModule,
    NzAffixModule,
    NzModalModule,
    NzAutocompleteModule,
    NzCollapseModule,
    NzToolTipModule,
    NzCalendarModule,
    NzBadgeModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NzTagModule,
    NzStatisticModule,
    NzDividerModule,
    NzTabsModule,
    NzDropDownModule,
    NzSwitchModule,
    DropUpComponent,
    VideoComponent,
    PdfComponent,
    PdfJsViewerModule,
    NzBreadCrumbModule,
    BreadcrumbsComponent,
    PerfectScrollbarModule,
    CovidBannerComponent,
    SpinnerComponent
  ],
  providers: [
    ValidatorsFactory,
    LocalDatabaseService,
    BreadCrumbsService,
    DisclaimerService,
    StudiesGridUtil,
    SearchUtil,
    { provide: NZ_CONFIG, useValue: ngZorroConfig },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ]
})

export class SharedModule { }
