import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Speciality } from 'src/app/modules/api-client/models';
import { LocalDatabaseService } from 'src/app/modules/shared/services/local-database.service';

@Component({
  selector: 'app-specialities',
  templateUrl: './specialities.component.html',
  styleUrls: ['./specialities.component.scss']
})
export class SpecialitiesComponent implements OnInit {

  results: Speciality[];
  eventId: string;
  listMonths: any;

  constructor(
    public router: Router,
    private localDb: LocalDatabaseService
  ) { }

  ngOnInit() {
    this.results = this.localDb.getSpecialitiesForEvent();

    this.listMonths = new Map([
      ["01", "Jan"],
      ["02", "Feb"],
      ["03", "Mar"],
      ["04", "Apr"],
      ["05", "May"],
      ["06", "Jun"],
      ["07", "Jul"],
      ["08", "Ago"],
      ["09", "Sep"],
      ["10", "Oct"],
      ["11", "Nov"],
      ["12", "Dic"]
    ])
  }

  navigateToRecruitingStudies(specialityId: string) {
    const topicId = this.localDb.getDefaultTopicIdForSpecialityId(specialityId); // will fetch either defined topic, or first
    this.router.navigate([`/specialities/${specialityId}/topics/${topicId}`]);
  }

  modificationDates(lastUpdate: string) {
    try {
      return `${this.listMonths.get(lastUpdate.slice(0, 2))} ${lastUpdate.slice(2, 6)}`
    }
    catch (error) { return `Info not available` }
  }

}
