import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzI18nService, en_US } from 'ng-zorro-antd/i18n';
import { environment } from 'src/environments/environment';
import { LocalDatabaseService } from './modules/shared/services/local-database.service';
import { Location } from '@angular/common';
import { Speciality, TopicType } from './modules/api-client/models';
import { Router, ActivatedRoute } from '@angular/router';
import { SearchUtil } from './modules/shared/utils/search.util';
import { AuthService } from './modules/api-client/services/auth.service';
import { MatomoTracker, MatomoInjector } from 'ngx-matomo';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, take } from 'rxjs/operators';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  get canShowCovidBanner(): boolean {
    const path = this.getLastPathFromCurrentLocation();
    return !['login', 'sso_success', 'sso_error', 'events', '404'].includes(path);
  }

  lang = new BehaviorSubject<string>(null);
  speciality: Speciality;
  title = '';
  footerStyle = null;
  footerVersion = null;

  isDataPackageVisible = false;
  isLogoutVisible = false;
  isHomeVisible = false;
  isReferencesVisible = false;
  isSearchVisible = false;
  hasCovidBanner: boolean;

  isEditionVisible = false;
  isStatsVisible = false;

  queryParams: {};

  constructor(
    private translateService: TranslateService,
    private i18n: NzI18nService,
    private localDb: LocalDatabaseService,
    private location: Location,
    private router: Router,
    private searchUtil: SearchUtil,
    private authService: AuthService,
    private matomoInjector: MatomoInjector,
    private matomoTracker: MatomoTracker,
    public activatedRoute: ActivatedRoute
  ) {

    this.translateService.addLangs(['en', 'de']);

    // a datapackage has been loaded, set the interface language accordingly
    if (this.localDb.getEventLangTranslation()) {
      this.translateService.setDefaultLang(this.localDb.getEventLangTranslation());
    } else {
      // a datapackage has not been loaded, check the browser lang
      const browserLanguage = navigator.language;

      // if the browser language is available on the translation options set it
      if (this.localDb.getAvailableTranslations().includes(browserLanguage)) {
        this.translateService.setDefaultLang(browserLanguage);
      } else {
        // otherwise set the absolute default
        this.translateService.setDefaultLang('en');
      }

    }

    this.i18n.setLocale(en_US);

    // setting up matomo injector
    if (environment.matomo) {
      this.matomoInjector.init(environment.matomo.url, environment.matomo.siteId);
    }

  }

  ngOnInit() {

    this.translateService.get('App.DiscoverTheRochePipeline').subscribe(defaultTitle => {
      this.title = defaultTitle;
    });

    this.lang.pipe(
      distinctUntilChanged()
    ).subscribe((lang) => {
      this.setAppLang(lang);
    });


    this.location.onUrlChange(() => {

      this.lang.next(this.localDb.getEventLangTranslation());

      /**
       * make sure we're not sending JWT related links
       * we're not using ActivatedRoute.queryParams because as an observable
       * it starts first with an empty value, which is enough to trigger a false send
       */
      if (!window.location.search.includes('?jwt') && environment.matomo) {
        this.matomoTracker.setCustomVariable(
          environment.matomo.customVariablePackage,
          'Package',
          this.localDb.getSelectedEventName(),
          'visit'
        );
        this.matomoTracker.trackPageView();
      }

      const currentSpecialityId = this.getSpecialityIdForCurrentLocation();
      const speciality: Speciality = currentSpecialityId ? this.localDb.getSpeciality(currentSpecialityId) : null;
      this.speciality = speciality;
      this.setAppTitle(speciality?.name);


      const lastPath = this.getLastPathFromCurrentLocation();
      if (['login', 'events', 'specialities', '404', 'sso_error'].indexOf(lastPath) === -1) {
        this.footerStyle = 'transparent';
        this.isHomeVisible = true;
        this.isReferencesVisible = !!this.localDb.getEventReferencesPdf();
        this.isSearchVisible = true;
      } else {
        this.footerStyle = null;
        this.isHomeVisible = false;
        this.isReferencesVisible = false;
        this.isSearchVisible = false;
      }

      if (['login', 'events', '404', 'sso_error'].indexOf(lastPath) === -1) {
        this.footerVersion = this.localDb.getEventContentVersion();
      } else {
        this.footerVersion = null;
      }

      this.isDataPackageVisible = ['specialities'].indexOf(lastPath) !== -1;
      this.isLogoutVisible = ['events', 'specialities'].indexOf(lastPath) !== -1;

      let checkRole = this.authService.getDecodedJwtToken()
      if (checkRole) {
        if (checkRole.role === "Editor" && ['events'].indexOf(lastPath) !== -1) { this.isEditionVisible = true }
        else { this.isEditionVisible = false }
      } else { this.isEditionVisible = false }

      if (checkRole) {
        if ((checkRole.role === "Stats" || checkRole.role === "Editor") && ['events'].indexOf(lastPath) !== -1) { this.isStatsVisible = true }
        else { this.isStatsVisible = false }
      } else { this.isStatsVisible = false }

      if (this.localDb.getCurrentEventId()) {
        this.hasCovidBanner = this.localDb.getEventCovidBannerState();
      }

    });
  }

  setAppLang(lang: string) {
    if (lang) {
      this.translateService.use(lang);
      this.setAppTitle(this.translateService.instant('App.DiscoverTheRochePipeline'));
    }
  }

  // sets the app title for either a speciality
  // or a specified title; subscribes once to changes on the title from lang
  // in case it has been updated due to changing lang
  setAppTitle(title?: string) {
    if (this.speciality) {
      this.title = this.speciality.name;
    } else {
      if (title) {
        this.title = title;
      } else {
        this.translateService.get('App.DiscoverTheRochePipeline').pipe(take(1)).subscribe(t => {
          this.title = t;
        });

      }
    }
  }

  didPressLogo() {
    this.router.navigate(['/specialities']);
  }

  didPressPackageButton() {
    this.router.navigate(['/events']);
  }

  didPressLogoutButton() {
    this.authService.logout();
  }

  didPressHomeButton() {
    this.router.navigate(['/specialities']);
  }

  didPressReferencesButton() {
    const referencesPdf = this.localDb.getEventReferencesPdf();
    if (referencesPdf) {
      this.matomoTracker.trackEvent('OpenReferences', 'pdf.references', this.localDb.getSelectedEventName(), null);
      this.router.navigate(['/media', referencesPdf.id]);
    }
  }

  didPressSearchButton() {
    this.searchUtil.clear();
    const specialityId = this.getSpecialityIdForCurrentLocation();
    // try to always redirect search to pipeline overview tab if exists
    const pipelineTopic = this.localDb.getTopicForTopicTypeInSpeciality(TopicType.PIPELINE_OVERVIEW, specialityId);
    const topicId = (pipelineTopic) ? pipelineTopic.id : this.getTopicIdForCurrentLocation();
    this.router.navigate(['/specialities', specialityId, 'topics', topicId, 'search']);
  }

  // didPressEditionButton() {
  //   this.router.navigate(['/edition']);
  // }
  // didPressEditionNewButton() {
  //   this.router.navigate(['/editionNew']);
  // }

  didPressStatsButton() {
    this.router.navigate(['/stats']);
  }

  private getSpecialityIdForCurrentLocation(): string {
    const pathComponents = this.location.path().split('/');
    const index = pathComponents.indexOf('specialities');
    return index + 1 < pathComponents.length ? pathComponents[index + 1] : null;
  }

  private getTopicIdForCurrentLocation(): string {
    const pathComponents = this.location.path().split('/');
    const index = pathComponents.indexOf('topics');
    return index + 1 < pathComponents.length ? pathComponents[index + 1] : null;
  }

  private getLastPathFromCurrentLocation(): string {
    const pathComponents = this.location.path().split('/');
    return pathComponents[pathComponents.length - 1];
  }

}
