<ng-container *ngIf="pageReady">
  <div nz-row>
    <div nz-col nzSpan="12" nzOffset="6">
      <div class="roche-title-section-wrapper roche-mt-50 roche-mb-50">
        <h1 class="roche-text-center roche-title-section">{{modeOfAction.name}}</h1>
      </div>
    </div>
  </div>

  <div nz-row class="roche-pb-50">
    <div nz-col [nzXl]="{ span: 16, offset : 4 }" [nzSm]="{ span: 22, offset : 1 }">
      <div nz-row [nzGutter]="{ xs: 8, sm: 8, md: 16, lg: 16, xl: 16, xxl: 16 }" class="moa-listing-details"
        style="display:flex;justify-content: center;">
        <div *ngFor="let group of targets" nz-col [nzXl]="{ span: 8}" [nzMd]="{ span: 8 }">
          <div class="moa-item-name">
            <span class="moa-activesubstance">{{group.name}}</span>
          </div>
          <div class="moa-item-details">
            <div class="moa-item-image"
              [style.backgroundImage]="group.imageId | toAttachmentId | authImg:false:'style' | async">
            </div>
            <div class="roche-pill listOptions" *ngFor="let asset of group.substances"
              (click)="openAsset(group,asset)">
              {{getAsset(asset)}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>