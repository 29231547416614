import { Component, OnInit, Input, TemplateRef, OnChanges } from '@angular/core';
import { LocalDatabaseService } from '../../services/local-database.service';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { DisclaimerService } from '../../services/disclaimer.service';

export class FooterLink {
  color: string;
  title: string;
  isPopover: boolean;
  text: string;
}

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnChanges {

  @Input() style: string;

  @Input() version: string;

  footerLinks: FooterLink[];

  tplModal: NzModalRef;
  disclaimerModal: NzModalRef;

  constructor(
    private localDb: LocalDatabaseService,
    private modalService: NzModalService,
    private disclaimerService: DisclaimerService
  ) { }

  ngOnInit() {
    this.footerLinks = this.localDb.getFinePrints();
    this.disclaimerService.setDisclaimer(this.localDb.getDisclaimer()); // set onto service
  }

  isEventLang(lang: string): boolean {
    return this.localDb.getEventLang() === lang;
  }

  ngOnChanges() {
    this.footerLinks = this.localDb.getFinePrints();
    this.disclaimerService.setDisclaimer(this.localDb.getDisclaimer()); // set onto service
  }

  saveDisclaimerAcceptedForEvent(id: string): void {
    this.disclaimerService.saveDisclaimerAcceptedForEvent(id);
  }

  didAcceptDisclaimerForEvent(id: string): boolean {
    return this.disclaimerService.didAcceptDisclaimerForEvent(id);
  }

  openModal(tplTitle: string, tplContent: TemplateRef<{}>): void {
    this.tplModal = this.modalService.create({
      nzTitle: tplTitle,
      nzContent: tplContent,
      nzFooter: null,
      nzClassName: 'footerModal',
      nzClosable: true,
      nzWidth: 900
    });
  }

}
