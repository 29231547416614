import { Component, OnInit } from '@angular/core';
import { SearchUtil, SearchResults } from 'src/app/modules/shared/utils/search.util';
import { Substance, Study, Speciality, TopicType, Topic } from 'src/app/modules/api-client/models';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { MatomoTracker } from 'ngx-matomo';
import { debounce } from 'rxjs/operators';
import { interval } from 'rxjs';
import { LocalDatabaseService } from 'src/app/modules/shared/services/local-database.service';


@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  get searchTerm(): string {
    return this.searchUtil.searchTerm;
  }

  set searchTerm(term: string) {
    this.searchUtil.searchTerm = term;
    this.searchUtil.search();
  }

  results: Observable<SearchResults>;
  speciality: Speciality;
  pipelineTopic: Topic;
  recruitingStudyTopic: Topic;

  constructor(
    private searchUtil: SearchUtil,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private matomoTracker: MatomoTracker,
    private localDb: LocalDatabaseService
  ) {
    this.results = this.searchUtil.results();

    const resultCheck = this.results.pipe(debounce(() => interval(500)));
    if (resultCheck) {
      resultCheck.subscribe(() => {
        if (this.searchUtil.searchTerm) {
          this.matomoTracker.trackEvent('SearchedTerm', 'search.term', this.searchUtil.searchTerm, null);
        }
      });
    }
  }

  ngOnInit() {

    this.activatedRoute.parent.params.subscribe(params => {
      if (params.specialityId) {
        this.speciality = this.localDb.getSpeciality(params.specialityId);
        this.pipelineTopic = this.localDb.getTopicForTopicTypeInSpeciality(TopicType.PIPELINE_OVERVIEW, params.specialityId);
        this.recruitingStudyTopic = this.localDb.getTopicForTopicTypeInSpeciality(TopicType.GLASS_BODY_STUDIES, params.specialityId);
      }
    });
  }

  didSelectSubstance(substance: Substance) {
    // not all data packages have a PIPELINE_OVERVIEW topic
    if (this.pipelineTopic && this.pipelineTopic.id) {
      const url = `specialities/${this.speciality.id}/topics/${this.pipelineTopic.id}/substance/${substance.id}`;
      this.router.navigate([url]);
    } else {
      const basePath = this.activatedRoute.parent.snapshot.url.map(u => u.path);
      const path = basePath.concat(['substance', substance.id]);
      this.router.navigate(path);
    }
  }

  didSelectStudy(study: Study) {

    // not all data packages have a PIPELINE_OVERVIEW topic
    if (this.recruitingStudyTopic && this.pipelineTopic.id) {
      const url = `specialities/${this.speciality.id}/topics/${this.recruitingStudyTopic.id}/study/${study.id}/media/${study.pdfId}`;
      this.router.navigate([url]);

    } else {
      // cant send to substance because user can search Per and find juniPER, not Permetuzab
      if (study.pdfId) {
        const basePath = this.activatedRoute.parent.snapshot.url.map(u => u.path);
        const path = basePath.concat(['study', study.id], ['media', study.pdfId]);
        this.router.navigate(path);
      }
    }


  }

}
