import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-drop-up',
  templateUrl: './drop-up.component.html',
  styleUrls: ['./drop-up.component.scss']
})
export class DropUpComponent implements OnInit {

  @Input()
  title: string;

  @Input()
  isMediaButton: boolean;

  @Input()
  fromAnalisys: boolean;

  @Input()
  options: {
    id: string,
    label: string
  }[];

  @Output()
  didSelectOption = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
  }

  didSelectOptionId(id: string) {
    this.didSelectOption.emit(id);
  }

}
