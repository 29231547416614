import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalDatabaseService } from 'src/app/modules/shared/services/local-database.service';
import { Topic, Speciality, Phase, Substance, Category } from 'src/app/modules/api-client/models';
import { GridStructure } from 'src/app/modules/shared/components/grid/grid.component';

@Component({
  selector: 'app-pipeline-overview',
  templateUrl: './pipeline-overview.component.html',
  styleUrls: ['./pipeline-overview.component.scss']
})
export class PipelineOverviewComponent implements OnInit {

  title: string;
  topic: Topic;
  speciality: Speciality;
  gridStructure: GridStructure;
  filterContentActive: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private localDb: LocalDatabaseService,
    private router: Router
  ) { }

  ngOnInit() {
    this.filterContentActive = false;
    this.activatedRoute.parent.params.subscribe(params => {
      if (params.topicId) {
        this.speciality = this.localDb.getSpeciality(params.specialityId);
        this.topic = this.localDb.getTopic(params.topicId);
      }
    });

    this.loadGrid();
  }

  loadGrid() {
    const phases = this.localDb.getPhases();
    const categories = this.localDb.getCategoriesForSpecialityAccordingToEventConfiguration(this.speciality.id);

    this.gridStructure = {
      x: phases.map(phase => ({ id: phase.id, name: phase.name })),
      y: categories.map(category => {

        const categorySubstances = this.localDb.getVisibleSubstancesForSpecialityAndCategory(
          this.speciality.id, category.id, this.filterContentActive
        );

        const groups = this.groupCategorySubstancesByPhases(phases, categorySubstances);
        return {
          id: category.id,
          name: category.name,
          items: this.substanceGridItemsWithGroupPhases(category, groups)
        };
      }).filter(y => y.items.length > 0).sort((y1, y2) => y1.name.toLowerCase() <= y2.name.toLowerCase() ? -1 : 1)
    };
  }

  didSelectSubstance(item: { yId: string, itemId: string; itemPhaseId: string }) {
    const isEnabled = this.gridStructure.y.find(y => y.id === item.yId).items.find(i => i.id === item.itemId).metadata.hasContent;
    if (isEnabled) {
      this.router.navigate([`specialities/${this.speciality.id}/topics/${this.topic.id}/substance/${item.itemId}`], { queryParams: { phaseId: item.itemPhaseId } });
    }
  }

  didChangeContentAvailabilitySwitch() {
    this.filterContentActive = !this.filterContentActive;
    this.loadGrid();
  }

  private groupCategorySubstancesByPhases(allPhases: Phase[], categorySubstances: { substance: Substance, phases: Phase[] }[])
    : { substance: Substance, phases: Phase[] }[][] {
    const groups: { substance: Substance, phases: Phase[] }[][] = Array.from({ length: allPhases.length });
    categorySubstances.forEach(cs => {
      try {
        //Here take substances and create each substance and add it the same times that phases have this substance in each category.
        cs.phases.forEach((itemPhase, indexPhase) => {
          try {
            let substanceToAdd = JSON.parse(JSON.stringify({ substance: cs.substance, phases: [cs.phases[indexPhase]] }))
            const index = allPhases.indexOf(cs.phases[indexPhase]);
            groups[index] = (groups[index] || []).concat(substanceToAdd);
          } catch (error) { console.error(`Error: check phases of substance ${cs?.substance?.name}`) }
        })
      } catch (error) { console.error(`Error: check categories of substance ${cs?.substance?.name}`) }
    });
    return groups.map(arr => arr || []);
  }

  private substanceGridItemsWithGroupPhases(category: Category, groupPhases: { substance: Substance, phases: Phase[] }[][])
    : { id: string, metadata: any, span: number, offset?: number, endSpan?: number }[] {

    function isEmpty() {
      return groupPhases.map(gp => gp.length).reduce((acc, current) => acc + current, 0) === 0;
    }

    let offset = 0;
    const items: { id: string, metadata: any, span: number, offset?: number, endSpan?: number }[] = [];
    while (!isEmpty()) {
      let columnCount = 0;
      if (offset > 0) {
        // if there is offset left of the previous row
        // we add it as endSpan of the current last item.
        if (items.length > 0) {
          items[items.length - 1].endSpan = offset;
        }
        offset = 0;
      }
      for (let i = 0; i < groupPhases.length; i++) {
        if (columnCount > i) {
          continue;
        }
        if (groupPhases[i].length > 0) {
          let colSpan = 1;
          const cs = groupPhases[i].shift();

          for (let y = i; y < groupPhases.length; y++) {
            if (groupPhases[y].indexOf(cs) !== -1) {
              groupPhases[y].splice(groupPhases[y].indexOf(cs), 1);
              colSpan = i + 1;
            }
          }
          columnCount = columnCount + colSpan;
          items.push({
            id: cs.substance.id,
            metadata: {
              //Select if the substance content the phase in the name. If not, add the phase that is add in this object of substance. Always is phase[0], because we only add one.
              title: (cs.substance.name.toUpperCase()).includes("PHASE") ? cs.substance.name : `${cs.substance.name} - ${cs.phases[0].name}`,
              subtitle: cs.substance.activeSubstance && cs.substance.activeSubstance.length > 0
                ? `(${cs.substance.activeSubstance})` : null,
              hasContent: this.localDb.getSubstanceHasContentForCategory(this.speciality.id, category.id, cs.substance.id),
              phase: cs.phases[0].id //Phase was added to check the phase of the substance for the position in pipeline overview and get correct studies for each phase.
            },
            span: colSpan,
            offset,
            endSpan: 0
          });
          offset = 0;
        } else {
          columnCount = i;
          offset += 1;
        }
      }
    }

    return items;
  }

}
