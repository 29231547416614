import { Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ApiClientService } from '../../api-client/services/api-client.service';

interface CachedImage {
  safeUrl: SafeUrl;
  attachmentId: string;
 }

@Injectable({
  providedIn: 'root'
})
export class CachedImageService {

  private attachments: CachedImage[] = [];

  constructor(
    private sanitizer: DomSanitizer,
    private apiClient: ApiClientService
  ) { }

  public hasAttachment(attachmentId: string): boolean {
    return this.attachments.find(a => a.attachmentId === attachmentId) ? true : false;
  }

  public saveAttachment(attachmentId: string, safeUrl: SafeUrl) {
    if (!this.hasAttachment(attachmentId)) {
      this.attachments.push({
        attachmentId,
        safeUrl
      });
    }
  }

  public getAttachment(attachmentId: string): CachedImage {
    return this.attachments.find(a => a.attachmentId === attachmentId);
  }

  public preloadAttachment(attachmentId: string): void {
    if (attachmentId) {
      this.apiClient.getAttachment(attachmentId).subscribe(blob => {
        const safeUrl = this.sanitizer.bypassSecurityTrustStyle(`url('${URL.createObjectURL(blob)}')`);
        this.saveAttachment(attachmentId, safeUrl);
      });
    }
  }

  public preloadMultipleAttachments(attachmentIds: string[]): Observable<{attachmentId: string, blob: Blob}[]> {
    return this.apiClient.getAttachments(attachmentIds).pipe(
      tap((attachments) => {
        attachments.forEach(attachment => {
          const safeUrl = this.sanitizer.bypassSecurityTrustStyle(`url('${URL.createObjectURL(attachment.blob)}')`);
          this.saveAttachment(attachment.attachmentId, safeUrl);
        });
      })
    );
  }

}
