import { Codable } from '../interfaces/serialization';

export class Video implements Codable<Video> {

    id: string;
    name: string;
    description: string;
    imageAttachmentId: string;
    videoSubtitleId: string;
    videoSoundId: string;
    slideshowId: string;
    position: number;

    deserialize(object: any, id?: any): Video {
        const video = new Video();
        video.id = object.id;
        video.name = object.name;
        video.description = object.description;
        video.imageAttachmentId = object.image_attachment_id;
        video.videoSubtitleId = object.video_subtitle_id;
        video.videoSoundId = object.video_sound_id;
        video.slideshowId = object.slideshow_id;
        video.position = object.position;
        return video;
    }

    serialize(): any {
        return {
            id: this.id,
            name: this.name,
            description: this.description,
            image_attachment_id: this.imageAttachmentId,
            video_subtitle_id: this.videoSubtitleId,
            video_sound_id: this.videoSoundId,
            slideshow_id: this.slideshowId,
            position: this.position
        };
    }

}

