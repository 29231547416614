import { Pipe, PipeTransform } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'authImg',
})
export class AuthImgPipe implements PipeTransform {

  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) { }

  transform(attachmentId: string, defaultImage?: boolean | string, type = 'url'): Observable<SafeUrl> {
    
        const subject = new BehaviorSubject<SafeUrl>(defaultImage);
    
        if (attachmentId) {
          const url = `${environment.api_url}/attachments/${attachmentId}/stream`;
          this.http
          .get(url, { responseType: 'blob' })
          .subscribe((val) => {
            subject.next(this.sanitizeUrl(URL.createObjectURL(val), type));
          });
        }
    
        return subject.asObservable();
        
  }

  private sanitizeUrl(url: string, type: string): SafeUrl {
    if (type === 'style') {
      return this.sanitizer.bypassSecurityTrustStyle(`url('${url}')`);
    }
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

}
