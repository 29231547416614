import { Codable } from '../interfaces/serialization';

export class Attachment implements Codable<Attachment> {

    id: string;
    contentType: string;
    gridFsId: string;
    fileSize: number;
    pages: number;
    thumbnailId: string;
    callFromFront: boolean;
    fileInformation: object;

    deserialize(object: any, id?: any): Attachment {
        const attachment = new Attachment();
        attachment.id = object ? object.id ? object.id : id : id;
        attachment.contentType = object ? object.content_type : null;
        attachment.gridFsId = object ? object.grid_fs_id : null;
        attachment.fileSize = object ? object.file_size : null;
        attachment.pages = object ? object.pages : null;
        attachment.thumbnailId = object ? object.thumbnail_id : null;

        return attachment;
    }

    serialize(): any {
        if (this.fileInformation) {
            return {
                id: this.id,
                content_type: this.contentType,
                grid_fs_id: this.gridFsId,
                file_size: this.fileSize,
                pages: this.pages,
                thumbnail_id: this.thumbnailId,
                callFromFront: this.callFromFront,
                fileInformation: this.fileInformation ? this.fileInformation : null,
            };
        } else {
            return {
                id: this.id,
                content_type: this.contentType,
                grid_fs_id: this.gridFsId,
                file_size: this.fileSize,
                pages: this.pages,
                thumbnail_id: this.thumbnailId,
                callFromFront: this.callFromFront,
            };
        }
    }
}

