import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalDatabaseService } from 'src/app/modules/shared/services/local-database.service';
import { Topic, TopicType } from 'src/app/modules/api-client/models';
import { Location } from '@angular/common';

@Component({
  selector: 'app-topic',
  templateUrl: './topic.component.html',
  styleUrls: ['./topic.component.scss']
})
export class TopicComponent implements OnInit {

  specialityId: string;
  currentTopicId: string;
  tabs: { route: string, topic: Topic }[];
  selectedTabIndex: number | false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private localDb: LocalDatabaseService,
    private router: Router
  ) {
    this.activatedRoute.params.subscribe(params => {
      this.specialityId = params.specialityId;
      this.currentTopicId = params.topicId;

      this.refreshTabs();
    });
  }

  ngOnInit() {

    const defaultTab = this.tabs.find(tab => tab.topic.id === this.currentTopicId);
    // If the current location path is /speciality/id/topics/id we need to redirect
    // to /speciality/id/topics/id/TOPIC_PATH so that the component for the tab is loaded
    const currentUrl = this.location.path();
    const expectedUrl = `/specialities/${this.specialityId}/topics/${this.currentTopicId}`;
    if (currentUrl === expectedUrl && defaultTab) {
      this.router.navigate([defaultTab.route], { replaceUrl: true });
    }

    if (!defaultTab && this.tabs) {
      // if no defaultTab found re-route to the first one from the list
      this.router.navigate([this.tabs[0].route, { replaceUrl: true }]);
    }

    // if user clicked search, a new url will be triggered, so check tabs again
    this.location.onUrlChange(() => {
      this.refreshTabs();
    });
  }

  didClickTab(tab: { route: string, topic: Topic }) {
    this.router.navigate([tab.route]);
  }

  private refreshTabs() {
    this.tabs = this.localDb.getTopicsForSpecialityId(this.specialityId).map(topic => {
      return {
        route: `/specialities/${this.specialityId}/topics/${topic.id}/${this.pathForTopic(topic)}`,
        topic
      };
    });

    const defaultTab = this.tabs.find(tab => tab.topic.id === this.currentTopicId);

    if (this.location.path().includes('search')) {
      this.selectedTabIndex = false;
    } else {
      this.selectedTabIndex = defaultTab ? this.tabs.indexOf(defaultTab) : 0;
    }
  }

  private pathForTopic(topic: Topic): string {
    switch (topic.type) {
      case TopicType.GLASS_BODY: // This is a deprecated topic type, but keep it for compatibility
      case TopicType.GLASS_BODY_STUDIES:
        return 'glassbody_studies';
      case TopicType.GLASS_BODY_CURRENT_DATA:
        return 'glassbody_current_data';
      case TopicType.MODE_OF_ACTIONS:
        return 'mode_of_actions';
      case TopicType.PIPELINE_OVERVIEW:
        return 'pipeline_overview';
      case TopicType.ANALYSIS:
        return 'analysis';
    }
  }

}
