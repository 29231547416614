import { Injectable } from '@angular/core';
import {
  EventFullData,
  Speciality,
  Category,
  Topic,
  Attachment,
  ModeOfAction,
  Phase,
  Substance,
  StudyType,
  Study,
  StudyPhase,
  StudyCategory,
  TopicType,
  Target,
  Video,
  Pdf
} from '../../api-client/models';


@Injectable({
  providedIn: 'root'
})
export class LocalDatabaseService {

  private fullData: EventFullData;
  private LOCAL_STORAGE_KEY = 'full_data';

  constructor() {
    this.loadDataFromStorage();
  }

  getFinePrints() {
    return (this.fullData) ? this.fullData.event.configuration.finePrints : [];
  }

  getDisclaimer() {
    return (this.fullData) ? this.fullData.event.configuration.finePrints.find(fp => fp.isPopover === true) : null;
  }

  getCurrentEventId(): string {
    if (this.fullData && this.fullData.event && this.fullData.event.id) {
      return this.fullData.event.id;
    }
    return '';
  }

  getSelectedEventName(): string {
    if (this.fullData && this.fullData.event && this.fullData.event.name) {
      return this.fullData.event.name;
    }
    return '';
  }

  getSubstancesForAnalysisTopic(topicId: string) {

    const topicSubstances = this.fullData.event.configuration.specialities.find(speciality =>
      speciality.topics.find(topic => topic.id === topicId)
    ).configurations; // find the topicId first

    return topicSubstances.filter(substance => this.fullData.substances.find(s => s.id === substance.substanceId)).map(
      item => this.fullData.substances.find(s => s.id === item.substanceId)
    );

  }

  getEventCovidBannerState(): boolean {
    return this.fullData.event.showCovidBanner;
  }

  getEventLangTranslation(): string {
    const availableTranslations = this.getAvailableTranslations();
    if (this.fullData && this.fullData.event) {
      if (this.fullData.locale && availableTranslations.includes(this.fullData.locale)) {
        return this.fullData.locale;
      } else {
        return availableTranslations[0];
      }
    }
    return null;
  }

  getAvailableTranslations(): string[] {
    return ['en', 'de'];
  }

  getEventLang(): string {
    return this.fullData?.locale || null;
  }

  getEventContentVersion() {
    if (this.fullData && this.fullData.event) {
      return this.fullData.event.contentVersion;
    }
    return null;
  }

  getEventReferencesPdf(): Pdf {
    if (this.fullData && this.fullData.event && this.fullData.event.referencesPdf) {
      return this.fullData.pdfs.find(pdf => pdf.id === this.fullData.event.referencesPdf);
    }
    return null;
  }

  getModeOfActionForTopicId(topicId: string, specialityId: string): ModeOfAction[] {

    // must go within to event configuration specialities and use specialityId to match and get a list of TOPICS
    const speciality = this.fullData.event.configuration.specialities.find(item => item.id === specialityId);

    // from the list of TOPICS, must discover in which topic "we are", for that we use topicId against our list of topics
    const selectedTopic = speciality.topics.find(item => item.id === topicId);

    // from the SELECTED TOPIC get the array of MODE OF ACTIONS
    const modeOfActionsForTopic = selectedTopic.modeOfActions;

    if (modeOfActionsForTopic) {
      return this.fullData.modeOfActions.filter(item => modeOfActionsForTopic.find(id => id.id === item.id));
    }

  }

  /**
   * returns a study category for a given substance
   * if a specialityId is passed in, it will take into account the speciality available categories from event configuration
   */
  getStudyCategoriesForSubstance(
    substanceId: string,
    studyType: string[],
    specialityId?: string,
    filterByShowCurrentData?: boolean,
    phase?: string
  ): Category[] {

    const substance = this.fullData.substances.find(item => item.id === substanceId);
    return substance.studyCategoryDependencies.filter(item => {

      return item.studies.filter(id => {
        const study = this.fullData.studies.find(s => s.id === id);
        if (phase && study.phase ? study.phase == phase : true) {
          return studyType.find(s => s === study.type);
        }
      }).length > 0;

    }).map(item => {
      if (specialityId) {
        const eventCategoriesIds = this.fullData.event.configuration.specialities.find(s => s.id === specialityId)?.categories;
        const eventCategories = this.fullData.categories.filter(c => eventCategoriesIds.includes(c.id));

        // if showCurrentData param is set to true, make sure to return only properties.showCurrentData === true;
        if (filterByShowCurrentData && specialityId) {
          const substanceCategoryConfig = this.fullData.event.configuration.specialities.
            find(s => s.id === specialityId).configurations.find(s => s.substanceId === substanceId);
          const substanceShowCurrentDataForCategory = substanceCategoryConfig?.categories.find(c => c.id === item.categoryId)?.
            properties.showCurrentData === true;
          return substanceShowCurrentDataForCategory ? eventCategories.find(c => c.id === item.categoryId) : null;
        }
        return eventCategories.find(c => c.id === item.categoryId); // when no match is found, undefined will be returned...
      } else {
        return this.fullData.categories.find(c => c.id === item.categoryId);
      }
    }).filter(i => !!i); // ...so we clear eventual empty / undefined elements before returning the categories []

  }

  getTopic(id: string): Topic {
    return this.fullData.topics.find(topic => topic.id === id);
  }

  getTopicForTopicTypeInSpeciality(type: TopicType, specialityId: string): Topic {
    const specialityConfig = this.fullData.event.configuration.specialities.find(s => s.id === specialityId);
    if (specialityConfig) {
      const topics = specialityConfig.topics.map(t => this.fullData.topics.find(topic => topic.id === t.id));
      return topics.find(t => t.type === type);
    }
    return null;
  }

  getSpeciality(id: string): Speciality {
    return this.fullData.specialities.find(speciality => speciality.id === id);
  }

  getModeOfAction(id: string): ModeOfAction {
    return this.fullData.modeOfActions.find(modeOfAction => modeOfAction.id === id);
  }

  getTarget(id: string): Target {
    return this.fullData.targets.find(t => t.id === id);
  }

  getSubstancesDetails(substances: string[]): Substance[] {
    return this.fullData.substances.filter(s => substances.find(id => id === s.id));
  }

  getSubstance(substanceId: string): Substance {
    return this.fullData.substances.find(s => s.id === substanceId);
  }

  getPhase(phaseId: string): Phase {
    return this.fullData.phases.find(s => s.id === phaseId);
  }

  getPdf(pdfId: string): Pdf {
    return this.fullData.pdfs.find(p => p.id === pdfId);
  }

  getVideo(videoId: string): Video {
    return this.fullData.videos.find(v => v.id === videoId);
  }

  getVideosDetails(videos: { videoId: string }[]): Video[] {
    return this.fullData.videos.filter(video => videos.find(f => f.videoId === video.id));
  }

  getTargetsForModeOfAction(specialityId: string, topicId: string, modeOfActionId: string): any {

    const targetsIds = this.fullData.event.configuration.
      specialities.find(s => s.id === specialityId).
      topics.find(t => t.id === topicId).
      modeOfActions.find(m => m.id === modeOfActionId).targets;

    return this.fullData.targets.filter(item => targetsIds.find(id => id === item.id));

  }

  getSpecialities(): Speciality[] {
    return this.fullData.specialities;
  }

  getSpecialitiesForEvent(): Speciality[] { //Filter specialities that should not be visible.
    const specialityConfig = this.fullData.event.configuration.specialities;

    if (specialityConfig) {
      const specialities = this.fullData.specialities.map(t => t.id);
      let specialitiesToReturn = this.fullData.specialities.filter(s => specialities.find(id => id === s.id)).filter(itemSpeciality => itemSpeciality.visible);
      return specialitiesToReturn
    }
  }

  getCategoriesForSpecialityId(specialityId: string): Category[] {
    const speciality = this.fullData.event.configuration.specialities.find(s => s.id === specialityId);
    if (speciality && speciality.categories) {
      return speciality.categories.map(id => (this.fullData.categories || []).find(c => c.id === id));
    }
    return [];
  }

  getIsCategoryVisibleForSpeciality(specialityId: string, categoryId: string): boolean {
    const specialityConfig = this.fullData.event.configuration.specialities.find(s => s.id === specialityId);
    if (specialityConfig) {
      return specialityConfig.categories.indexOf(categoryId) !== -1;
    }
    return false;
  }

  getAttachmentForImageId(imageId: string): Attachment {
    const image = this.fullData.images.find(img => img.id === imageId);
    if (image) {
      return this.fullData.attachments.find(att => att.id === image.attachmentId) || null;
    }
    return null;
  }

  getTopicsForSpecialityId(specialityId: string): Topic[] {
    const specialityConfig = this.fullData.event.configuration.specialities.find(s => s.id === specialityId);
    if (specialityConfig) {
      const topicIds = specialityConfig.topics.map(t => t.id);
      return this.fullData.topics.filter(t => topicIds.find(id => id === t.id)).sort((t1, t2) => t1.position < t2.position ? -1 : 1);
    }
    return [];
  }

  getDefaultTopicIdForSpecialityId(specialityId: string): string {
    const specialityConfig = this.fullData.event.configuration.specialities.find(s => s.id === specialityId);
    if (specialityConfig && specialityConfig.defaultTopicId) {
      return specialityConfig.defaultTopicId;
    }
    const topics = this.getTopicsForSpecialityId(specialityId);
    return topics && topics.length > 0 ? topics[0].id : null;
  }

  getPhases(): Phase[] {
    return this.fullData.phases.sort((p1, p2) => p1.position < p2.position ? -1 : 1);
  }

  getCategoriesForSpecialityAccordingToEventConfiguration(specialityId: string): Category[] {
    const specialityConfig = this.fullData.event.configuration.specialities.find(s => s.id === specialityId);
    if (specialityConfig) {
      const categoryIds = specialityConfig.categories.map(c => c);
      return this.fullData.categories.filter(c => categoryIds.indexOf(c.id) !== -1);
    }
    return [];
  }

  getVisibleSubstancesForSpecialityAndCategory(specialityId: string, categoryId: string, onlyHasContent: boolean): { substance: Substance, phases: Phase[] }[] {
    const substances = this.fullData.substances.filter(substance => {
      return substance.categoriesDependencies.filter(cd => cd.categoryId === categoryId).length > 0;
    });
    const specialityConfig = this.fullData.event.configuration.specialities.find(s => s.id === specialityId);
    return substances.filter(substance => {
      const substanceConfig = (specialityConfig.configurations || []).find(c => c.substanceId === substance.id);
      const config = (substanceConfig && substanceConfig.categories || []).find(c => c.id === categoryId);
      const hasContent = this.getSubstanceHasContentForCategory(specialityId, categoryId, substance.id);
      const isVisible = config && config.properties && config.properties.visible ? config.properties.visible : false;

      return isVisible && ((onlyHasContent && hasContent) || (!onlyHasContent));

    }).map(s => ({ substance: s, phases: this.getPhasesForSubstanceInCategory(categoryId, s.id) }));
  }

  getPhasesForSubstanceInCategory(categoryId: string, substanceId: string): Phase[] {
    const substance = this.fullData.substances.find(s => s.id === substanceId);
    if (substance) {
      const categoryDependency = substance.categoriesDependencies.find(cd => cd.categoryId === categoryId);
      const phasesIds = categoryDependency && categoryDependency.phases ? categoryDependency.phases : [];
      return this.fullData.phases.filter(p => phasesIds.indexOf(p.id) !== -1).sort((p1, p2) => p1.position < p2.position ? -1 : 1);
    }
    return [];
  }

  getSubstanceHasContentForCategory(specialityId: string, categoryId: string, substanceId: string) {
    const specialityConfig = this.fullData.event.configuration.specialities.find(s => s.id === specialityId);
    if (specialityConfig) {
      const substanceConfig = specialityConfig.configurations.find(c => c.substanceId === substanceId);
      const config = substanceConfig ? substanceConfig.categories.find(c => c.id === categoryId) : null;
      if (config) {
        if (!(config && config.properties && config.properties.visible)) {
          return false;
        }
        if (config && config.properties && config.properties.showDescription) {
          return true;
        }
        if (!(config && config.properties && config.properties.showCurrentData) ||
          !(config && config.properties && config.properties.showStudies)) {
          return false;
        }
      }

    }
    return true;
  }

  getCategoriesForSubstanceWithStudyOfTypes(studyTypes: StudyType[], substanceId): Category[] {
    const substance = this.fullData.substances.find(s => s.id === substanceId);
    if (substance) {
      return substance.studyCategoryDependencies.filter(scd => {
        return scd.studies.map(sId => this.fullData.studies.find(s => s.id === sId))
          .find(s => studyTypes.indexOf(s.type));
      }).map(scd => this.fullData.categories.find(c => c.id === scd.categoryId));
    }
    return [];
  }

  getStudyPhasesForCategory(categoryId: string): StudyPhase[] {
    const category = this.fullData.categories.find(c => c.id === categoryId);
    if (category) {
      return category.studiesPhasesDependencies.map(phaseId => {
        return this.fullData.studiesPhases.find(sp => sp.id === phaseId);
      }).sort((sp1, sp2) => sp1.position < sp2.position ? -1 : 1);
    }
    return [];
  }

  getAllStudiesFor(categoryId: string, substanceId: string, studyTypes: StudyType[]): Study[] {
    let allStudies: Study[] = [];
    if (substanceId) {
      const substance = this.fullData.substances.find(s => s.id === substanceId);
      substance.studyCategoryDependencies.filter(scd => scd.categoryId === categoryId)
        .forEach(scd => {
          const studies = scd.studies.map(sId => this.fullData.studies.find(s => s.id === sId));
          allStudies = allStudies.concat(studies);
        });
    } else {
      this.fullData.substances
        .filter(s => s.studyCategoryDependencies.find(scd => scd.categoryId === categoryId))
        .forEach(substance => {
          const studyIds = substance.studyCategoryDependencies
            .filter(scd => scd.categoryId === categoryId)
            .map(scd => scd.studies).reduce((prev, curr) => prev.concat(curr), []);
          const studies = studyIds.map(sId => this.fullData.studies.find(s => s.id === sId)).filter(s => !!s);
          allStudies = allStudies.concat(studies);
        });
    }
    return allStudies.filter(s => studyTypes.indexOf(s.type) !== -1);
  }


  // getAllStudies(): Study[] {
  //   let allStudiesToWork = []
  //   this.fullData.event.configuration.specialities.map(itemSpeciality => {
  //     if (itemSpeciality.id != "f7795efe-6c7e-47a9-ba48-bace7e9f258c" && itemSpeciality.id != "8d2cc697-1865-483b-9da1-6fba0527f415") {

  //       itemSpeciality.configurations.map(itemSubstance => {
  //         let substanceToWork = this.fullData.substances.find(s => s.id === itemSubstance.substanceId);
  //         substanceToWork.studyCategoryDependencies.map(itemCategories => {
  //           itemCategories.studies.forEach(itemStudy => {
  //             if (!allStudiesToWork.includes(itemStudy)) { allStudiesToWork.push(itemStudy) }
  //           })
  //         })
  //       })
  //     }
  //   })
  //   return allStudiesToWork.map(itemStudyCheck => { return this.fullData.studies.find(s => s.id === itemStudyCheck) });
  // }

  // getAllSubstances(): Substance[] {
  //   let allSubstancesToWork = []
  //   this.fullData.event.configuration.specialities.map(itemSpeciality => {
  //     itemSpeciality.configurations.map(itemSubstance => {
  //       if (!allSubstancesToWork.includes(itemSubstance.substanceId)) { allSubstancesToWork.push(itemSubstance.substanceId) }
  //     })
  //   })
  //   return allSubstancesToWork.map(itemSubstanceCheck => { return this.fullData.substances.find(s => s.id === itemSubstanceCheck) });
  // }

  getCategory(categoryId: string): Category {
    return this.fullData.categories.find(c => c.id === categoryId);
  }

  getStudyCategory(studyCategoryId: string): StudyCategory {
    return this.fullData.studiesCategories.find(sc => sc.id === studyCategoryId);
  }

  getStudyPhase(studyPhaseId: string): StudyPhase {
    return this.fullData.studiesPhases.find(sp => sp.id === studyPhaseId);
  }

  getStudiesForStudyCategoryId(studyCategoryId: string, studyTypes: StudyType[], substanceId?: string): Study[] {
    const studies = this.fullData.studies
      .filter(study => studyTypes.indexOf(study.type) !== -1 && study.studiesCategories.find(sc => sc.id === studyCategoryId));

    if (substanceId) {
      return studies.filter(study => {
        const substance = this.fullData.substances.find(s => s.id === substanceId);
        const substanceStudyIds = substance.studyCategoryDependencies.map(scd => scd.studies).reduce((prev, curr) => prev.concat(curr), []);
        return substanceStudyIds.indexOf(study.id) !== -1;
      });
    }
    return studies;
  }

  getAllSubstancesInCategoryWithStudiesOfTypes(categoryId: string, studyTypes: StudyType[]): Substance[] {
    return this.fullData.substances
      .filter(substance => {
        return substance.studyCategoryDependencies
          .find(scd => {
            const studies = this.fullData.studies.filter(study => scd.studies.indexOf(study.id) !== -1)
              .filter(study => studyTypes.indexOf(study.type) !== -1);
            return scd.categoryId === categoryId && studies.length > 0;
          }) !== undefined;
      });
  }

  getStudyForId(studyId: string): Study {
    return this.fullData.studies.find(s => s.id === studyId);
  }

  getSubstancesForEventGivenSpeciality(specialityId: string): Substance[] {
    const substanceIdsForSpeciality = this.fullData.event.configuration.specialities.
      find(s => s.id === specialityId).configurations.map(cs => {
        //Change to avoid substance also when some value is not available.
        let checkSubstance = cs.categories.find(itemCategory => {
          return !Object.values(itemCategory.properties).includes(false)
        })
        if (checkSubstance) { return cs.substanceId } else { return null }
      });
    return this.fullData.substances.filter(s => substanceIdsForSpeciality.includes(s.id));
  }

  searchSubstancesWithTerm(term: string, specialityId?: string): Substance[] {

    let allSubstances: Substance[];
    if (specialityId) {
      allSubstances = this.getSubstancesForEventGivenSpeciality(specialityId);
    }

    return (allSubstances || this.fullData.substances).filter(substance => {
      const targets = this.fullData.targets.filter(target => target.substances.indexOf(substance.id) !== -1);
      return !!substance.description &&
        (this.stringContains(substance.name, term) || targets.find(t => this.stringContains(t.name, term)) ||
          this.stringContains(substance.gdcNumber, term) || this.stringContains(substance.rgNumber, term) ||
          this.stringContains(substance.roNumber, term) || this.stringContains(substance.activeSubstance, term));
    });
  }

  searchStudiesWithTerm(term: string, specialityId?: string): Study[] {

    const allStudies: Study[] = [];
    if (specialityId) {
      const studyCategoriesForEvent: Category[] = this.getCategoriesForSpecialityAccordingToEventConfiguration(specialityId);
      studyCategoriesForEvent.forEach(c => {
        const substancesForCategory = this.getAllSubstancesInCategoryWithStudiesOfTypes(c.id, [
          StudyType.RECRUITING_STUDY, StudyType.NONE_RECRUITING_STUDY
        ]);
        substancesForCategory.forEach(s => {
          const studiesForSubstance = this.getAllStudiesFor(c.id, s.id, [StudyType.RECRUITING_STUDY, StudyType.NONE_RECRUITING_STUDY]);
          allStudies.push(...studiesForSubstance);
        });
      });
    }

    return (allStudies || this.fullData.studies).filter(study => {
      return (study.type === StudyType.RECRUITING_STUDY || study.type === StudyType.NONE_RECRUITING_STUDY) &&
        !!study.pdfId && this.stringContains(study.name, term);
    });
  }

  getAttachmentWithId(attachmentId: string): Attachment {
    return this.fullData.attachments.find(a => a.id === attachmentId);
  }

  setFullData(fullData: EventFullData) {
    this.fullData = fullData;
    try {
      this.getLocalStorage().setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(fullData.serialize()));
    } catch (e) {
      console.error(e);
    }
  }

  removeFullData() {
    this.getLocalStorage().removeItem(this.LOCAL_STORAGE_KEY);
  }

  getLocaleInformation() {
    try {
      let locale = this.getLocalStorage().full_data
      if (locale) {
        locale = JSON.parse(locale)
      } else { locale = 'en' }
      return locale.locale
    } catch (error) { console.log("error", error); return null }
  }

  private loadDataFromStorage() {
    const object = this.getLocalStorage().getItem(this.LOCAL_STORAGE_KEY);
    this.fullData = object ? (new EventFullData()).deserialize(JSON.parse(object)) : null;
  }

  private getLocalStorage() {
    return localStorage;
  }

  private stringContains(testString: string, term: string): boolean {
    return !!testString ? !!testString.match(new RegExp(term, 'i')) : false;
  }

}
