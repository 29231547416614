import { Component, OnInit } from '@angular/core';
import { ModeOfAction, Target, Speciality, Topic, Substance } from 'src/app/modules/api-client/models';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalDatabaseService } from 'src/app/modules/shared/services/local-database.service';

@Component({
  selector: 'app-groupAssets',
  templateUrl: './groupAssets.component.html',
  styleUrls: ['./groupAssets.component.scss']
})
export class GroupAssetsComponent implements OnInit {

  modeOfAction: ModeOfAction;
  targets: Target[];
  speciality: Speciality;
  topic: Topic;
  pageReady: boolean;

  constructor(
    public activatedRoute: ActivatedRoute,
    private localDb: LocalDatabaseService,
    public router: Router
  ) {
    try {
      this.pageReady = false;

      // must get / set from parent routing
      this.activatedRoute.parent.params.subscribe(params => {
        this.topic = this.localDb.getTopic(params.topicId);
        this.speciality = this.localDb.getSpeciality(params.specialityId);
      });

      this.activatedRoute.params.subscribe(params => {
        try {
          this.modeOfAction = this.localDb.getModeOfAction(params.modeOfActionId);
          this.targets = this.localDb.getTargetsForModeOfAction(this.speciality.id, this.topic.id, this.modeOfAction.id);
          this.activatedRoute.queryParams.subscribe(query => {
            if (query?.foundationMore) {
              if (this.targets.length == 1 ? this.targets[0].pdfId : null) {
                //This part is used to connect directly with this route if is Foundation More and have a pdf.
                const parentBasePath = this.activatedRoute.parent.snapshot.url.map(u => u.path);
                const path = parentBasePath.concat(['media', this.targets[0].pdfId]);
                this.router.navigate(path);
              }
            } else {
              if (this.targets.length === 1) { //Go directly to the target if only have one.
                this.toTargetDetails(this.targets[0]);
              } else { //Show targets if they are more than one.
                this.pageReady = true;
              }
            }
          })
        } catch (error) { console.error("Error: It could not be possible redirect to new url"); this.navigateToThisUrl() }
      });
    } catch (error) { console.error("Error: It could not be possible redirect to new url"); this.navigateToThisUrl() }
  }

  ngOnInit() { }

  navigateToThisUrl() { //In case of issue, redirect to original direction.
    try {
      this.router.navigate(["specialities", this.speciality.id, "topics", this.topic.id, "analysis"], { replaceUrl: true });
    } catch (error) { this.router.navigate(["404"]); }
  }

  toTargetDetails(target: Target) {
    try {
      let url = []
      if (target.substances.length == 1) { //Go directly to the asset if only have one.
        url = ["specialities", this.speciality.id, "topics", this.topic.id, "mode_of_actions", this.modeOfAction.id, "groupAssets", target.id, "asset", target.substances[0]]
      } else { //Show the group of assets
        url = ["specialities", this.speciality.id, "topics", this.topic.id, "mode_of_actions", this.modeOfAction.id, "groupAssets", target.id]
      } this.router.navigate(url);
    } catch (error) { throw error }
  }

  getAsset(asset) {
    return (this.localDb.getSubstance(asset)).name || ""
  }

  openAsset(group: Target, asset: string) { //Go to the asset selected.
    try {
      const url = ["specialities", this.speciality.id, "topics", this.topic.id, "mode_of_actions", this.modeOfAction.id, "groupAssets", group.id, "asset", asset];
      this.router.navigate(url);
    } catch (error) { console.error("Error: It could not be possible to open the asset"); }
  }
}
