import { Pipe, PipeTransform } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CachedImageService } from '../../shared/services/cached-image.service';

@Pipe({
  name: 'authCachedImg',
})
export class AuthCachedImg implements PipeTransform {

  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private cachedImageService: CachedImageService
  ) {}

  transform(attachmentId: string, defaultImage?: boolean|string, type = 'url'): Observable<SafeUrl> {
    const subject = new BehaviorSubject<SafeUrl>(defaultImage);

    // we have to check with the cachedImageService if the attachmentId exists
    // if so return its safeUrl containing the blob://
    if (this.cachedImageService.hasAttachment(attachmentId)) {
      const cachedAttachment = this.cachedImageService.getAttachment(attachmentId);
      subject.next(cachedAttachment.safeUrl);
    } else {
      // otherwise we have to make a new request
      // and immediatly save it to our cached attachments
      if (attachmentId) {
        const url = `${environment.api_url}/attachments/${attachmentId}/stream`;
        
        this.http
        .get(url, { responseType: 'blob' })
        .subscribe((val) => {
          const safeUrl = this.sanitizeUrl(URL.createObjectURL(val), type);
          this.cachedImageService.saveAttachment(attachmentId, safeUrl);
          subject.next(safeUrl);
        });
        
      }
    }


    return subject.asObservable();

  }

  private sanitizeUrl(url: string, type: string): SafeUrl {
    if (type === 'style') {
      return this.sanitizer.bypassSecurityTrustStyle(`url('${url}')`);
    }
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

}
