import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-sso-error',
  templateUrl: './sso-error.component.html',
  styleUrls: ['./sso-error.component.scss']
})
export class SsoErrorComponent implements OnInit {

  errorReason: Observable<string>;

  constructor(
    private activatedRoute: ActivatedRoute
  ) {
    this.errorReason = this.activatedRoute.queryParams
      .pipe(map(params => params.reason ? params.reason : 'unknown'));
  }

  ngOnInit() {
  }

}
