import { Codable } from '../interfaces/serialization';

export enum SpecialityType {
    ONCOLOGY = 'ONCOLOGY',
    DERMATOLOGY = 'DERMATOLOGY',
    GASTROENTEROLOGY = 'GASTROENTEROLOGY',
    RHEUMATOLOGY = 'RHEUMATOLOGY',
    PULMONOLOGY = 'PULMONOLOGY',
    HEMOPHILIA = 'HEMOPHILIA',
    RARE_DISEASES = 'RARE_DISEASES',
    OPHTHALMOLOGY = 'OPHTHALMOLOGY',
    NEUROSCIENCE = 'NEUROSCIENCE',
    INFECTOLOGY = 'INFECTOLOGY',
    TUMOR_PROFILING = 'TUMOR_PROFILING',
    HEMATOLOGY = 'HEMATOLOGY'
}

export class Speciality implements Codable<Speciality> {

    id: string;
    name: string;
    description: string;
    position: number;
    imageId: string;
    type: SpecialityType;
    topics: string[];
    categories: string[];
    recruitingStudiesSwitchLabel: string;
    visible: boolean;
    hasContent: boolean;
    message: string;

    deserialize(object: any, id?: any): Speciality {
        const speciality = new Speciality();
        speciality.id = object.id || id;
        speciality.name = object.name;
        speciality.description = object.description;
        speciality.position = object.position;
        speciality.imageId = object.imageId;
        speciality.type = object.type;
        speciality.topics = object.topics;
        speciality.categories = object.categories;
        speciality.recruitingStudiesSwitchLabel = object.recruiting_studies_switch_label;
        speciality.visible = object.visible;
        speciality.hasContent = object.hasContent;
        speciality.message = object.message;
        return speciality;
    }

    serialize(): object {
        let topicsToAdd = []
        if (Array.isArray(this.topics)) { this.topics.forEach(item => { if (typeof (item) == "string" && item) { topicsToAdd.push(item) } }) }
        let categoriesToAdd = []
        if (Array.isArray(this.categories)) { this.categories.forEach(item => { if (typeof (item) == "string" && item) { categoriesToAdd.push(item) } }) }
        return {
            id: this.id,
            name: this.name,
            description: typeof (this.description) == "string" ? this.description : "",
            position: typeof (this.position) == "number" ? this.position >= 0 ? this.position : 0 : 0,
            image_id: this.imageId ? this.imageId.length ? this.imageId : null : null,
            type: typeof (this.type) == "string" ? this.type : "",
            topics: topicsToAdd,
            categories: categoriesToAdd,
            recruiting_studies_switch_label: typeof (this.recruitingStudiesSwitchLabel) == "string" ? this.recruitingStudiesSwitchLabel : "",
            visible: typeof (this.visible) == "boolean" ? this.visible || false : false,
            hasContent: typeof (this.hasContent) == "boolean" ? this.hasContent || false : false,
            message: typeof (this.message) == "string" ? this.message : "",
        };
    }
}

