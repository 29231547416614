import { fromUnixTime } from 'date-fns';

export class JWTToken {
    sub: string;
    role: string;
    iat: Date;
    exp: Date;
    refreshToken: string;

    static deserialize(object: any): JWTToken {
        const token = new JWTToken();
        token.sub = object.sub;
        token.role = object.role;
        token.iat = fromUnixTime(object.iat);
        token.exp = fromUnixTime(object.exp);
        token.refreshToken = object.refresh_token;
        return token;
    }

    isExpired(): boolean {
        return this.exp.getTime() < new Date().getTime();
    }
}
