import { Codable } from '../interfaces/serialization';

export enum PdfTag {
    CURRENT_DATA = 'CURRENT_DATA',
    STUDY = 'STUDY',
    CHAPTER_PDF = 'CHAPTER_PDF',
    VIDEO_SLIDESHOW = 'VIDEO_SLIDESHOW',
    REFERENCES = 'REFERENCES',
    TOPIC = 'TOPIC'
}

export class Pdf implements Codable<Pdf> {

    id: string;
    name: string;
    searchDescription: string;
    position: number;
    tag: PdfTag;
    pages: number;
    attachmentId: string;
    thumbnailAttachmentId: string;

    deserialize(object: any, id?: any): Pdf {
        const pdf = new Pdf();
        pdf.id = object.id || id;
        pdf.name = object.name;
        pdf.searchDescription = object.search_description;
        pdf.tag = object.tag;
        pdf.position = object.position;
        pdf.pages = object.pages;
        pdf.attachmentId = object.attachment_id;
        pdf.thumbnailAttachmentId = object.thumbnail_attachment_id;
        return pdf;
    }

    serialize(): any {
        return {
            id: this.id,
            name: this.name,
            search_description: this.searchDescription,
            position: this.position,
            tag: this.tag,
            pages: this.pages,
            attachment_id: this.attachmentId,
            thumbnail_attachment_id: this.thumbnailAttachmentId
        };
    }

}

