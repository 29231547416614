<div class="custom-container">
  <div class="router-wrapper">

    <!-- details "page" -->
    <div nz-row>

      <div nz-col [nzXl]="{ span: 20, offset : 2 }" [nzLg]="{ span: 20, offset : 2 }" [nzMd]="{ span: 20, offset : 2 }" >
        <div class="moa-listing-details">

          <ng-container [ngSwitch]="type">
            <app-video *ngSwitchCase="'video'" [attachmentId]="attachmentId"></app-video>
            <app-pdf *ngSwitchCase="'pdf'" [attachmentId]="attachmentId"></app-pdf>
          </ng-container>

        </div>
      </div>
    </div>
    <!-- details "page" -->
  </div>
</div>
