<div nz-row>
  <div nz-col [nzXl]="{ span: 12, offset : 6 }" [nzSm]="{ span: 20, offset : 2 }">
    <div class="roche-title-section-wrapper roche-mt-50 roche-mb-50" style="display:block;justify-content: center;">
      <h1 class="roche-text-center roche-title-section roche-mb-20 analysis-title" style="cursor: pointer;"
        (click)="didSelect()">{{topic.name}}
      </h1>
      <p [innerHTML]="topic.description"></p>
    </div>
  </div>
</div>

<ng-container *ngIf="speciality.type === 'TUMOR_PROFILING'">
  <h1 class="roche-text-center roche-title-section roche-mb-20">{{ 'AnalysisScreen.GenomicProfilingFor' | translate}}
  </h1>
  <div nz-row class="roche-pb-50">
    <div nz-col [nzXXl]="{ span: 16, offset : 4 }" [nzXl]="{ span: 20, offset : 2 }" [nzSm]="{ span: 22, offset : 2 }"
      [nzMd]="{ span: 24, offset : 0 }">
      <div class="ana-items" style="align-items: flex-start;">
        <div *ngFor="let modeOfAction of getTypeOfAssets(modesOfAction)">
          <div class="ana-item">
            <a (click)="toModeOfActionTargets(modeOfAction)">
              <span *ngIf="modeOfAction.imageId; else noImage" class="ana-image"
                [style.backgroundImage]="modeOfAction.imageId | toAttachmentId | authImg:false:'style' | async"></span>
              <ng-template #noImage>
                <span class="ana-image" [style.backgroundImage]="'url(/assets/img/no-image.png)'"></span>
              </ng-template>
              <span class="ana-title roche-pill">{{modeOfAction.name}}</span>
            </a>
          </div>
        </div>
      </div>

      <div nz-row>
        <div nz-col [nzXl]="{ span: 16, offset : 4 }" [nzSm]="{ span: 20, offset : 2 }">
          <div class="ana-items" style="width:100%">
            <div class="ana-item-foundationMore">
              <a (click)="toModeOfActionTargets(null,true)">
                <span *ngIf="getImageFM(); else noImage" class="ana-image"
                  [style.backgroundImage]="getImageFM() | toAttachmentId | authImg:false:'style' | async"></span>
                <ng-template #noImage>
                  <span class="ana-image" [style.backgroundImage]="'url(/assets/img/no-image.png)'"></span>
                </ng-template>
                <span class="ana-title roche-pill">Foundation More Added-value services supporting customers</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>


<!-- <div nz-row>
  <div nz-col [nzXl]="{ span: 12, offset : 6 }" [nzSm]="{ span: 20, offset : 2 }">
    <div class="roche-title-section-wrapper roche-mt-50 roche-mb-50">
      <h1 class="roche-text-center roche-title-section roche-mb-50">{{topic.name}}</h1>
      <p [innerHTML]="topic.description"></p>
    </div>
  </div>
</div>

<ng-container *ngIf="speciality.type !== 'RARE_DISEASES'">
<h1 class="roche-text-center roche-title-section roche-mb-20">{{ 'AnalysisScreen.GenomicProfilingFor' | translate}}</h1>
<div nz-row class="roche-pb-50">
  <div nz-col [nzXXl]="{ span: 16, offset : 4 }" [nzXl]="{ span: 20, offset : 2 }" [nzSm]="{ span: 22, offset : 2 }" [nzMd]="{ span: 24, offset : 0 }">
    <div class="ana-items">
      <div *ngFor="let substance of analysisSubstances">
            <div class="ana-item">
              <a (click)="toSubstanceDetails($event, substance)">
                <span class="ana-activesubstance">{{substance.activeSubstance}}</span>
                <span *ngIf="substance.images[0]; else noImage" class="ana-image" [style.backgroundImage]="substance.images[0] | toAttachmentId | authImg:false:'style' | async"></span>
                <ng-template #noImage>
                  <span class="ana-image" [style.backgroundImage]="'url(/assets/img/no-image.png)'"></span>
                </ng-template>
                <span class="ana-title roche-pill">{{substance.name}}</span>
              </a>
          </div>
      </div>
    </div>
  </div>
</div>
</ng-container> -->