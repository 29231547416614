import { Codable } from '../interfaces/serialization';

export class Category implements Codable<Category> {

    id: string;
    name: string;
    position: number;
    studiesPhasesDependencies: string[];
    imageId: string;

    deserialize(object: any, id?: any): Category {
        const category = new Category();
        category.id = object.id || id;
        category.name = object.name;
        category.position = object.position;
        category.studiesPhasesDependencies = object.studies_phases_dependencies;
        category.imageId = object.image_id;
        return category;
    }

    serialize(): any {
        let therapyLinesToAdd = []
        this.studiesPhasesDependencies.forEach(item => { if (typeof (item) == "string") { therapyLinesToAdd.push(item) } })
        return {
            id: this.id,
            name: this.name,
            position: typeof (this.position) == "number" ? this.position : null,
            studies_phases_dependencies: therapyLinesToAdd,
            image_id: this.imageId,
        };
    }

}

