<ng-container *ngIf="pageReady">
<div nz-row>
  <div nz-col nzSpan="12" nzOffset="6">
    <div class="roche-title-section-wrapper roche-mt-50 roche-mb-50">
      <h1 class="roche-text-center roche-title-section">{{modeOfAction.name}}</h1>
    </div>
  </div>
</div>

<div nz-row class="roche-pb-50">

  <div nz-col [nzXl]="{ span: 16, offset : 4 }" [nzSm]="{ span: 22, offset : 1 }">
    <div nz-row [nzGutter]="{ xs: 8, sm: 8, md: 16, lg: 16, xl: 16, xxl: 16 }" class="moa-listing-details">


      <!-- iterator -->
      <div *ngFor="let target of targets" nz-col [nzXl]="{ span: 8}" [nzMd]="{ span: 8 }">
        <a (click)="toTargetDetails(target)">
          <div class="moa-item-details">
            <div class="moa-item-image" [style.backgroundImage]="target.imageId | toAttachmentId | authImg:false:'style' | async">
            </div>
            <div class="moa-item-name">
              <span class="roche-pill">{{target.name}}</span>
            </div>
          </div>
        </a>
      </div>
      <!-- /iterator -->


    </div>
  </div>
</div>
</ng-container>
