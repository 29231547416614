import { Component, OnInit } from '@angular/core';
import { GridStructure } from 'src/app/modules/shared/components/grid/grid.component';
import { StudiesGridUtil } from 'src/app/modules/shared/utils/studies-grid.util';
import { StudyType, Category, TopicType, Substance, Study, Topic } from 'src/app/modules/api-client/models';
import { ActivatedRoute, Router } from '@angular/router';
import { flatMap, map } from 'rxjs/operators';
import { LocalDatabaseService } from 'src/app/modules/shared/services/local-database.service';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { BreadCrumbsService } from 'src/app/modules/shared/services/breadcrumbs.service';

@Component({
  selector: 'app-studies',
  templateUrl: './studies.component.html',
  styleUrls: ['./studies.component.scss']
})
export class StudiesComponent implements OnInit {

  gridStructure: GridStructure;
  categories: Category[];
  substances: Substance[];
  filterSubstanceId: string;
  showingCurrentData = false;
  noContentMessage = '';
  showSwitchButton: boolean;
  switchValue: any;
  study: Study;
  recrutingStudySwitchLabel: string;
  hasContent: boolean;

  allowScreenSwitch = true;
  allowSubstanceFilter = true;
  showOnlyRecruitingStudies = false;

  get categoryOptions(): Category[] {
    // In the dropdown we don't show the current selected category
    return (this.categories || []).filter(c => c.id !== this.studiesGridUtil.categoryId).
      sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
  }

  get currentCategory(): Category {
    return (this.categories || []).find(c => c.id === this.studiesGridUtil.categoryId);
  }

  constructor(
    private localDb: LocalDatabaseService,
    public studiesGridUtil: StudiesGridUtil,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    public location: Location,
    public breadcrumbs: BreadCrumbsService
  ) { }

  ngOnInit() {

    this.activatedRoute.url.pipe(
      flatMap(urlSegments => {
        if (urlSegments.map(u => u.path).indexOf('current_data') !== -1) {
          this.studiesGridUtil.studyTypes = [StudyType.CURRENT_DATA];
          this.showingCurrentData = true;
          this.noContentMessage = this.translate.instant('StudiesScreen.CurrentDataNoResults');
        } else {
          if (this.showOnlyRecruitingStudies) {
            this.studiesGridUtil.studyTypes = [StudyType.RECRUITING_STUDY];
          } else {
            this.studiesGridUtil.studyTypes = [StudyType.RECRUITING_STUDY, StudyType.NONE_RECRUITING_STUDY];
          }
          this.showingCurrentData = false;
          this.noContentMessage = this.translate.instant('StudiesScreen.AllStudiesNoResults');
        }
        return this.activatedRoute.parent.params;
      }),
      flatMap(params => {
        this.studiesGridUtil.specialityId = params.specialityId;
        return this.activatedRoute.params;
      }),
      flatMap(params => {
        this.studiesGridUtil.categoryId = params.categoryId;
        return this.activatedRoute.queryParams;
      }),
      map(query => {
        this.studiesGridUtil.substanceId = query.substanceId;
        this.filterSubstanceId = query.substanceId;
        this.allowScreenSwitch = !query.substanceId;
        this.allowSubstanceFilter = !query.substanceId;
        return query.phaseId
      })
    ).subscribe((phaseId) => {
      this.studiesGridUtil.calculateGridStructure(phaseId);
      this.categories = this.studiesGridUtil.getCategoriesForStudies();
    });

    this.studiesGridUtil.gridStructure().subscribe(structure => {
      this.gridStructure = structure;
    });

    this.studiesGridUtil.substances().subscribe(substances => {
      this.substances = substances;
    });

    this.showSwitchButton = this.hasSwitchButton();

    this.recrutingStudySwitchLabel = this.localDb.getSpeciality(this.studiesGridUtil.specialityId).recruitingStudiesSwitchLabel;
  }

  didSelectGridItem(item: { yId: string, itemId: string, hasContent: boolean }) { //just check if pdf is available and must be showed.
    try {
      const study = this.localDb.getStudyForId(item.itemId);
      if (study.hasContent) {
        const checkHasContent = study.pdfId
        if (checkHasContent) {
          const parentBasePath = this.activatedRoute.parent.snapshot.url.map(u => u.path);
          const currentBasePath = this.activatedRoute.snapshot.url.map(u => u.path);
          const absoluteUrl = [...parentBasePath, ...currentBasePath];
          const path = absoluteUrl.concat(['media', study.pdfId]);

          // preserve queryParams to be able to go back to state if possible
          this.activatedRoute.queryParams.subscribe(queryParams => {
            this.router.navigate(path, { queryParams });
          });
        }
        else {
          console.log("No content available")
        }
      }
    } catch (error) { console.error("Error: no pdf available") }
  }


  didSelectCategory(category: Category) {

    // update the breadcrumbs
    const breadCrumbKey = this.showingCurrentData ? 'current_data' : 'recruiting_studies';
    this.breadcrumbs.updateBreadCrumbKey(breadCrumbKey, category.id);

    this.studiesGridUtil.substanceId = null;
    this.studiesGridUtil.categoryId = category.id;

    // when changing category, preserve studies filtering
    if (this.showOnlyRecruitingStudies === true) {
      this.studiesGridUtil.studyTypes = [StudyType.RECRUITING_STUDY];
    } else {
      this.studiesGridUtil.studyTypes = [StudyType.RECRUITING_STUDY, StudyType.NONE_RECRUITING_STUDY];
    }

    this.studiesGridUtil.calculateGridStructure();
  }

  didPressSwitchToRelatedScreen() {
    let topic: Topic;
    let goToPath: string;
    if (this.showingCurrentData) {
      topic = this.localDb.getTopicForTopicTypeInSpeciality(TopicType.GLASS_BODY_STUDIES, this.studiesGridUtil.specialityId);
      goToPath = 'recruiting_studies';
    } else {
      topic = this.localDb.getTopicForTopicTypeInSpeciality(TopicType.GLASS_BODY_CURRENT_DATA, this.studiesGridUtil.specialityId);
      goToPath = 'current_data';
    }
    const paths = ['/specialities', this.studiesGridUtil.specialityId, 'topics',
      topic.id, goToPath, this.studiesGridUtil.categoryId];
    this.router.navigate(paths);
  }

  didChangeSubstanceFilter(substanceId: string) {
    this.studiesGridUtil.substanceId = substanceId;
    this.studiesGridUtil.calculateGridStructure();
  }

  didChangeStudiesSwitch(showOnlyRecruitingStudies: boolean) {
    this.showOnlyRecruitingStudies = !this.showOnlyRecruitingStudies;

    if (this.showOnlyRecruitingStudies) {
      this.studiesGridUtil.studyTypes = [StudyType.RECRUITING_STUDY];
      this.noContentMessage = this.translate.instant('StudiesScreen.RecruitingStudiesNoResults');
    } else {
      this.studiesGridUtil.studyTypes = [StudyType.RECRUITING_STUDY, StudyType.NONE_RECRUITING_STUDY];
      this.noContentMessage = this.translate.instant('StudiesScreen.AllStudiesNoResults');
    }
    this.studiesGridUtil.calculateGridStructure();
  }

  // will check if there is a sibling topic to show
  hasSwitchButton() {
    let switchTopic: Topic;
    // for the sake of doing a single operation
    if (this.showingCurrentData) {
      switchTopic = this.localDb.getTopicForTopicTypeInSpeciality(TopicType.GLASS_BODY_STUDIES, this.studiesGridUtil.specialityId);
    } else {
      switchTopic = this.localDb.getTopicForTopicTypeInSpeciality(TopicType.GLASS_BODY_CURRENT_DATA, this.studiesGridUtil.specialityId);
    }
    return switchTopic !== undefined ? true : false;
  }

}
