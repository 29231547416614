import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { LocalDatabaseService } from '../../services/local-database.service';
import { Speciality, Topic, ModeOfAction, Target, Substance, Category, Phase } from 'src/app/modules/api-client/models';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {

  speciality: Speciality;
  topic: Topic;
  modeOfAction: ModeOfAction;
  target: Target;
  substance: Substance;
  recruitingStudyCategory: Category;
  currentDataCategory: Category;
  queryParams: any;
  phase: Phase;

  breadcrumbs: {
    label: string,
    id: string,
    url?: string,
    studyPrefixLabel?: string // when within a topic.type === PIPELINE_OVER - add this visual cue to diferentiate between DATA / TRIALS
  }[];

  urlPossibilities: {
    key: string,
    searchField: string
  }[];

  didClickBreadcrumb(breadcrumb) {
    if (breadcrumb.url) {
      this.router.navigate([breadcrumb.url], { queryParams: this.queryParams });
    }
  }

  constructor(
    private location: Location,
    private localDb: LocalDatabaseService,
    public activatedRoute: ActivatedRoute,
    public router: Router,
    public translate: TranslateService
  ) {

    // map out our URL to search field, due to SLUG / localDb mismatch (plural, singular)
    this.urlPossibilities = [
      { key: 'mode_of_actions', searchField: 'mode_of_actions' },
      { key: 'topics', searchField: 'topics' },
      { key: 'targets', searchField: 'targets' },
      { key: 'substance', searchField: 'substances' },
      { key: 'current_data', searchField: 'current_data' },
      { key: 'recruiting_studies', searchField: 'recruiting_studies' },
      { key: 'media', searchField: 'media' },
      { key: 'category', searchField: 'category' },
      { key: 'groupAssets', searchField: 'groupAssets' },
      { key: 'asset', searchField: 'asset' },
    ];

  }

  ngOnInit() {

    // get topic and speciality
    this.activatedRoute.params.subscribe(param => {
      this.speciality = this.localDb.getSpeciality(param.specialityId);
      this.topic = this.localDb.getTopic(param.topicId);
    });

    this.generateBreadcrumbs();

    this.location.onUrlChange(() => {
      this.generateBreadcrumbs();
    });

  }

  generateBreadcrumbs() {
    this.breadcrumbs = [];
    if (this.location.path().includes('search')) {
      return false;
    }
    try {
      const phaseQueryParameters = this.router.url.split('?')[1].split('&')[1].split("phaseId=")[1]
      if (phaseQueryParameters) {
        this.phase = this.localDb.getPhase(phaseQueryParameters)
      }
    } catch (error) { this.phase = null }
    // const paths = this.location.path().split('/'); // split into array
    const paths = this.router.url.split('?')[0].split('/'); // make sure we don't bring queryParameters
    const index = paths.indexOf('specialities'); // where do we start looking for?

    for (let i = index; i < paths.length; i++) {

      const urlKey = paths[i]; // check if key exists in our main array

      if (this.urlExistsInPossibilities(urlKey)) {
        const searchField = this.getSearchField(urlKey); // get additional info, like where to search for ids

        // then we add this to our results and check the next value i + 1, which in our URL structure would be the UUID
        const item = this.getItemAndSaveIntoComponent(searchField, paths[i + 1]);
        if (item && item.id && item.label) {
          this.breadcrumbs.push(item);
        }
      }
    }

  }

  urlExistsInPossibilities(urlKey) {
    if (this.urlPossibilities.find(i => i.key === urlKey)) {
      return true;
    }
  }

  getName(urlKey) {
    return this.urlPossibilities.find(u => u.key === urlKey).key || false;
  }

  getSearchField(urlKey) {
    return this.urlPossibilities.find(u => u.key === urlKey).searchField || false;
  }

  // checks if current URL has a defined key / slug
  urlHas(key) {
    const path = this.location.path().split('/');
    if (path.includes(key)) {
      return true;
    } else {
      return false;
    }
  }

  getItemAndSaveIntoComponent(searchField, itemId): { label: string, id: string, url: string } | false {

    if (itemId) {

      let found; // just in case we don't get results
      let destinationUrl;
      let studyPrefixLabel;

      switch (searchField) { // get what?

        case 'asset':
          found = this.substance = this.localDb.getSubstance(itemId);
          if (found) {
            // tslint:disable-next-line: max-line-length
            destinationUrl = `specialities/${this.speciality.id}/topics/${this.topic.id}/mode_of_actions/${this.modeOfAction.id}/groupAssets/${this.target.id}/asset/${found.id}`;
          }
          break;

        case 'groupAssets':
          found = this.target = this.localDb.getTarget(itemId);
          if (found) {
            // tslint:disable-next-line: max-line-length
            destinationUrl = `specialities/${this.speciality.id}/topics/${this.topic.id}/mode_of_actions/${this.modeOfAction.id}/groupAssets`;
          }
          break;

        case 'targets':
          found = this.target = this.localDb.getTarget(itemId);
          if (found) {
            // tslint:disable-next-line: max-line-length
            destinationUrl = `specialities/${this.speciality.id}/topics/${this.topic.id}/mode_of_actions/${this.modeOfAction.id}/targets/${found.id}`;
          }
          break;

        case 'mode_of_actions':
          found = this.modeOfAction = this.localDb.getModeOfAction(itemId);
          if (found) {
            if (this.topic.type === 'ANALYSIS') {
              destinationUrl = `specialities/${this.speciality.id}/topics/${this.topic.id}/mode_of_actions/${found.id}/groupAssets`;
            } else {
              destinationUrl = `specialities/${this.speciality.id}/topics/${this.topic.id}/mode_of_actions/${found.id}/targets`;
            }
          }
          break;

        case 'substances':
          found = this.substance = this.localDb.getSubstance(itemId);
          if (found) {

            // specific scenario from ANALYSIS
            if (this.topic.type === 'ANALYSIS' || this.topic.type === 'PIPELINE_OVERVIEW') {
              destinationUrl = `specialities/${this.speciality.id}/topics/${this.topic.id}/substance/${found.id}`;
            } else {

              // mode of action is not always available on URL structure
              if (this.modeOfAction) {
                // tslint:disable-next-line: max-line-length
                destinationUrl = `specialities/${this.speciality.id}/topics/${this.topic.id}/mode_of_actions/${this.modeOfAction.id}/targets/${this.target.id}/substance/${this.substance.id}`;
              }
            }

          }
          break;



        case 'topics':
          found = this.topic = this.localDb.getTopic(itemId);

          if (found) {
            // final URL depends on topic type
            switch (found.type) {
              case 'GLASS_BODY_CURRENT_DATA':
                destinationUrl = `specialities/${this.speciality.id}/topics/${this.topic.id}/glassbody_current_data`;
                break;

              case 'GLASS_BODY_STUDIES':
                destinationUrl = `specialities/${this.speciality.id}/topics/${this.topic.id}/glassbody_studies`;
                break;

              case 'MODE_OF_ACTIONS':
                destinationUrl = `specialities/${this.speciality.id}/topics/${found.id}/mode_of_actions`;
                break;

              case 'PIPELINE_OVERVIEW':
                destinationUrl = `specialities/${this.speciality.id}/topics/${this.topic.id}/pipeline_overview`;
                break;

              case 'ANALYSIS':
                destinationUrl = `specialities/${this.speciality.id}/topics/${this.topic.id}/analysis`;
                break;

              default:
                console.log(`Topic type not set => ${found.type} ...not generating URL`);
                break;
            }
          }

          break;

        case 'current_data':
          found = this.currentDataCategory = this.localDb.getCategory(itemId);
          if (found) {

            // case specific: visual queue for breadcrumbs to inform user that is currently viewing
            if (this.topic.type === 'PIPELINE_OVERVIEW') {
              studyPrefixLabel = this.translate.instant('StudiesScreen.ClinicalData');
            }

            if (this.modeOfAction && this.target && this.substance && (this.topic.type && this.topic.type === 'MODE_OF_ACTIONS')) {
              // tslint:disable-next-line: max-line-length
              destinationUrl = `specialities/${this.speciality.id}/topics/${this.topic.id}/mode_of_actions/${this.modeOfAction.id}/targets/${this.target.id}/substance/${this.substance.id}/current_data/${found.id}`;
              this.queryParams = { substanceId: this.substance.id };
            }
            else if (this.modeOfAction && this.target && this.substance && (this.topic.type && this.topic.type === 'ANALYSIS')) {
              // tslint:disable-next-line: max-line-length
              destinationUrl = `specialities/${this.speciality.id}/topics/${this.topic.id}/mode_of_actions/${this.modeOfAction.id}/groupAssets/${this.target.id}/asset/${this.substance.id}/current_data/${found.id}`;
              this.queryParams = { substanceId: this.substance.id };
            }
            else {
              if (this.substance && this.substance.id && this.urlHas('substance')) { //Always charge the phase.
                if (this.phase) { this.queryParams = { substanceId: this.substance.id, phaseId: this.phase.id }; }
                // tslint:disable-next-line: max-line-length
                if (this.target && this.target.id) {
                  destinationUrl = `specialities/${this.speciality.id}/topics/${this.topic.id}/substance/${this.substance.id}/current_data/${found.id}`;
                } else {
                  destinationUrl = `specialities/${this.speciality.id}/topics/${this.topic.id}/substance/${this.substance.id}/current_data/${found.id}`;
                }
              } else {
                destinationUrl = `specialities/${this.speciality.id}/topics/${this.topic.id}/current_data/${found.id}`;
              }
            }

          }
          break;

        case 'recruiting_studies':
          found = this.recruitingStudyCategory = this.localDb.getCategory(itemId);

          if (found) {

            // case specific: visual queue for breadcrumbs to inform user that is currently viewing
            if (this.topic.type === 'PIPELINE_OVERVIEW') {
              studyPrefixLabel = this.translate.instant('StudiesScreen.ActiveStudies');
            }

            // scenario FROM_MEDIA
            if (this.recruitingStudyCategory && this.modeOfAction && this.target && this.substance && (this.topic.type && this.topic.type === 'MODE_OF_ACTIONS')) {

              // tslint:disable-next-line: max-line-length
              destinationUrl = `specialities/${this.speciality.id}/topics/${this.topic.id}/mode_of_actions/${this.modeOfAction.id}/targets/${this.target.id}/substance/${this.substance.id}/recruiting_studies/${found.id}`;
              this.queryParams = { substanceId: this.substance.id };
            }
            else if (this.recruitingStudyCategory && this.modeOfAction && this.target && this.substance && (this.topic.type && this.topic.type === 'ANALYSIS')) {

              // tslint:disable-next-line: max-line-length
              destinationUrl = `specialities/${this.speciality.id}/topics/${this.topic.id}/mode_of_actions/${this.modeOfAction.id}/groupAssets/${this.target.id}/asset/${this.substance.id}/recruiting_studies/${found.id}`;
              this.queryParams = { substanceId: this.substance.id };
            }
            else {

              if (this.substance && this.substance.id && this.urlHas('substance')) {

                if (this.phase) { this.queryParams = { substanceId: this.substance.id, phaseId: this.phase.id }; }
                else { this.queryParams = { substanceId: this.substance.id }; }
                // tslint:disable-next-line: max-line-length
                destinationUrl = `specialities/${this.speciality.id}/topics/${this.topic.id}/substance/${this.substance.id}/recruiting_studies/${found.id}`;
              } else {
                destinationUrl = `specialities/${this.speciality.id}/topics/${this.topic.id}/recruiting_studies/${found.id}`;
              }

            }

          }
          break;

        case 'media':
          found = this.localDb.getVideo(itemId);
          if (!found) {
            found = this.localDb.getPdf(itemId);
          }
          else {
            //If the substance have phase, we need to send the information to charge everything correctly.
            if (this.substance?.id && this.phase?.id) { this.queryParams = { substanceId: this.substance.id, phaseId: this.phase.id }; }
          }
          break;

        case 'category':
          found = this.localDb.getCategory(itemId);
          break;

        default:
          found = false;
          console.log(`WARNING: got unmapped searchField => ${searchField} - NOT adding to breadcrumbs`);
          break;
      }

      if (!destinationUrl) {
        // console.log(`Could not find a destinationUrl for ${searchField}`);
      }

      if (found) {
        const item = {
          label: found.name,
          studyPrefixLabel,
          id: found.id,
          url: destinationUrl
        };
        return item;
      }

    } else {
      return false;
    }

  }

}
