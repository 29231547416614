<div>

  <div class="custom-container">



    <div nz-row>
      <div nz-col [nzXl]="{span: 5, offset : 2}" [nzLg]="{span: 6, offset : 1}" [nzSm]="{span: 6, offset : 0}">
        <ul class="menu">
          <li *ngIf="isDataPackageVisible && isNotTempEvent()">
            <app-button-icon (click)="packageButtonClicked($event)" [title]="'EventsScreen.DataPackage' | translate"
              icon="icon-package"></app-button-icon>
          </li>
          <li *ngIf="isLogoutVisible && isNotTempEvent()">
            <app-button-icon (click)="logoutButtonClicked($event)" [title]="'EventsScreen.Logout' | translate"
              icon="icon-logout"></app-button-icon>
          </li>
          <li *ngIf="isHomeVisible"><a class="icon icon-home" (click)="homeButtonClicked($event)"></a></li>
          <li *ngIf="isReferencesVisible"><a class="icon icon-references" (click)="referencesButtonClicked($event)"></a>
          </li>
          <li *ngIf="isSearchVisible"><a class="icon icon-search" (click)="searchButtonClicked($event)"></a></li>
          <!-- <li *ngIf="isEditionVisible"><a class="icon icon-edition" (click)="editionButtonClicked($event)"></a></li>
          <li *ngIf="isEditionVisible"><a class="icon icon-edition" (click)="editionNewButtonClicked($event)"></a></li> -->
          <li *ngIf="isStatsVisible"><a class="icon icon-stats" (click)="statsButtonClicked($event)"></a></li>
        </ul>
      </div>
      <div nz-col [nzSm]="{ span: 10, offset: 0 }">
        <h1 class="page-title">{{ title }}</h1>
      </div>
      <div nz-col [nzXl]="{span: 2, offset: 3}" [nzSm]="{span: 6, offset : 0}">
        <span (click)="clickLogo()" class="logo"></span>
      </div>
    </div>

  </div>

</div>