import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Category, Topic } from 'src/app/modules/api-client/models';
import { LocalDatabaseService } from '../../services/local-database.service';
import { CachedImageService } from '../../services/cached-image.service';

@Component({
  selector: 'app-indication-selection',
  templateUrl: './indication-selection.component.html',
  styleUrls: ['./indication-selection.component.scss']
})
export class IndicationSelectionComponent implements OnInit {

  @Input()
  title: string;

  @Input()
  specialityId: string;

  @Input()
  topicId: string;

  @Output()
  didSelectCategory = new EventEmitter<string>();

  @Output()
  redirectToSingleCategory = new EventEmitter<string>();

  glassBody: any;
  categories: Category[];
  pageReady: boolean;
  topic: Topic;
  defaultGlassbodyImage: string;
  mouseOverTimeout: any;

  images: { categoryId: string, attachmentId: string }[];

  constructor(
    private localDb: LocalDatabaseService,
    private cachedImageService: CachedImageService
  ) {

    this.pageReady = false;

    this.glassBody = {
      categoryImage: null,
      width: 330,
      height: 520,
      alt: 'Roche Pharma'
    };

  }

  ngOnInit() {

    if (this.topicId) {
      this.topic = this.localDb.getTopic(this.topicId);
      if (this.topic.imageGlassBodyId) {
        this.defaultGlassbodyImage = this.topic.imageGlassBodyId;
        // set the default on top
        const glassBodyImageTopicDefault = this.localDb.getAttachmentForImageId(this.topic.imageGlassBodyId)?.id;
        this.glassBody.categoryImage = glassBodyImageTopicDefault;
      }
    }
    this.categories = this.localDb.getCategoriesForSpecialityId(this.specialityId).
      sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);

    if (this.categories.length === 1) {
      const categoryId = this.categories[0].id;
      this.redirectToSingleCategory.emit(categoryId); // tell the component to redirect
    } else {

      this.images = this.categories.map(category => {
        const attachment = this.localDb.getAttachmentForImageId(category.imageId);
        return {
          categoryId: category.id,
          attachmentId: attachment ? attachment.id : null
        };
      });

      // preload all the images before pageload
      this.cachedImageService.preloadMultipleAttachments(this.images.map(image => image.attachmentId)).subscribe(attachments => {
        if (attachments) {
          this.pageReady = true;
        }
      });

    }

  }

  hover(event, category?: Category) {

    if (category && category.imageId) {
      this.glassBody.categoryImage = this.images.find(i => i.categoryId === category.id).attachmentId;
    } else {
      this.glassBody.categoryImage = null;
    }
    
  }

  didMouseOut(event): void {
    // clearTimeout(this.mouseOverTimeout);
    // this.mouseOverTimeout = setTimeout(() => {
    this.glassBody.categoryImage = this.localDb.getAttachmentForImageId(this.topic.imageGlassBodyId)?.id;
    // }, 500);
  }

  onSelectCategory(category: Category) {
    this.didSelectCategory.emit(category.id);
  }

}
