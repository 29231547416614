import { Codable } from '../interfaces/serialization';

export class Country implements Codable<Country> {

    id: string;
    name: string;
    isoCode: string;

    deserialize(object: any, id?: any): Country {
        const country = new Country();

        country.id = object.id || id;
        country.name = object.name;
        country.isoCode = object.isoCode;
        return country;
    }

    serialize(): any {
        return {
            id: this.id,
            name: this.name,
            isoCode: this.isoCode,
        };
    }

}
