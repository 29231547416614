import { Injectable } from '@angular/core';
import { FooterLink } from '../components/footer/footer.component';
import { Location } from '@angular/common';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { TranslateService } from '@ngx-translate/core';
import { LocalDatabaseService } from './local-database.service';


@Injectable({
  providedIn: 'root'
})
export class DisclaimerService {

  popoverDisclaimer: FooterLink;
  storageKey = 'ACCEPTED_DISCLAIMER_EVENTS';
  disclaimerModal: NzModalRef;
  isShowingDisclaimer = false;

  get canShowDisclaimer() {
    const pathComponents = this.location.path().split('/');
    return pathComponents.includes('specialities') && !!!this.isShowingDisclaimer;
  }

  constructor(
    private location: Location,
    private modalService: NzModalService,
    private translateService: TranslateService,
    private localDb: LocalDatabaseService
  ) {

    this.location.onUrlChange(() => {
      this.display();
    });

  }

  public setDisclaimer(disclaimer: FooterLink) {
    this.popoverDisclaimer = disclaimer;
  }

  /**
   * given conditions will display a popup disclaimer
   * even if the user has accepted the disclaimer already,
   * it should be displayed again when accessing the specialities screen
   */
  public display(forceDisplay?: boolean): void {
    const didAlreadyAcceptDisclaimerForEvent = this.didAcceptDisclaimerForEvent(this.localDb.getCurrentEventId());

    if ((this.popoverDisclaimer?.title && this.popoverDisclaimer?.text && this.canShowDisclaimer) || forceDisplay) {
      if (!didAlreadyAcceptDisclaimerForEvent || forceDisplay) {

        this.isShowingDisclaimer = true;

        this.translateService.get('Shared.Agree').subscribe(label => {
          this.disclaimerModal = this.modalService.confirm({
            nzTitle: this.popoverDisclaimer?.title,
            nzContent: this.popoverDisclaimer?.text,
            nzFooter: null,
            nzClassName: 'roche-disclaimer-modal',
            nzClosable: false,
            nzWidth: 900,
            nzCancelText: null,
            nzOkText: label,
            nzOnOk : () => {
              this.saveDisclaimerAcceptedForEvent(this.localDb.getCurrentEventId());
              this.isShowingDisclaimer = false;
            }
          });
        });
      }
    }
  }

  saveDisclaimerAcceptedForEvent(id: string): void {
    const events = JSON.parse(window.localStorage.getItem(this.storageKey)) || [];
    if (!events.includes(id)) {
      events.push(id);
      window.localStorage.setItem(this.storageKey, JSON.stringify(events));
    }
  }

  didAcceptDisclaimerForEvent(id: string): boolean {
    const events = JSON.parse(window.localStorage.getItem(this.storageKey));
    return events?.includes(id);
  }

}
