<ng-container *ngIf="(options && options.length > 1); else singleButtonTemplate" #notFromAnalysis>
  <button nz-button nz-dropdown [nzDropdownMenu]="menu" [nzPlacement]="'topCenter'" class="roche-mr-10">{{ title }}</button>
  <nz-dropdown-menu #menu="nzDropdownMenu">
    <ul class="roche-dropup-component-list" nz-menu>
      <li *ngFor="let option of options"><a (click)="didSelectOptionId(option.id)" [innerHtml]="option.label"></a></li>
    </ul>
  </nz-dropdown-menu>
</ng-container>

<ng-template #singleButtonTemplate>
  <a *ngIf="!fromAnalisys" class="roche-dropup-component-button roche-mr-10" (click)="didSelectOptionId(options[0].id)">{{ (!isMediaButton ? title : options[0].label) }}</a>
</ng-template>
