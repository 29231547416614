import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Topic } from 'src/app/modules/api-client/models';
import { LocalDatabaseService } from 'src/app/modules/shared/services/local-database.service';

@Component({
  selector: 'app-glassbody',
  templateUrl: './glassbody.component.html',
  styleUrls: ['./glassbody.component.scss']
})
export class GlassbodyComponent implements OnInit {

  specialityId: string;
  title: string;
  type: string;
  topicId: string;
  imageGlassBodyId: string;
  topic: Topic;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private localDb: LocalDatabaseService
  ) { }

  ngOnInit() {

    this.type = this.activatedRoute.routeConfig.path === 'glassbody_current_data' ? 'CURRENT_DATA' : 'RECRUITING_STUDIES';

    this.activatedRoute.parent.params.subscribe(params => {
      this.specialityId = params.specialityId;
      this.topicId = params.topicId;
      this.topic = this.localDb.getTopic(this.topicId);
      this.title = this.topic?.name;
    });
  }

  didSelectCategory(categoryId: string) {
    const paths = this.activatedRoute.parent.snapshot.url.map(s => s.path);
    if (this.type === 'CURRENT_DATA') {
      paths.push('current_data');
    } else {
      paths.push('recruiting_studies');
    }
    paths.push(categoryId);
    this.router.navigate(paths);
  }

  redirectToSingleCategory(categoryId) {
    const paths = this.activatedRoute.parent.snapshot.url.map(s => s.path);
    if (this.type === 'CURRENT_DATA') {
      paths.push('current_data');
    } else {
      paths.push('recruiting_studies');
    }
    paths.push(categoryId);
    this.router.navigate(paths);
  }

}
