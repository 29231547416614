<div nz-row>
  <div nz-col nzSpan="12" nzOffset="6">
    <div class="roche-title-section-wrapper roche-mt-50 roche-mb-50">
      <h1 class="roche-text-center roche-title-section">{{target.name}}</h1>
    </div>
  </div>
</div>

<div nz-row class="roche-mb-50">

  <div nz-col [nzXl]="{ span: 16, offset : 4 }" [nzSm]="{ span: 20, offset : 2 }">

    <!-- focus area -->
    <div nz-row class="moa-target-details">

      <div class="moa-target-image" nz-col [nzMd]="{ span : 10 }"
        [style.backgroundImage]="target.imageId | toAttachmentId | authImg:false:'style' | async">
      </div>

      <div nz-col [nzMd]="{ span : 14 }" class="moa-target-content">

        <div class="moa-target-description">

          <div class="ps" [perfectScrollbar]>
            <perfect-scrollbar></perfect-scrollbar>
            <div [innerHTML]="target.description"></div>
          </div>

        </div>

        <!-- substance buttons -->
        <div class="moa-target-info">
          <span *ngIf="substances.length>0"
            class="moa-target-label roche-font-base roche-font-bold roche-font-uppercase">{{
            'TargetsScreen.PipelineSubstances' | translate }}</span>
          <div *ngIf="substances.length==1">
            <a *ngFor="let substance of substances" (click)="toSubstanceDetails(substance)"
              class="roche-pill">{{substance.name}}</a>
          </div>
          <app-drop-up *ngIf="substances.length>1" (didSelectOption)="didSelectSubstance($event)" [options]="listOfSubstance" [title]="'Substances'"></app-drop-up>
        </div>
        <!-- /substance buttons -->

      </div>

    </div>
    <!-- /focus area -->
    <div class="moa-target-pdf" *ngIf="pdfOfTargetName">
      <a (click)="didSelect()" class="roche-pill">{{pdfOfTargetName}}</a>
    </div>
  </div>
</div>